<script setup>
import { computed, onMounted, ref } from 'vue';
import TableComponent from "@/components/tableComponents/TableComponent.vue"
import LoaderComponent from "@/components/LoaderComponent.vue";
import NotificationModal from "@/components/modalComponents/NotificationModal.vue";
import { Modal } from 'bootstrap';
import { useStore } from 'vuex';

// Vuex Store
const store = useStore();

// Reactive Variables
let apiError = ref('');
let loading = ref(false);
let tableLoading = ref(false);
const rows = ref([]);
const columns = ref([
    { label: "Status Code", field: "status_code", html: false },
    { label: "Type", field: "status_code_type", html: false },
    { label: "Name", field: "status_code_name", html: false },
    { label: "Description", field: "status_code_desc", html: false },
]);

// Methods
async function getHttpStatusLegends(){
    try{
        loading.value = true;
        tableLoading.value = true;
        let res = await store.dispatch('Other/getHTTPStatusLegendsAction');
        rows.value = res;
    }catch(e){
        if(e.code !== "ERR_NETWORK"){
            let errorModal = new Modal(document.getElementById('error-modal'));
            apiError.value = `<h4>Something Unexpected Occured</h4><p>Something unexpected happened, try again later!</p>`;
            errorModal.show();
        }
    }finally{
        setTimeout(()=>{
            loading.value = false;
            tableLoading.value = false;
        }, 1000);
    }
}

function init(){
    getHttpStatusLegends();
}

// Computed Properties
const getComputedRows = computed(()=>{
    if(rows.value && rows.value.length > 0){
        return rows.value.map(row => {
            return {
                status_code: row.status_code,
                status_code_type: row.status_code_type ? row.status_code_type.replace('_',' ') : '',
                status_code_name: row.status_code_name,
                status_code_desc: row.status_code_desc,
            }
        })
    } 

    return null;
}); 

// Life Cycle Methods
onMounted(() => {
    init();
});

</script>

<template>
  <section class="dashboard-agency-view https-status-legends">
      <LoaderComponent :isLoading="loading" />

      <div class="custom-container">
        <div class="dashboard-agency-table https-status-legends-table">
          <div class="row align-items-center">
              <div class="col-lg-12 col-md-12 col-12">
                  <div class="primary-heading">
                      <h2>HTTP Status Legends</h2>
                  </div>
              </div>
          </div>

          <div class="table-wrapper">
            <TableComponent
                :rows="getComputedRows"
                :columns="columns"
                :tableLoading="tableLoading"
                :limit="10"
            />
          </div>

        </div>
      </div>

      <!-- Notification(Error) API Error Modal Start -->
      <NotificationModal modalId="error-modal" type="error" :verticalCentered="true" dataBackDrop="static" :dataKeyboard="true" :showCloseBtn="true">
          <div v-if="apiError" v-html="apiError"></div>
          <div class="modal-btns">
              <router-link class="primary-btn primary-bg" :to="{name:'dashboard'}">Go To Dashboard</router-link>
          </div>
      </NotificationModal>

  </section>
</template>

<style>

</style>