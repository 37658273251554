function getAPIURL(){
    if(window.location.origin === 'https://app.linkwiki.com'){
        return 'https://api.linkwiki.com';
    }else if(window.location.origin === 'https://devapp.linkwiki.com'){
        return 'https://devapi.linkwiki.com';
    }else{
        //return 'http://localhost:8002';
        return 'https://api.linkwiki.com';
    }   
}

const urls = {
    baseUrl:  getAPIURL(),
}   

export default urls;