<script setup>
import { defineProps, defineEmits } from 'vue';
import { RouterLink, useRoute } from "vue-router";

// Vue Routes
const route = useRoute();

// Vue Props
defineProps({
    projectId: Number,
    title: Object,
    data: Object,
    count: Number,
    listFilter: Boolean,
});

// Vue Emits
const emits = defineEmits(['event']);

// Methods
const getDiffColor = function(diff){
    if(diff >= 0){
        return '#38cb89';
    }else if(diff < 0){
        return '#ff0e52';
    }else{
        return '#ff0e52';
    }
}
const getPercentageColor = function(val){
    if(val >= 0 && val < 30){
        return '#ff0000';
    }else if(val >= 30 && val < 60){
        return '#FFA500';
    }else if(val >= 60 && val < 80){
        return '#ffc200';
    }else if(val >= 80 && val < 90){
        return '#38cb89';
    }else if(val >= 90 && val <= 100){
        return '#00cbaf';
    }else{
        return '#ff0000';
    }
}
function viewAllAnchorText(){
    emits('event', {
        type: 'showAllAnchorText',
    });
}
function filterAnchorText(text){
    emits('event', {
        type: 'filterAnchorText',
        anchor: text,
    });
}
function filter(filter){
    emits('event', {
        type: 'filter',
        data: filter,
    });
}

</script>

<template>
    <div class="project-thumbnail-da project-thumbnail-anchor" v-if="title.name === 'Anchor Spread'">
        <h3>{{ title.name }} <i class="fas fa-exclamation-circle" v-if="title.tooltip" v-tooltip="title.tooltip"></i></h3>
        <ul v-if="Object.keys(data).length > 0">
            <li v-for="(value,key) in data" :key="key">
                <a href="#" class="list" v-if="listFilter">
                    <p v-tooltip="key" @click.prevent="filterAnchorText(key)">{{ key }}</p>
                    <span>{{ value.val }} <small :style="{'color': '#38cb89'}">{{ value.per }}%</small></span>
                </a>
                <div v-else>
                    <p v-tooltip="key">{{ key }}</p>
                    <span>{{ value.val }} <small :style="{'color': '#38cb89'}">{{ value.per }}%</small></span>
                </div>
            </li>
            <li v-if="count > 5">
                <a href="#" v-if="route.name === 'project'" @click.prevent="viewAllAnchorText" class="primary-btn tertiary-transparent-btn">View All</a>
                <router-link v-else class="primary-btn tertiary-transparent-btn" :to="`/projects/${projectId}`">View Links</router-link>
            </li>
        </ul>
        <div class="chart-content" v-else>
            <router-link class="primary-btn primary-bg" :to="`/add-links/${projectId}`">Get Started</router-link>
            <span>Add links to view anchor spread.</span>
        </div>
    </div>
    <div class="project-thumbnail-da" v-else>
        <h3>{{ title.name }} <span v-if="title.value">{{ title.value }}</span> <i class="fas fa-exclamation-circle" v-if="title.tooltip" v-tooltip="title.tooltip"></i></h3>
        <ul>
            <li v-for="(value,key) in data" :key="key">
                <div class="health-score-wrapper" v-if="key === 'Health Score'">
                    <div class="percentage" :style="{'width': value.val + '%', background: getPercentageColor(value.val)}"></div>
                </div>
                <a href="#" class="list" v-if="listFilter && value.filter" @click="filter({value: value.filter,for: title.name})">
                    <p v-tooltip="value.tooltip">{{ key }}</p>
                    <span v-if="key === 'Health Score'" :style="{color: getPercentageColor(value.val)}">{{ value.val < 0 ? 0 : value.val }}%</span>
                    <span v-else>{{ value.val }} <small :style="{'color': getDiffColor(value.diff)}"><i class="fas" :class="{'fa-caret-down': value.diff < 0, 'fa-caret-up': value.diff >= 0,}"></i>{{ value.diff }}</small></span>
                </a>
                <div v-else>
                    <p v-tooltip="value.tooltip">{{ key }}</p>
                    <span v-if="key === 'Health Score'" :style="{color: getPercentageColor(value.val)}">{{ value.val < 0 ? 0 : value.val }}%</span>
                    <span v-else>{{ value.val }} <small :style="{'color': getDiffColor(value.diff)}"><i class="fas" :class="{'fa-caret-down': value.diff < 0, 'fa-caret-up': value.diff >= 0,}"></i>{{ value.diff }}</small></span>
                </div>
            </li>
        </ul>
    </div>
</template>