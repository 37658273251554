<script setup>
    import { useSlots, defineProps } from 'vue';  

    // Props 
    defineProps({
        modalId: String,
        modalClass: String,
        title: String,
        verticalCentered: Boolean,
        dataBackDrop: String,
        dataKeyboard: Boolean,
        showCloseBtn: Boolean,
    });

    // Slots
    const slots = useSlots();
</script>

<template>
    <Teleport to="body">
        <div class="modal fade" :class="modalClass" :id="modalId" tabindex="-1" :aria-labelledby="modalId + 'Label'" aria-hidden="true" :data-bs-backdrop="dataBackDrop" :data-bs-keyboard="dataKeyboard">
            <div class="modal-dialog" :class="{'modal-dialog-centered': verticalCentered }">
                <div class="modal-content">
                    <button v-if="showCloseBtn" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    <div class="modal-header" v-if="title">
                        <h5 class="modal-title" :id="modalId + 'Label'">{{ title }}</h5>
                    </div>
                    <div class="modal-body">
                        <slot></slot>    
                    </div>
                    <div class="modal-footer" v-if="slots['modal-footer']">
                        <slot name="modal-footer"></slot>
                    </div>
                </div>
            </div>
        </div>
    </Teleport>    
</template>

<style>

</style>