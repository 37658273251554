import { UserProfileServices } from '@/services/userProfileServices';
import url from '@/prod.js';
export default {
  namespaced: true,

  mutations: {
  
  },
  actions: {

    // User Profile Image Upload Action 
    async userProfileImageUpdateAction({commit}, payload, config){
      let res = await UserProfileServices.userProfileImageUpdateAPI(payload,config);
      let data = res.data;
      if(data.success){
        commit('Auth/userProfileImageUpdateMutation', `${url.baseUrl}${data.path}`, { root: true });
      }
      return data;
    },

    // Get User Profile Data Action
    async getUserProfileDataAction(){
      let res = await UserProfileServices.getUserProfileDataAPI();
      let data = res.data;
      return data;
    },

    // Update User Profile Data Action
    async updateUserProfileDataAction({commit}, payload){
      let res = await UserProfileServices.updateUserProfileDataAPI(payload);
      let data = res.data;
      if(data.success){
        commit('Auth/updateUserDataMutation', payload, { root: true });
      }
      return data; 
    },

    // Update User Password Action
    async updateUserProfilePasswordAction(_, payload){
      let res = await UserProfileServices.updateUserProfilePasswordAPI(payload);
      let data = res.data;
      return data; 
    },

  },
}