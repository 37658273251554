import { OtherServices } from '@/services/otherServices';
export default {
  namespaced: true,
  mutations: {
  
  },
  actions: {
    // Get Http Status Legends
    async getHTTPStatusLegendsAction(){
      let res = await OtherServices.httpStatusLegendsAPI();
      let data = res.data;
      return data; 
    },
  },
}