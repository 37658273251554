<script setup>
import { computed, onMounted, ref } from "vue";
import { RouterLink, useRoute, useRouter } from 'vue-router';
import { useStore } from "vuex";
import { useToast } from "vue-toastification";

// Vue Router
const router = useRouter();
const route = useRoute();

// Vue Store
const store = useStore();

// Toastification 
const toast = useToast();

// Reative Variables
const authData = ref({
    states: {
        name: "",
        email: "",
        password: "",
        accept_terms: false,
    },
    errors: {
        name: "",
        email: "",
        password: "",
        accept_terms: "",
    }
});

// Methods

// UTM Fn
function utm(){
    const allAnchors = [...document.querySelectorAll('a')];

    const Utm = function(s,m,c,t){
        this.s = s;
        this.m = m;
        this.c = c;
        this.t = t;
    }

    Utm.prototype.generateUrl = function(){
        return `utm_source=${this.s}&utm_medium=${this.m}&utm_campaign=${this.c}&utm_term=${this.t}`;
    }

    const storeToSession = function(url){
        sessionStorage.setItem('utmLink',url);
    }

    const getFromSession = function(){
        return sessionStorage.getItem('utmLink');
    }

    const checkUtmSession = function(){
        const url = getFromSession();
        if(url){
            return url;
        }else{
            return false;
        }
    }

    const updateUrl = function(currentUrl,urlParams,hosts){
        const url = checkUtmSession();
        let urlParamsStr = '';

        if(Object.keys(Object.fromEntries(urlParams.entries())).length){
            for(let [key, value] of urlParams.entries()){
                if(key !== 'utm_source' && key !== 'utm_medium' && key !== 'utm_campaign' && key !== 'utm_term'){
                    urlParamsStr += `&${key}=${value}`;
                }
            }
        }

        if(url.length > 0){
            history.replaceState(history.state,null,`?${url}${urlParamsStr}`);
        }else{
            const utmParams = {
                utmSource: urlParams.get('utm_source') ? urlParams.get('utm_source') : 'direct',
                utmMedium: urlParams.get('utm_medium') ? urlParams.get('utm_medium') : 'direct',
                utmCampaign: urlParams.get('utm_campaign') ? urlParams.get('utm_campaign') : 'direct',
                utmTerm: urlParams.get('utm_term') ? urlParams.get('utm_term') : 'direct'
            }
            const newUrl = new Utm(utmParams.utmSource,utmParams.utmMedium,utmParams.utmCampaign,utmParams.utmTerm).generateUrl();

            history.replaceState(history.state,null,`?${newUrl}${urlParamsStr}`);
            storeToSession(newUrl);
        }

        hosts.forEach(function(host){
            allAnchors.filter((anchor)=>anchor.getAttribute('href').includes(host)).map((anchor) => {
                const href = anchor.getAttribute('href');
                anchor.setAttribute('href',`${href}?${getFromSession() ? getFromSession() : ''}`);
            });
        });
    }

    function initUtm(){
        const hosts = ['https://app.linkwiki.com/','https://calendly.com/'];
        const currentLink =  window.location.search;
        const urlParam = new URLSearchParams(currentLink);
        updateUrl(currentLink, urlParam, hosts);
    }
    initUtm();
}

function togglePassword(e){
    const el = e.target;
    const  parentEl = el.parentNode;
    const inputEl = parentEl.querySelector('input');

    if(!parentEl.classList.contains('show-password')){
        parentEl.classList.add('show-password');
        el.classList.remove('fa-eye-slash');
        el.classList.add('fa-eye');
        inputEl.setAttribute('type','text');
    }else{
        parentEl.classList.remove('show-password');
        el.classList.remove('fa-eye');
        el.classList.add('fa-eye-slash');
        inputEl.setAttribute('type','password');
    }
}
function toggleLoadingBtn(btn){
    if(!btn.classList.contains('loading')){
        btn.classList.add('loading');
        btn.disabled = true;
    }else{
        btn.classList.remove('loading');
        btn.disabled = false;
    }
}
function emailValidation(email) {
    return String(email)
        .toLowerCase()
        .match(/^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i);
}
function validateForm(){
    let errCount = 0;
    const states = authData.value.states; 
    const errors = authData.value.errors;

    if(!states.email){
        errors.email = 'Field is required!';
    }else if(!emailValidation(states.email)){
        errors.email = 'Email is invalid!';
    }else{
        errors.email = '';
    }
    errors.name = states.name === '' ? 'Field is required' : '';
    errors.password = states.password === '' ? 'Field is required' : '';
    errors.accept_terms = !states.accept_terms ? 'Please check the box if you want to proceed.' : '';

    Object.keys(errors).forEach(error => {
        if(errors[error]){
            errCount++;
        }
    });

    return errCount;
}
async function submitForm(e){
    const submitBtn = e.target.querySelector('.submit-btn');

    if(validateForm() === 0){
        try{
            toggleLoadingBtn(submitBtn);
            await store.dispatch('Auth/signUpAction',getComputedSignUpValues.value,{root:true});

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': 'clientportal-signup',
                'formType': 'Sign Up',
                'formPlacement': 'Client Portal App',
                'gtm.elementClasses': e.target.getAttribute('class'),
                'gtm.elementId': e.target.getAttribute('id') || '',
            });
            
            if(route.query.id && route.query.cycle){
                router.push({name: 'email-verification',query:{id:route.query.id,cycle:route.query.cycle}});    
            }else{
                router.push({name: 'email-verification'});
            }
            
        }catch(e){
            e.response.data.data.error.forEach((error) => {
                toast.error(error,{
                    transition: "Vue-Toastification__fade",
                    position: "top-center",
                    timeout: 6000,
                    closeOnClick: false,
                    pauseOnFocusLoss: true,
                    pauseOnHover: true,
                    draggable: false,
                    showCloseButtonOnHover: false,
                    hideProgressBar: false,
                    closeButton: "button",
                    icon: true,
                    rtl: false
                });
            });
        }finally{
            toggleLoadingBtn(submitBtn);
        }
    }
}

// Computed Properties
const getComputedSignUpValues = computed(()=>{
    return {
        name: authData.value.states.name,
        email: authData.value.states.email,
        password: authData.value.states.password,
        accept_terms: authData.value.states.accept_terms ? 1 : 0,
    }
});

// LifeCycle Methods
onMounted(()=>{
    utm();
})

</script>

<template>
    <section class="dashboard-agency-auth dashboard-signup">
        <div class="dashboard-agency-auth-form">
            <form @submit.prevent="submitForm" class="agency-form agency-signup-form">
                <figure class="mobile-comp"><img src="@/assets/images/logo.png" alt="logo"></figure>
                <h1>Create your <br> new account!</h1>
                <div class="form-group">
                    <label>Name</label>
                    <div class="d-relative">
                        <input
                            v-model="authData.states.name"
                            type="text"
                            class="form-control"
                            placeholder="john doe"
                        />
                        <i class="fas fa-user"></i>
                        <span class="error-message" v-if="authData.errors.name"
                            ><i class="fas fa-exclamation-circle"></i>
                            {{ authData.errors.name }}</span
                        >
                    </div>
                </div>
                <div class="form-group">
                    <label>Email</label>
                    <div class="d-relative">
                        <input
                            v-model.trim="authData.states.email"
                            type="email"
                            class="form-control"
                            placeholder="johndoe@gmail.com"
                        />
                        <i class="fas fa-envelope"></i>
                        <span class="error-message" v-if="authData.errors.email"
                            ><i class="fas fa-exclamation-circle"></i>
                            {{ authData.errors.email }}</span
                        >
                    </div>
                </div>
                <div class="form-group">
                    <label>Password</label>
                    <div class="d-relative">
                        <input
                            v-model="authData.states.password"
                            type="password"
                            class="form-control"
                            placeholder="********"
                        />
                        <i class="fas fa-key"></i>
                        <i
                            class="hide-password fas fa-eye-slash"
                            @click="togglePassword"
                        ></i>
                        <span class="error-message" v-if="authData.errors.password"
                            ><i class="fas fa-exclamation-circle"></i>
                            {{ authData.errors.password }}</span
                        >
                    </div>
                </div>
                <div class="form-group">
                    <div class="d-relative">
                        <p class="ter-ser-wrapper">
                            <input type="checkbox" class="d-none" id="accept-terms-conditions" v-model="authData.states.accept_terms" :value="true">
                            <label for="accept-terms-conditions"><span></span> I Agree to the </label>
                            <a href="https://www.linkwiki.com/terms-conditions" target="_blank" class="p-anchor">Terms & Conditions</a>
                        </p>
                        <span class="error-message" v-if="authData.errors.accept_terms"
                            ><i class="fas fa-exclamation-circle"></i>
                            {{ authData.errors.accept_terms }}</span
                        >
                    </div>
                </div>
                <button class="primary-btn primary-bg lg-btn submit-btn" type="submit">
                    <span>Sign Up</span>
                    <i class="fas fa-spinner fa-spin loading"></i>
                </button>
                <p class="mobile-comp">
                    Already have an account?
                    <router-link :to="{name: 'login'}" class="p-anchor">Login Now</router-link>
                </p>
                <!-- <div class="social-login">
                    <div class="social-login-title"><h3>OR</h3></div>
                    <ul>
                        <li><a href="#" class="google-btn"><i class="fab fa-google"></i> Sign Up With Google</a></li>
                    </ul>
                </div> -->
            </form>
        </div>
        <div
            class="dashboard-agency-auth-content"
            :style="{backgroundImage: 'url(' + require('../../assets/images/auth-bg.jpg') + ')'}"
        >
            <div class="wrapper">
                <h2>
                    Welcome to <br />
                    Link Wiki
                </h2>
                <p>
                    Take your link building to the next level <br />
                    with a tool that does all the work of <br />
                    monitoring your backlinks.
                </p>
                <p>Get unlimited access during our Early Bird offer!</p>

                <div class="have-account">
                    <p>Already have an account?</p>
                    <router-link :to="{name: 'login'}" class="p-anchor">Login Now</router-link>
                </div>
            </div>
        </div>
    </section>
</template>

<style></style>
