import apiInstance from "./apiInstance";

export class DomainServices{

    static addDomainAPI(payload){
        return apiInstance.post('/api/add-domain',payload);
    }

    static getAllDomainDataAPI(){
        return apiInstance.get('/api/user-dashboard');
    }

    static getDomainDataAPI(payload){
        return apiInstance.get(`/api/get-domain-detail/${payload.id}`, { params: {type: payload.type} });
    }

    static getDomainLinksDataAPI(payload){
        return apiInstance.get(`/api/get-links-detail/${payload.id}`, { params: payload });
    }

    static trackLinksAPI(payload){
        return apiInstance.post('/api/add-links', payload, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static getAllProjectsAPI(payload){
        return apiInstance.get(`/api/get-all-projects`, { params: payload });
    }

    static getAllLinksAPI(payload){
        return apiInstance.get(`/api/notify-domain-list/${payload.verificationId}`, { params: {'project_id': payload.project_id} });
    }

    static exportLinksDataAPI(payload){
        return apiInstance.get(`/api/export-links/${payload.id}`, { params: payload });
    }

    static exportUpdatedLinksDataAPI(payload){
        return apiInstance.get(`/api/export-updated-links/${payload.verificationId}`, { params: payload });
    }

    static getSourcesAPI(){
        return apiInstance.get('/api/source-list');
    }

    static addSourceAPI(payload){
        return apiInstance.post('/api/create-source', payload);
    }

    static removeSourceAPI(payload){
        return apiInstance.get(`/api/delete-source/${payload}`);
    }

    static updateAnchorTextURLAPI(payload){
        return apiInstance.post(`/api/update-anchor`, payload);
    }

    static getDomainLinksLog(payload){
        return apiInstance.get(`/api/get-links-detail-log/${payload.id}`);
    }

    static getProjectsByBatchIdAPI(payload){
        return apiInstance.get(`/api/notify-user-domains/${payload}`);
    }
}