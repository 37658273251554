<script setup>  

import {defineProps, computed, onMounted, provide, ref } from 'vue';
import { useStore } from 'vuex';
import { useToast } from "vue-toastification";
import Popper from "vue3-popper";
import LoaderComponent from "@/components/LoaderComponent.vue";
// import SavedCardList from "@/components/paymentComponents/SavedCardList.vue";
// import NotificationModal from "@/components/modalComponents/NotificationModal.vue";
// import { Modal } from 'bootstrap';

// Vue Props
defineProps({
    isPackageExpired: Boolean,
    isTrialExpired: Boolean,
    isPackageTrial: Number,
});


// Toastification 
const toast = useToast();

// Vuex Store
const store = useStore();

// Reactive Variables
let loading = ref(true);
const profileOveriewState = ref({
    states: {
        name: '',
        email: '',
        address_line_1: '',
        country_id: '',
        state_id: '',
        city: '',
        zipcode: '',
        userImg: '',
    },
    errors: {
        name: '',
        email: '',
        address_line_1: '',
        country_id: '',
        state_id: '',
        city: '',
        zipcode: '',
        userImg: ''
    }
});
const profileResetPasswordState = ref({
    states: {
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: ''
    },
    errors:{
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: ''
    }
});
const savedCards = ref({
  cards:[],
  selectedCard: null,
});
// const cardType = ref('STRIPE');
const countriesData = ref({
    countries: [],
    states: [],
});

// Vue Provide 
provide('savedCards', savedCards);

// Methods
function userProfileImgUpload(e){
    const imageFile = e.target.files[0];
    const imgFileTypes = ['image/webp', 'image/jpeg', 'image/png'];
    
    if(imageFile && imgFileTypes.includes(imageFile.type)){
        if(imageFile.size > 2097152){
            toast.error('File size must not be greater than 2MB.')
        }else{
            profileOveriewState.value.states.userImg = imageFile;
            userProfileImageSubmit();
        }
    }else{
        toast.error('Invalid file format!');
    }
}
async function userProfileImageSubmit(){
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }
    let data = new FormData();
    data.append('file',profileOveriewState.value.states.userImg);
    try{
        let res = await store.dispatch('Profile/userProfileImageUpdateAction', data, config)
        if(res.success){
            toast.success('Profile image updated successfully');
        }
    }catch(e){
        if(!e.response.data.success){
            toast.error(e.response.data.data.error[0]);
        }else{
            toast.error('Something unexpected happened.');
        }   
    }
}

function togglePassword(e){
    const el = e.target;
    const  parentEl = el.parentNode;
    const inputEl = parentEl.querySelector('input');

    if(!parentEl.classList.contains('show-password')){
        parentEl.classList.add('show-password');
        el.classList.remove('fa-eye-slash');
        el.classList.add('fa-eye');
        inputEl.setAttribute('type','text');
    }else{
        parentEl.classList.remove('show-password');
        el.classList.remove('fa-eye');
        el.classList.add('fa-eye-slash');
        inputEl.setAttribute('type','password');
    }
}
function toggleLoadingBtn(btn){
    if(!btn.classList.contains('loading')){
        btn.classList.add('loading');
        btn.disabled = true;
    }else{
        btn.classList.remove('loading');
        btn.disabled = false;
    }
}

function validateProfileOverviewForm(){
    let errCount = 0;
    const states = profileOveriewState.value.states; 
    const errors = profileOveriewState.value.errors;

    errors.name = states.name === '' ? 'Name field is required!' : '';
    // errors.companyName = states.companyName === '' ? 'Company name field is required!' : '';
    errors.address_line_1 = states.address_line_1 === '' ? 'Address field is required!' : '';
    errors.country_id = states.country_id === '' ? 'Country field is required!' : '';
    errors.state_id = states.state_id === '' ? 'State field is required!' : '';
    errors.city = states.city === '' ? 'City field is required!' : '';
    errors.zipcode = states.zipcode === '' ? 'Zipcode field is required!' : '';

    // if(states.phone === ''){
    //     errors.phone = 'Field is required!';
    // }else if(!states.phone.match(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im)){
    //     errors.phone = 'Phone Number is not valid!';
    // }else{
    //     errors.phone = '';
    // }

    if(states.email === ''){
        errors.email = 'Email field is required!';
    }else if(!states.email.match(/^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i)){
        errors.email = 'Email field is not valid!';
    }else{
        errors.email = '';
    }

    Object.keys(errors).forEach(error => {
        if(errors[error]){
            errCount++;
        }
    });    

    return errCount;
}
function validateProfileResetPasswordForm(){
    let errCount = 0;
    const states = profileResetPasswordState.value.states; 
    const errors = profileResetPasswordState.value.errors;

    errors.currentPassword = states.currentPassword === '' ? 'Field is required!' : '';
    errors.newPassword = states.newPassword === '' ? 'Field is required!' : '';
    if(!states.confirmNewPassword){
        errors.confirmNewPassword = 'Field is required!';
    }else if(states.newPassword !== states.confirmNewPassword){
        errors.confirmNewPassword = 'Password does not match!';
    }else{
        errors.confirmNewPassword = '';
    }
    Object.keys(errors).forEach(error => {
        if(errors[error]){
            errCount++;
        }
    });

    return errCount;
}

async function submitProfileOverviewForm(e){
    const submitBtn = e.target.querySelector('.submit-btn');
    if(!validateProfileOverviewForm()){
        try{
            toggleLoadingBtn(submitBtn);
            let res = await store.dispatch('Profile/updateUserProfileDataAction', {
                name: profileOveriewState.value.states.name,
                email: profileOveriewState.value.states.email,
                // company: profileOveriewState.value.states.companyName,
                address_line_1: profileOveriewState.value.states.address_line_1,
                country_id: profileOveriewState.value.states.country_id,
                state_id: profileOveriewState.value.states.state_id,
                city: profileOveriewState.value.states.city,
                zipcode: profileOveriewState.value.states.zipcode                

            }, {root:true});
            toast.success(res.message);    
        }catch(e){
            e.response.data.data.error.forEach(err => {
                toast.error(err);
            });
        }finally{
            toggleLoadingBtn(submitBtn);
        }
    }
}
async function submitProfileResetPasswordForm(e){
    const submitBtn = e.target.querySelector('.submit-btn');
    if(!validateProfileResetPasswordForm()){
        try{
            toggleLoadingBtn(submitBtn);
            let res = await store.dispatch('Profile/updateUserProfilePasswordAction', {
                current_password: profileResetPasswordState.value.states.currentPassword,
                new_password: profileResetPasswordState.value.states.newPassword,
                confirm_password: profileResetPasswordState.value.states.confirmNewPassword,
            }, {root:true});
            if(res.success){
                toast.success(res.message);

                // Reset Password Fields
                profileResetPasswordState.value.states.currentPassword = '';
                profileResetPasswordState.value.states.newPassword = '';
                profileResetPasswordState.value.states.confirmNewPassword = '';
            }
        }catch(e){
            e.response.data.data.error.forEach(err => {
                toast.error(err);
            });
        }finally{
            toggleLoadingBtn(submitBtn);
        }
    }
}   

async function getUserProfileData(){
    try{
        let res = await store.dispatch('Profile/getUserProfileDataAction',null,{root:true});
        let data = res.data;
        profileOveriewState.value.states.name = data.account.name;
        profileOveriewState.value.states.email = data.account.email;
        profileOveriewState.value.states.companyName = data.account.company;
        profileOveriewState.value.states.address_line_1 = data.billing_address.address_line_1;
        profileOveriewState.value.states.country_id = data.billing_address.country_id ? data.billing_address.country_id : 233;
        profileOveriewState.value.states.state_id = data.billing_address.state_id;
        profileOveriewState.value.states.city = data.billing_address.city;
        profileOveriewState.value.states.zipcode = data.billing_address.zipcode;
    }catch(e){
        if(e.code !== "ERR_NETWORK"){
            toast.error('Something unexpected happened!');
        }
    }
}

async function getCountries(){
    try{
        let res = await store.dispatch('Payment/getCountriesAction');
        if(res.success){
            countriesData.value.countries = res.data;
            if(profileOveriewState.value.states.country_id){
                getStates(profileOveriewState.value.states.country_id);
            }
        }
    }catch(e){
        if(e.code !== "ERR_NETWORK"){
            toast.error('Something unexpected happened');
        }
    }
}
async function getStates(id){
    const countryId = id;
    try{
        let res = await store.dispatch('Payment/getStatesAction',countryId);
        if(res.success){
            countriesData.value.states = res.data;
        }
    }catch(e){
        if(!e.response.data.success){
            countriesData.value.states = [];
            profileOveriewState.value.states.state_id = null;
        }else if(e.code !== "ERR_NETWORK"){
            toast.error('Something unexpected happened');
        }
    }
}
// async function getPaymentCards(){
//     try{
//         let res = await store.dispatch('Payment/getPaymentCardsAction');
//         if(res.is_success && res.user_cards.length > 0){
//             savedCards.value.cards = res.user_cards;
//             savedCards.value.selectedCard = res.user_cards.filter(card => card.default_card_token_id)[0]?.id;        
//         }
//     }catch(e){
//         if(e.code !== "ERR_NETWORK"){
//             toast.error('Something unexpected happened');
//         }
//     }
// }

// async function updatePackageInfo(){
//     try{
//         await store.dispatch('Packages/getCurrentPackageAction',null,{root:true});
//     }catch(e){
//         console.log(e);
//     }
// }

// async function cancelSubscription(e){
//     const submitBtn = e.target;
//     const confirmModal = Modal.getInstance(document.getElementById('cancel-subscription-confirm-modal'));
//     const successModal = new Modal(document.getElementById('cancel-subscription-success-modal'));

//     try{
//         toggleLoadingBtn(submitBtn);
//         let res = await store.dispatch('Payment/cancelSubscriptionAction');

//         if(res.success){
//             await updatePackageInfo();
//             confirmModal.hide();
//             successModal.show();
//         }

//     }catch(e){
//         if(e.code !== "ERR_NETWORK"){
//             if(!e.response.data.success){
//                 toast.error(e.response.data.message);
//             }else{
//                 toast.error('Something unexpected happened.');
//             }
//         }
//     }finally{
//         toggleLoadingBtn(submitBtn);
//     }
// }

async function init(){

    loading.value = true;

    // Profile Tabs Reset
    document.querySelectorAll('#userProfileTab button').forEach((tab)=>{
        tab.classList.remove('active');  
    });
    document.querySelectorAll('#myTabContent .tab-pane').forEach((tab)=>{
        tab.classList.remove('show');  
        tab.classList.remove('active');  
    });
    document.querySelector('#userProfileTab button:first-child').classList.add('active');
    document.querySelector('#myTabContent .tab-pane:first-child').classList.add('show');
    document.querySelector('#myTabContent .tab-pane:first-child').classList.add('active');

    // Get User Profile Data
    await getUserProfileData();

    // Get Countries
    await getCountries();

    // Get Saved Cards
    // await getPaymentCards();

    loading.value = false;
}

// Computed Properties
const userData = computed(()=>{
    const data = store.getters['Auth/getUser'];
    return {
        name: data.name,
        email: data.email,
        profileImage: data.profileImage ? data.profileImage : require('@/assets/images/user-profile-img.png'),
        isEmailVerified: data.isEmailVerified,
    }
});
// const packageData = computed(()=>{
//     return store.getters['Packages/getPackageData'];
// });


// Life Cycle Hooks
onMounted(() => {
    init();
});

</script>

<template>
    <section class="dashboard-agency-view user-profile-view">
        <LoaderComponent :isLoading="loading" />
        
        <div class="custom-container">

            <div class="primary-heading">
                <h2>User Profile</h2>
            </div>
            <div class="wrapper">
                <div class="profile-tabs">
                    <ul class="nav nav-tabs" id="userProfileTab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button
                                class="nav-link"
                                id="overview"
                                data-bs-toggle="tab"
                                data-bs-target="#profile-overview"
                                type="button"
                                role="tab"
                                aria-controls="overview"
                                aria-selected="true"
                            >
                                Profile Overview
                            </button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button
                                class="nav-link"
                                id="reset"
                                data-bs-toggle="tab"
                                data-bs-target="#profile-reset-password"
                                type="button"
                                role="tab"
                                aria-controls="reset"
                                aria-selected="false"
                            >
                                Update Password
                            </button>
                        </li>
                        <!-- <li class="nav-item" role="presentation">
                            <button
                                class="nav-link"
                                id="payment"
                                data-bs-toggle="tab"
                                data-bs-target="#profile-payment"
                                type="button"
                                role="tab"
                                aria-controls="payment"
                                aria-selected="false"
                            >
                                Payment Options
                            </button>
                        </li> -->
                        <!-- <li class="nav-item" role="presentation">
                            <button
                                class="nav-link"
                                id="package"
                                data-bs-toggle="tab"
                                data-bs-target="#profile-package-info"
                                type="button"
                                role="tab"
                                aria-controls="package"
                                aria-selected="false"
                            >
                                Package Details
                            </button>
                        </li> -->
                    </ul>
                </div>

                <div class="tab-content" id="myTabContent">
                    <div
                        class="tab-pane fade"
                        id="profile-overview"
                        role="tabpanel"
                        aria-labelledby="profile-overview"
                    >
                        <div class="profile-overview-container">
                            <form enctype="multipart/form-data" @submit.prevent="userProfileImageSubmit">
                                <div class="profile-img-container">
                                    <input type="file" class="d-none" id="user-profile-img-input" @input="userProfileImgUpload"  accept="image/png, image/jpeg, image/webp" >
                                    <div class="img-preview-box">
                                        <figure>
                                            <img :src="userData.profileImage" alt="user-profile-img"> 
                                            <label for="user-profile-img-input"><i class="fas fa-camera"></i></label>     
                                        </figure>
                                        <div>
                                            <h3>{{ userData.name }}</h3>
                                            <h4>{{ userData.email }}</h4>
                                        </div>                                  
                                    </div>
                                </div>
                            </form>

                            <form class="agency-form-2" @submit.prevent="submitProfileOverviewForm" novalidate>
                                <div class="personal-info-container">
                                    <h3>Personal Details</h3>
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6 col-12">
                                            <div class="form-group">
                                                <label>Name</label>
                                                <div class="d-relative">
                                                    <input type="text" class="form-control" v-model="profileOveriewState.states.name">
                                                    <span class="error-message" v-if="profileOveriewState.errors.name"><i class="fas fa-exclamation-circle"></i> {{ profileOveriewState.errors.name }}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-12">
                                            <div class="form-group">
                                                <label>Email</label>
                                                <div class="d-relative">
                                                    <input type="email" class="form-control" v-model="profileOveriewState.states.email" readonly>
                                                    <span class="error-message" v-if="profileOveriewState.errors.email"><i class="fas fa-exclamation-circle"></i> {{ profileOveriewState.errors.email }}</span> 
                                                    <Popper
                                                        class="error"
                                                        :class="{'verified': userData.isEmailVerified}"
                                                        :content="userData.isEmailVerified ? 'Email is verified.' : 'Email not verified.'"
                                                        :arrow="true"
                                                        :hover="true"
                                                        placement="top">
                                                            <i class="fas fa-exclamation-circle" v-if="!userData.isEmailVerified"></i>
                                                            <i class="fas fa-check-circle" v-else></i>
                                                    </Popper>
                                                </div>
                                                <!-- <small class="email-verification-notice" v-if="!userData.isEmailVerified">Didn't receive verification email, <a href="#" @click.prevent="sendEmailVerficationMail">Click Here</a></small>    -->
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-12 d-none">
                                            <div class="form-group">
                                                <label>Company Name</label>
                                                <div class="d-relative">
                                                    <input type="text" class="form-control" v-model="profileOveriewState.states.companyName">
                                                    <span class="error-message" v-if="profileOveriewState.errors.companyName"><i class="fas fa-exclamation-circle"></i> {{ profileOveriewState.errors.companyName }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="billing-info-container">
                                    <h3>Additional Details</h3>
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6 col-12">
                                            <div class="form-group">
                                                <label>Country</label>
                                                <div class="d-relative">
                                                    <select class="form-control" v-model="profileOveriewState.states.country_id" @change="(getStates(profileOveriewState.states.country_id))">
                                                        <option value="" disabled hidden selected>Country</option>
                                                        <option v-for="country in countriesData.countries" :key="country.id" :value="country.id">{{ country.name }}</option>
                                                    </select>
                                                    <i class="fas fa-angle-down"></i>
                                                    <span class="error-message" v-if="profileOveriewState.errors.country_id"><i class="fas fa-exclamation-circle"></i> {{ profileOveriewState.errors.country_id }}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-12">
                                            <div class="form-group">
                                                <label>State</label>
                                                <div class="d-relative">
                                                   <select class="form-control" v-model="profileOveriewState.states.state_id" :disabled="!profileOveriewState.states.country_id">
                                                        <option value="" disabled hidden selected>State</option>
                                                        <option v-if="countriesData.states.length === 0" value="null">No States Available</option>
                                                        <option v-for="state in countriesData.states" :key="state.id" :value="state.id">{{ state.name }}</option>
                                                    </select>
                                                    <i class="fas fa-angle-down"></i>
                                                    <span class="error-message" v-if="profileOveriewState.errors.state_id"><i class="fas fa-exclamation-circle"></i> {{ profileOveriewState.errors.state_id }}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-md-12 col-12">
                                            <div class="form-group">
                                                <label>Address</label>
                                                <div class="d-relative">
                                                    <input type="text" class="form-control" v-model="profileOveriewState.states.address_line_1">
                                                    <span class="error-message" v-if="profileOveriewState.errors.address_line_1"><i class="fas fa-exclamation-circle"></i> {{ profileOveriewState.errors.address_line_1 }}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-12">
                                            <div class="form-group">
                                                <label>City</label>
                                                <div class="d-relative">
                                                    <input type="text" class="form-control" v-model="profileOveriewState.states.city">
                                                    <span class="error-message" v-if="profileOveriewState.errors.city"><i class="fas fa-exclamation-circle"></i> {{ profileOveriewState.errors.city }}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-12">
                                            <div class="form-group">
                                                <label>Zip Code</label>
                                                <div class="d-relative">
                                                    <input type="number" class="form-control" v-model="profileOveriewState.states.zipcode">
                                                    <span class="error-message" v-if="profileOveriewState.errors.zipcode"><i class="fas fa-exclamation-circle"></i> {{ profileOveriewState.errors.zipcode }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- <button type="submit" class="primary-btn primary-bg"><i class="fas fa-save"></i> Save Changes</button> -->

                                <button class="primary-btn primary-bg submit-btn" type="submit">
                                    <span><i class="fas fa-save"></i> Save Changes</span>
                                    <i class="fas fa-spinner fa-spin loading"></i>
                                </button>

                            </form>
                        </div>
                    </div>

                    <div
                        class="tab-pane fade"
                        id="profile-reset-password"
                        role="tabpanel"
                        aria-labelledby="profile-reset-password"
                    >
                        <div class="profile-reset-password">
                            <form class="agency-form-2" @submit.prevent="submitProfileResetPasswordForm" novalidate>
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-12">
                                        <div class="form-group">
                                            <label>Current Password</label>
                                            <div class="d-relative">
                                                <input type="password" class="form-control" v-model="profileResetPasswordState.states.currentPassword">
                                                <i class="hide-password fas fa-eye-slash" @click="togglePassword"></i>
                                                <span class="error-message" v-if="profileResetPasswordState.errors.currentPassword"><i class="fas fa-exclamation-circle"></i> {{ profileResetPasswordState.errors.currentPassword }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-12">
                                        <div class="form-group">
                                            <label>New Password</label>
                                            <div class="d-relative">
                                                <input type="password" class="form-control" v-model="profileResetPasswordState.states.newPassword">
                                                <i class="hide-password fas fa-eye-slash" @click="togglePassword"></i>
                                                <span class="error-message" v-if="profileResetPasswordState.errors.newPassword"><i class="fas fa-exclamation-circle"></i> {{ profileResetPasswordState.errors.newPassword }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-12">
                                        <div class="form-group">
                                            <label>Confirm New Password</label>
                                            <div class="d-relative">
                                                <input type="password" class="form-control" v-model="profileResetPasswordState.states.confirmNewPassword">
                                                <i class="hide-password fas fa-eye-slash" @click="togglePassword"></i>
                                                <span class="error-message" v-if="profileResetPasswordState.errors.confirmNewPassword"><i class="fas fa-exclamation-circle"></i> {{ profileResetPasswordState.errors.confirmNewPassword }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                 <button class="primary-btn primary-bg submit-btn" type="submit">
                                    <span><i class="fas fa-save"></i> Save Changes</span>
                                    <i class="fas fa-spinner fa-spin loading"></i>
                                </button>
                            </form>
                        </div>
                    </div>
                        
                    <!-- <div
                        class="tab-pane fade"
                        id="profile-payment"
                        role="tabpanel"
                        aria-labelledby="profile-payment"
                    >
                        <div class="profile-payment-container">
                            <h3>Saved Cards</h3>
                            <SavedCardList :changeCard="true" :cardType="cardType"/>
                        </div>
                    </div> -->

                    <!-- <div
                        class="tab-pane fade"
                        id="profile-package-info"
                        role="tabpanel"
                        aria-labelledby="profile-package-info"
                    >
                        <div class="profile-package">
                            <div class="profile-package-top">
                                <div class="current-package">
                                    <h5>Subscribed To</h5>
                                    <h3 v-if="packageData.pkg_cycle !== 'LIFETIME' && packageData.is_pkg_exp">NA</h3>
                                    <h3 v-else>{{ packageData.pkg_n }} Package</h3>
                                </div>

                                <div class="exp-timer-wrapper" v-if="packageData.pkg_cycle !== 'LIFETIME'">
                                    <h5 v-if="!packageData.is_pkg_exp && packageData.pkg_exp_days >= 0">Expires On</h5>
                                    <h5 v-else>Expired</h5>
                                    <h3>{{ packageData.pkg_end_at ? packageData.pkg_end_at : '---' }}</h3>
                                </div>
                                <div class="exp-timer-wrapper" v-else>
                                    <h5>Explore Other Packages</h5>
                                    <div class="d-flex justify-content-end">
                                        <router-link :to="{name: 'packages'}" class="primary-btn primary-bg">Upgrade Now</router-link>
                                    </div>
                                </div>
                            </div>
                            <div class="profile-package-bottom">
                                <div class="row">
                                    <div class="col-lg-3 col-md-3 col-6">
                                        <div class="profile-package-box profile-package-box--1">
                                            <p>Total Projects</p>
                                            <h3 v-if="packageData.pkg_cycle !== 'LIFETIME' && packageData.is_pkg_exp">NA</h3>
                                            <h3 v-else>{{ packageData.pro_lt ? packageData.pro_lt : 0 }}</h3>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-3 col-6">
                                        <div class="profile-package-box profile-package-box--2">
                                            <p>Projects Left</p>
                                            <h3 v-if="packageData.pkg_cycle !== 'LIFETIME' && packageData.is_pkg_exp">NA</h3>
                                            <h3 v-else>{{ packageData.pro_lt_left ? packageData.pro_lt_left : 0 }}</h3>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-3 col-6">
                                        <div class="profile-package-box profile-package-box--3">
                                            <p>Total Links</p>
                                            <h3 v-if="packageData.pkg_cycle !== 'LIFETIME' && packageData.is_pkg_exp">NA</h3>
                                            <h3 v-else>{{ packageData.li_lt ? packageData.li_lt : 0 }}</h3>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-3 col-6">
                                        <div class="profile-package-box profile-package-box--4">
                                            <p>Links Left</p>
                                            <h3 v-if="packageData.pkg_cycle !== 'LIFETIME' && packageData.is_pkg_exp">NA</h3>
                                            <h3 v-else>{{ packageData.li_lt_left ? packageData.li_lt_left : 0 }}</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="profile-package-cancel" v-if="packageData.pkg_cycle !== 'LIFETIME' && !packageData.is_pkg_cancelled && !packageData.is_pkg_exp">
                                <a href="#" class="primary-btn primary-bg" data-bs-toggle="modal" data-bs-target="#cancel-subscription-confirm-modal">Cancel Subscription</a>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </section>

    <!-- Cancel Subscription Confirm Modal Start -->
    <!-- <NotificationModal modalId="cancel-subscription-confirm-modal" type="question" :verticalCentered="true" dataBackDrop="static" :dataKeyboard="true" :showCloseBtn="false">
        <div>
            <h4>Are You Sure?</h4>
            <p>Your subscription will be canceled and discontinued your recurring payments.</p>
        </div>
        <div class="modal-btns">
            <button class="primary-btn primary-bg submit-btn" @click.prevent="cancelSubscription">
                <span>Unsubscribed</span>
                <i class="fas fa-spinner fa-spin loading"></i>
            </button>
            <a href="#" class="primary-btn primary-transparent-bg" data-bs-dismiss="modal" aria-label="Close">Cancel</a>
        </div>
    </NotificationModal> -->

    <!-- Cancel Subscription Success Modal Start -->
    <!-- <NotificationModal modalId="cancel-subscription-success-modal" type="success" :verticalCentered="true" dataBackDrop="static" :dataKeyboard="true" :showCloseBtn="true">
        <div>
            <h4>Subscription Canceled</h4>
            <p>Your subscription has been canceled successfully, and you won’t be charged by us anymore. You can still add projects and links till ({{ packageData.pkg_end_at ? packageData.pkg_end_at : '---' }}), but your data won’t be updated after ({{ packageData.pkg_end_at ? packageData.pkg_end_at : '---' }}).  </p>        
        </div>
        <div class="modal-btns">
            <router-link :to="{name: 'dashboard'}" class="primary-btn primary-bg">Go To Dashboard</router-link>
            <router-link :to="{name: 'packages'}" class="primary-btn primary-transparent-bg">Explore Other Packages</router-link>
        </div>
    </NotificationModal> -->
</template>