<script setup>
import { useSlots } from "vue";

const slots = useSlots();
</script>

<template>
    <div class="project-thumbnail">
        <div class="project-thumbnail-top">
            <slot name="project-thumbnail-top-name" v-if="slots['project-thumbnail-top-name']"></slot>
            <slot name="project-thumbnail-top-slot" v-if="slots['project-thumbnail-top-slot']"></slot>    
        </div>
        <div class="project-thumbnail-bottom">
            <div class="row gx-5">
                <div class="col-xl-2 col-lg-4 col-md-4 col-12" v-if="slots['project-thumbnail-bottom-1']">
                    <slot name="project-thumbnail-bottom-1"></slot>
                </div>
                <div class="col-xl-2 col-lg-4 col-md-4 col-12" v-if="slots['project-thumbnail-bottom-2']">
                    <slot name="project-thumbnail-bottom-2"></slot>
                </div>
                <div class="col-xl-2 col-lg-4 col-md-4 col-12" v-if="slots['project-thumbnail-bottom-3']">
                    <slot name="project-thumbnail-bottom-3"></slot>
                </div>
                <div class="col-xl-2 col-lg-4 col-md-4 col-12" v-if="slots['project-thumbnail-bottom-4']">
                    <slot name="project-thumbnail-bottom-4"></slot>
                </div>
                <div class="col-xl-2 col-lg-4 col-md-4 col-12" v-if="slots['project-thumbnail-bottom-5']">
                    <slot name="project-thumbnail-bottom-5"></slot>
                </div>
                <div class="col-xl-2 col-lg-4 col-md-4 col-12" v-if="slots['project-thumbnail-bottom-6']">
                    <slot name="project-thumbnail-bottom-6"></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<style></style>
