import { createRouter, createWebHistory } from 'vue-router'
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import AuthLayout from "@/layouts/AuthLayout.vue";
import Dashboard from "@/views/Dashboard.vue";
import Welcome from "@/views/Welcome.vue";
import AddProject from "@/views/projects/AddProject.vue";
import AddLinks from "@/views/projects/AddLinks.vue";
import Projects from "@/views/projects/Projects.vue";
import UpdatedLinks from "@/views/projects/UpdatedLinks.vue";
import OverviewLinks from "@/views/projects/OverviewLinks.vue";
import Packages from "@/views/Packages.vue";
// import Payment from "@/views/Payment.vue";
import UserProfile from "@/views/profiles/UserProfile.vue";
import AuthLogin from "@/views/auth/AuthLogin.vue";
import AuthSignUp from "@/views/auth/AuthSignUp.vue";
import AuthForgetPassword from "@/views/auth/AuthForgetPassword.vue";
import AuthUpdatePassword from "@/views/auth/AuthUpdatePassword.vue";
import EmailVerification from "@/views/auth/EmailVerification.vue";
// import InvoicesView from "@/views/invoices/InvoicesView.vue";
// import InvoicesDetailView from "@/views/invoices/InvoicesDetailView.vue";
import HttpStatusLegends from "@/views/HttpStatusLegends.vue";
import NotFound from "@/views/NotFound.vue";
import store from '@/store';
import { Modal } from 'bootstrap';

const routes = [
  {
    path: "/",
    redirect: "dashboard",
    meta: {isAuthRequired: true, isEmailVerifiedRequired: true},
    component: DefaultLayout,
    children: [
        {
            path: "/dashboard",
            name: "dashboard",
            meta:{title:"Dashboard"},
            component: Dashboard,
        },
        {
            path: "/welcome",
            name: "welcome",
            meta:{title:"Welcome"},
            component: Welcome,
        },
        {
            path: "/add-project",
            name: "add-project",
            meta:{title:"Add Project"},
            component: AddProject,
        },
        {
            path: "/add-links/:id",
            name: "add-links",
            meta:{title:"Add Links"},
            component: AddLinks,
        },
        {
            path: "/projects",
            name: "projects",
            meta:{title:"Projects"},
            component: Projects,
        },
        {
            path: "/projects/:id",
            name: "project",
            meta:{title:"Project"},
            component: OverviewLinks,
        },
        {
            path: "/updated-links/:id",
            name: "updated-links",
            meta:{title:"Updated Links"},
            component: UpdatedLinks,
        },
        {
            path: "/packages",
            name: "packages",
            meta:{title:"Packages"},
            component: Packages,
        },
        // {
        //     path: "/payment/:id?",
        //     name: "payment",
        //     meta:{title:"Payment"},
        //     component: Payment,
        // },
        {
            path: "/profile",
            name: "profile",
            meta:{title:"User Profile"},
            component: UserProfile,
        },
        // {
        //     path: "/invoices",
        //     name: "invoices",
        //     meta:{title:"Invoices"},
        //     component: InvoicesView,
        // },
        // {
        //     path: "/invoices/:id",
        //     name: "invoice-detail",
        //     meta:{title:"Invoice Details"},
        //     component: InvoicesDetailView,
        // },
        {
            path: "/http-status-legends",
            name: "http-status-legends",
            meta:{title:"HTTP Status Legends"},
            component: HttpStatusLegends,
        },
        { path: '/:pathMatch(.*)*', 
            component: NotFound, 
            meta: {isAuthRequired: true, title:'Page Not Found'}
        },
    ],
  },
  {
    path: "/login",
    redirect: "login",
    meta: {isAuthRequired: false, isEmailVerifiedRequired: false},
    component: AuthLayout,
    children: [
        {
            path: "/login",
            name: "login",
            meta:{
                title:"Login",
                metaTags: [
                    {
                        'name': 'description',
                        'content': "Log in to Link Wiki and start tracking your backlinks in a blink. Link Wiki will ensure that acquired links are within your perspective so you're informed"
                    }
                ]
            },
            component: AuthLogin,
        },
        {
            path: "/signup",
            name: "signup",
            meta:{
                title:"Sign Up",
                metaTags: [
                    {
                        'name': 'description',
                        'content': "Signup for free to Link Wiki. Testing Link Wiki is Free for the first 30 days and you don't need to give any payment information to sign up to our portal."
                    }
                ]
            },
            component: AuthSignUp,
        },
        {
            path: "/forget-password",
            name: "forgetpassword",
            meta:{title:"Forget Password"},
            component: AuthForgetPassword,
        },
        {
            path: "/update-password/",
            name: "updatepassword",
            meta:{title:"Update Password"},
            component: AuthUpdatePassword,
        },
    ]
  },
  {
    path: "/email-verification",
    name: "email-verification",
    meta:{title:"Email Verification", isAuthRequired: true, isEmailVerifiedRequired: false},
    component: EmailVerification,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to,from,next)=>{
    
    // Title & Meta Tags
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
    const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
    if (nearestWithTitle) document.title =  nearestWithTitle.meta.title + ' | Link Wiki';

    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

    if (nearestWithMeta){
        nearestWithMeta.meta.metaTags.map(tagDef => {
            const tag = document.createElement('meta');
    
            Object.keys(tagDef).forEach(key => {
                tag.setAttribute(key, tagDef[key]);
            });
    
            tag.setAttribute('data-vue-router-controlled', '');
    
            return tag;
        })
        .forEach(tag => document.head.appendChild(tag, 'title'));
    }

    // Removing Modal on Page Change
    const modalBackDrop = document.querySelector('.modal-backdrop');
    const body = document.querySelector('body');    
    if(modalBackDrop){

        const modals = document.querySelectorAll('.modal.show');
        if(modals.length > 0){
            modals.forEach(modal => {
                Modal.getInstance(modal).hide();
            });
        }

        modalBackDrop.remove();
        if(body.classList.contains('modal-open')){
            body.classList.remove('modal-open');
            body.style.overflow = "auto";
            body.style.overflowX = "hidden";
            body.style.paddingRight = "0";
        }
    }

    // Removing Tooltips on Page Change
    const tooltips = document.querySelectorAll('.tooltip');
    if(tooltips.length > 0){
        tooltips.forEach(tooltip => tooltip.remove());
    }

    // If User is not authenticated.
    if(to.meta.isAuthRequired && !store.getters['Auth/isAuthenticated']){
        if(to.name === 'payment'){
            let newQuery = to.query;

            if(to.query.email_verification){
                newQuery = {...{email_verification: to.query.email_verification}};
                next({name: 'login', query:{id:to.params.id,cycle:to.query.cycle,...newQuery}})
            }

            next({name: 'signup', query:{id:to.params.id,cycle:to.query.cycle,...newQuery}})
        }else if(to.name === 'updated-links'){
            next({name: 'login',query:{updates:to.params.id, pid: to.query.pid}})
        }else if(to.query.email_verification){
            next({name: 'login',query:{email_verification:to.query.email_verification}})
        }

        next({name: 'login'});
    }else if(store.getters['Auth/isAuthenticated'] && !to.meta.isAuthRequired){
        if(!store.getters['Auth/isEmailVerifiedGetter']){
           next({name: 'email-verification'}) 
        }
        next({name:'dashboard'});
    }else if(to.meta.isEmailVerifiedRequired && !store.getters['Auth/isEmailVerifiedGetter']){
        let newQuery = {}
        if(Object.keys(to.query).length > 0){
            Object.entries(to.query).forEach(([key, value]) => {
                const obj = {};
                obj[key] = value;
                newQuery = {...obj};
            });
        }
        next({name:'email-verification', query:{...newQuery}});
    }else if(store.getters['Auth/isEmailVerifiedGetter'] && !to.meta.isEmailVerifiedRequired){
        next({name:'welcome'});
    }else{
        next();
    }
    
}); 

export default router
