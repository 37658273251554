<script setup>
    import { useSlots, defineProps } from 'vue';  

    // Props 
    defineProps({
        modalId: String,
        type: String,
        verticalCentered: Boolean,
        dataBackDrop: String,
        dataKeyboard: Boolean,
        showCloseBtn: Boolean,
    });

    // Slots
    const slots = useSlots();
</script>

<template>
    <Teleport to="body">
        <div class="modal fade notification-modal" :class="'notification-modal-' + type" :id="modalId" tabindex="-1" :aria-labelledby="modalId + 'Label'" aria-hidden="true" :data-bs-backdrop="dataBackDrop" :data-bs-keyboard="dataKeyboard">
            <div class="modal-dialog" :class="{'modal-dialog-centered': verticalCentered }">
                <div class="modal-content">
                    <button v-if="showCloseBtn" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    <div class="modal-body">
                        <i class="fas fa-times-circle" v-if="type === 'error'"></i>
                        <i class="fas fa-exclamation-circle" v-if="type === 'warning'"></i>
                        <i class="fas fa-check-circle" v-if="type === 'success'"></i>
                        <i class="fas fa-question-circle" v-if="type === 'question'"></i>
                        <div class="notification-content">
                            <slot></slot> 
                        </div>   
                    </div>
                    <div class="modal-footer" v-if="slots['modal-footer']">
                        <slot name="modal-footer"></slot>
                    </div>
                </div>
            </div>
        </div>
    </Teleport>    
</template>