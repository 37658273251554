import Auth from './auth';
import Profile from './profile';
import Domain from './domain';
import Packages from './packages';
import Payment from './payment';
import Other from './other';
import { createStore } from 'vuex';
import createPersistedState from "vuex-persistedstate";


export default createStore({
  plugins: [
    createPersistedState({
      storage: window.localStorage,
    }),
  ],

  modules: {
    Auth,
    Profile,
    Domain,
    Packages,
    Payment,
    Other
  }
});
