<script setup>
import { computed, onMounted, ref } from "vue";
import { useRouter } from 'vue-router'
import { useToast } from "vue-toastification";
import { useStore } from "vuex";
// import NotificationModal from "@/components/modalComponents/NotificationModal.vue";
// import { Modal } from 'bootstrap';

// Router
const router = useRouter();

// Vuex Store
const store = useStore();

// Toastification 
const toast = useToast();

// Reactive Variables
const error = ref('');
const authData = ref({
    states: {
        domain: "",
    },
    errors: {
        domain: "",
    }
});
const isLoading = ref(false);

// Methods
function toggleLoadingBtn(btn){
    if(!btn.classList.contains('loading')){
        btn.classList.add('loading');
        btn.disabled = true;
    }else{
        btn.classList.remove('loading');
        btn.disabled = false;
    }
}
function domainValidation(domain) {
    return String(domain)
        .toLowerCase().match(/^(http|https):\/\/(?:(?:(?:[a-zA-z\-]+)\:\/{1,3})?(?:[a-zA-Z0-9])(?:[a-zA-Z0-9\-\.]){1,61}(?:\.[a-zA-Z]{2,})+|\[(?:(?:(?:[a-fA-F0-9]){1,4})(?::(?:[a-fA-F0-9]){1,4}){7}|::1|::)\]|(?:(?:[0-9]{1,3})(?:\.[0-9]{1,3}){3}))(?:\:[0-9]{1,5}|(\/))?$/);
}
function domainProtocolValidation(domain){
    return String(domain)
        .toLowerCase().match(/^(http|https)/);
}
function formValidation() {
    let errCount = 0;
    const states = authData.value.states;
    const errors = authData.value.errors;

    if (!states.domain) {
        errors.domain = "The domain URL is required.";
    }else if (!domainProtocolValidation(states.domain)) {
        errors.domain = "Please add HTTP / HTTPS with your domain URL.";
    }else if (!domainValidation(states.domain)) {
        errors.domain = "Please enter valid domain URL.";
    }else {
        errors.domain = "";
    }

    for (const key of Object.keys(errors)) {
        if (errors[key]) {
            errCount++;
        } else {
            errCount = errCount === 0 ? 0 : errCount--;
        }
    }
    return errCount;
}
async function addProject(e) {
    const submitBtn = e.target.querySelector('.submit-btn');
    if(error.value){
        toast.error(error.value);
    }
    else if(!formValidation()) {
        try{
            isLoading.value = true;
            toggleLoadingBtn(submitBtn);
            let res = await store.dispatch('Domain/addDomainAction',{domain:computedDomainURL.value, projectCountLeft: packageData.value.pro_lt_left - 1},{root:true});
            if(res.success && res.http_status_code === 201){
                toast.success("Project successfully added.");
                setTimeout(()=>{
                    localStorage.setItem('pro-a-type', 'add');
                    router.push(`/add-links/${res.project_id}`);
                },1000) 
            }else if(res.success && res.http_status_code === 208){
                toast.error("Project already registered.");
            }else if(!res.success){
                toast.error(res.errors.domain[0]);
            }
        }catch(e){
            if(!e.response.data.success && e.response.data.http_status_code === 422){
                console.log(e.response.data.success);
                toast.error(e.response.data.messages[0]);
            }else if(!e.response.data.success){
                toast.error(e.response.data.message);
            }
        }finally{
            isLoading.value = false;
            toggleLoadingBtn(submitBtn);
        }   
    }
}
function cancelProject() {
    router.push({name: 'dashboard'});
}

function init(){
    // Default Values
    error.value = '';
    isLoading.value = false;

    // Package Trial & Subscription Errors
    // let packageExpiryModal = new Modal(document.getElementById('package-expiry-modal'));
    // let projectLimitModal = new Modal(document.getElementById('project-limit-modal'));
    // if(props.isPackageTrial && props.isTrialExpired){
    //     packageExpiryModal.show();
    //     error.value = `<h4>Trial Period Expired</h4><p>Your trial period has ended. Please upgrade or renew your package to keep tracking.</p>`;
    // }
    // if(packageData.value.pkg_cycle !== 'LIFETIME' && packageData.value.is_pkg_exp){
    //     packageExpiryModal.show();
    //     error.value = `<h4>Package Expired</h4><p>Your subscription period has ended. Please upgrade or renew your package to keep tracking.</p>`;
    // }
    // else if(packageData.value.pro_lt_left <= 0){
    //     projectLimitModal.show();
    //     error.value = "<h4>Project Limit Exceeded</h4><p>You have 0 remaining projects to add, please update your subscription package to add more projects.</p>";
    // }
    // else{
    //     error.value = '';
    // }
}

// Computed Properties
const packageData = computed(()=>{
    return store.getters['Packages/getPackageData'];
});
const computedDomainURL = computed(()=>{
    return authData.value.states.domain.slice(-1) === '/' ? authData.value.states.domain.substring(0,authData.value.states.domain.length - 1) : authData.value.states.domain;
});

// Life Cycle Methods
onMounted(()=>{
    init();
});
</script>

<template>
    <section class="dashboard-agency-view add-project-view">
        <div class="custom-container">
            <div class="primary-heading text-center">
                <h2>Add Your Project</h2>
                <!-- <p>You have ({{ packageData.pro_lt_left }} / {{ packageData.pro_lt }}) remaining projects to add.</p> -->
            </div>
            <div class="add-project-form">
                <form class="agency-form" @submit.prevent="addProject">
                    <div class="form-group">
                        <label>The Domain URL</label>
                        <div class="d-relative">
                            <input
                                v-model.trim="authData.states.domain"
                                type="text"
                                class="form-control"
                                placeholder="https://www.yourdomain.com"
                            />
                            <i class="fas fa-globe"></i>
                            <span class="error-message" v-if="authData.errors.domain"
                                ><i class="fas fa-exclamation-circle"></i>
                                {{ authData.errors.domain }}</span
                            >
                        </div>
                    </div>

                    <div class="form-buttons">
                        <button class="primary-btn primary-bg submit-btn" type="submit">
                            <span>Add Project</span>
                            <i class="fas fa-spinner fa-spin loading"></i>
                        </button>
                        <button
                            class="primary-btn primary-transparent-bg"
                            @click.prevent="cancelProject"
                            :disabled="isLoading"
                        >
                            Cancel
                        </button>
                    </div>
                </form>
            </div>
        </div>

        <!-- Notification(Error) Package Expired Modal Start -->
        <!-- <NotificationModal modalId="package-expiry-modal" type="error" :verticalCentered="true" dataBackDrop="static" :dataKeyboard="true" :showCloseBtn="false">
            <div v-html="error"></div>
            <div class="modal-btns">
                <router-link class="primary-btn primary-bg" :to="{name:'packages'}">Explore Packages</router-link>
                <router-link class="primary-btn primary-transparent-bg" :to="{name:'dashboard'}">Go To Dashboard</router-link>
            </div>
        </NotificationModal> -->

        <!-- Notification(Error) Project Limit Modal Start -->
        <!-- <NotificationModal modalId="project-limit-modal" type="error" :verticalCentered="true" dataBackDrop="static" :dataKeyboard="true" :showCloseBtn="false">
            <div v-html="error"></div>
            <div class="modal-btns">
                <router-link class="primary-btn primary-bg" :to="{name:'packages'}">Explore Packages</router-link>
                <router-link class="primary-btn primary-transparent-bg" :to="{name:'dashboard'}">Go To Dashboard</router-link>
            </div>
        </NotificationModal> -->
    </section>
</template>

<style></style>
