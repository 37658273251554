<script setup>
import { ref, defineProps } from "vue";

// Vue Props
const props = defineProps({
    chartData: Object,
    chartColor: String,
    title: String,
});

// Non-Reactive Variables
const xDefault = ['Jan','Feb','Mar','April','May','Jun'];
const yDefault = [0,0,0,0,0,0];

// Reactive Variable
const chartOptions = ref({
    colors: [props.chartColor],
    chart: {
        type: 'area',
        height: '100%',
        toolbar: {
            show: false,
        },
        sparkline: {
        enabled: true
        },
    },
    dataLabels: {
        enabled: false
    },
    stroke: {
        curve: 'smooth'
    },
    grid: {
        show: false,
        padding: {
            right: -5,
            left: -10,
            top: 5,
            bottom: 5,
        }
    },
    xaxis: {
        categories: props.chartData.x ? props.chartData.x : xDefault,
        labels:{
            show: false,
        },
        axisTicks: {
            show: false,
        },
    },
    yaxis:{
        labels:{
            show: false,
        },
    },
    tooltip:{
        x: {
            formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
                return props.chartData.x ? props.chartData.x[dataPointIndex] : xDefault[dataPointIndex];
            }
        },
    },
    legend:{
        show: false,
    },
});

</script>

<template>
    <apexchart type="area" height="100%" :options="chartOptions" 
        :series="[
            {
                name: title,
                data: chartData.y ? chartData.y : yDefault,
            }
        ]
    ">
    </apexchart>
</template>

<style></style>
