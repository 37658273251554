import { PackagesServices } from '@/services/packagesServices';
import moment from 'moment';
// import moment from 'moment';

// Calc Remaining Days FN
// function calcRemainingDays(str){

//     const givenDate = moment(str, 'YYYY-MM-DD HH:mm:ss').utc();
//     const currentDate = moment().utc().format('YYYY-MM-DD HH:mm:ss');

//     const m1 = givenDate.diff(currentDate,'minutes');
//     let numdays = Math.floor(m1 / 1440);
//     numdays = numdays < 0 ? 0 : numdays;

//     return numdays; 
// }

export default {
    namespaced: true,
    state:{
        pkg:{}
    },
    mutations:{
        // Update User Package Info 
        updatePackageInfoMutation(state,payload){
            state.pkg['pkg_id'] = payload.package_id;
            state.pkg['is_trial'] = payload.is_on_trial;
            state.pkg['pkg_n'] = payload.package_name;
            state.pkg['pro_lt'] = payload.projects_limits;
            state.pkg['pro_lt_left'] = payload.projects_limit_left;
            state.pkg['li_lt'] = payload.links_limits;
            state.pkg['li_lt_left'] = payload.links_limit_left;
            state.pkg['pkg_exp_days'] = payload.package_left_days;
            state.pkg['pkg_end_at'] = payload.package_end_at ? moment(payload.package_end_at).format('MM-DD-YYYY') : '---';
            state.pkg['trial_end_at'] = payload.trial_ends_at ? moment(payload.trial_ends_at).format('MM-DD-YYYY') : '---';
            state.pkg['trial_exp_days'] = payload.trial_left_days;
            state.pkg['is_trial_exp'] = payload.is_trial_expired;
            state.pkg['is_pkg_exp'] = payload.is_package_expired;
            state.pkg['pkg_cycle'] = payload.package_cycle;
            state.pkg['is_pkg_cancelled'] = payload.is_package_cancelled;
        },

        // Update Project Left Count
        updateProjectCountMutation(state,payload){
            state.pkg['pro_lt_left'] = payload;
        },
        
        // Update Links Left Count
        updateLinkCountMutation(state,payload){
            state.pkg['li_lt_left'] = payload;
        },
    },
    getters:{
        getPackageData: state => state.pkg,
    },
    actions:{
        async getAllPackagesAction(){
            let res = await PackagesServices.getAllPackagesAPI();
            let data = res.data;
            return data;
        },
        async getCurrentPackageAction({commit}){
            let res = await PackagesServices.getCurrentPackageAPI();
            let data = res.data;
            commit('updatePackageInfoMutation',data);
            return data;
        },
        async customPackageQuoteAction(_,payload){
            let res = await PackagesServices.customPackageQuoteAPI(payload);
            let data = res.data;
            return data;
        }
    }
}