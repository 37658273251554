<script setup>
import { defineEmits, defineProps } from 'vue';
import { RouterLink, useRouter } from "vue-router";
import { useStore } from 'vuex';

// Props
defineProps({
    userData: Object,
    packageData: Object,
});

// Emits
const emit = defineEmits(['sidebarEvent']);

// Vue Router
const router = useRouter();

// Vuex Store
const store = useStore();

function sidebarOpen(){
    emit('sidebarEvent', true);
}

// Methods
async function logout(){
    try{
        let res = await store.dispatch('Auth/logoutAction',null,{root:true});
        if(res.success){
            router.replace({name: 'login'});
        }
    }catch(e){
        console.log(e);
    }
}

</script>

<template>
    <header class="header">
        <div class="header-top">
            <div class="custom-container">
                <div class="row align-items-center">
                    <div class="col-lg-2 col-3">
                        <button class="side-menu-btn" @click.prevent="sidebarOpen">
                            <i class="fas fa-bars"></i>
                        </button>
                    </div>
                    <div class="col-lg-10 col-9">
                        <div class="header-top-info">
                            <div class="header-top-contact">
                                <!-- <p v-if="Object.keys(packageData).length === 0"><span class="loading"></span></p>
                                <p v-else-if="packageData.is_trial && packageData.is_trial_exp">Your trial period has ended</p>
                                <p v-else-if="packageData.is_trial && packageData.trial_exp_days > 0">Your trial will expire on {{ packageData.trial_end_at }}</p>
                                <p v-else-if="packageData.pkg_cycle !== 'LIFETIME' && packageData.is_pkg_exp">Your package has expired</p> -->
                                <!-- <router-link
                                    v-if="packageData.pkg_cycle === 'LIFETIME'"
                                    class="primary-btn primary-bg"
                                    :to="{ name: 'packages' }"
                                    >Upgrade Now</router-link
                                > -->
                                <p>Explore Other Packages</p>
                                <router-link
                                    class="primary-btn primary-bg"
                                    :to="{ name: 'packages' }"
                                    >View Packages</router-link
                                >
                            </div>
                            <div class="dropdown">
                                <a
                                    class="dropdown-toggle"
                                    href="#"
                                    id="navbarDropdown"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    <figure>
                                        <img
                                            :src="userData.profileImage"
                                            alt="user-img"
                                        />
                                    </figure>
                                    <p>
                                        {{ userData.name }}
                                        <span v-if="Object.keys(packageData).length === 0" class="loading"></span>
                                        <!-- <span v-else-if="packageData.is_trial && packageData.is_trial_exp">TRIAL PERIOD ENDED</span>
                                        <span v-else-if="packageData.is_trial && packageData.trial_exp_days >= 0">TRIAL EXPIRES ON {{ packageData.trial_end_at }}</span> -->
                                        <span v-else-if="packageData.pkg_cycle === 'LIFETIME'">EARLY BIRD ACCESS</span>
                                        <span v-else-if="packageData.pkg_cycle !== 'LIFETIME' && packageData.is_pkg_exp">SUBSCRIPTION EXPIRED</span>
                                        <span v-else-if="packageData.is_pkg_cancelled && packageData.is_pkg_exp">SUBSCRIPTION CANCELLED</span>
                                        <span v-else>PACKAGE EXPIRES ON {{ packageData.pkg_end_at }}</span>
                                    </p>
                                    <i class="fas fa-angle-down"></i>
                                </a>

                                <ul
                                    class="dropdown-menu"
                                    aria-labelledby="navbarDropdown"
                                >
                                    <li>
                                        <h4>
                                            {{ userData.name }}
                                            <span>{{ userData.email }}</span>
                                        </h4>
                                    </li>
                                    <li>
                                        <router-link :to="{name:'profile'}"><i class="fas fa-user"></i> Profile</router-link>
                                    </li>
                                    <li>
                                        <a href="#" @click.prevent="logout"
                                            ><i class="fas fa-sign-out-alt"></i>
                                            Logout</a
                                        >
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<style></style>
