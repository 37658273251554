<script setup>

import { ref, onMounted, computed, nextTick, watch} from 'vue';
import { useRoute, RouterLink, useRouter } from "vue-router";
import LoaderComponent from "@/components/LoaderComponent.vue";
import AlertComponent from "@/components/AlertComponent.vue";
import TableComponent from "@/components/tableComponents/TableComponent.vue"
import OverviewLinksDetailComponent from "@/components/projectDetailComponents/OverviewLinksDetailComponent.vue";
import NotificationModal from "@/components/modalComponents/NotificationModal.vue";
import ModalComponent from "../../components/modalComponents/ModalComponent.vue";
import SummaryBox from "@/components/updatedLinksComponents/SummaryBox.vue";
import SummaryDetailBox from "@/components/updatedLinksComponents/SummaryDetailBox.vue";
import { Modal } from 'bootstrap';
import { useStore } from 'vuex';
import { useToast } from "vue-toastification";
import moment from 'moment';
import html2pdf from "html2pdf.js";

// Tostification
const toast = useToast();

// Vue Routes
const route = useRoute();
const router = useRouter();

// Vuex Store
const store = useStore();

// Reactive Variables
let apiError = ref('');
let loading = ref(false);
let tableLoading = ref(false);
const search = ref('')
const searchProjects = ref([]);
const selectedProject = ref('');
const container = ref(null);
let isLinkDetailShowing = ref(false);
let linksDetailData = ref({});
const showLogs = ref(false);
const linkLogs = ref([]);
const data = ref(null);
const linkLogsColumns = ref([
    { label: "Crawl Date", field: "created_at", html: false, },
    { label: "Destination URL", field: "placement_url", html: true, },
    { label: "Live URL", field: "live_url_link", html: true, },
    { label: "HTTP Status", field: "live_url_http_status", html: true, },
    { label: "URL Match", field: "is_placement_anchor_url_matched", html: true, },
    { label: "Google Index", field: "is_google_index", html: true, },
    { label: "Moz DA", field: "da_score", html: true, },
    { label: "Spam Score", field: "spam_score", html: true, },
]);

// Methods
function getStatus(status){
    if(!status || status >= 400){
        return 'status-box-0';
    }else if(status >= 200 && status < 300){
        return 'status-box-1'
    }else if(status >= 300 && status){
        return 'status-box-2'
    }else{
        return 'status-box-0'
    }
}

// Summary Detail Boxes Functions
function SummaryDetailBoxEvent(data){
    if(data.type === 'view'){
        viewLinkDetail(data.id, data.concernType, data.attrType);
    }else if(data.type === 'logs'){
        getLinksLog(data.id);
    }   
}

// Link Detail Sidebar Functions
function linkDetailClose() {
    isLinkDetailShowing.value = false;
}
function viewLinkDetail(id, concernType, attrType) {
    let redId = data.value[concernType][attrType].findIndex(link => link.id === id);
    linksDetailData.value = data.value[concernType][attrType][redId];
    isLinkDetailShowing.value = true;
}

// Search Project
async function searchProject(){
    try{
        const res = await store.dispatch('Domain/getProjectsByBatchId', search.value.batch_id);
        const { data } = res;
        searchProjects.value = data;
    }catch(e){
        console.error(e);
    }
}
function updateSearchFilter(){
    router.push(`/updated-links/${search.value.verify_token}?pid=${selectedProject.value}`);
}

// Print PDF
function downloadPDF(){
    const opt = {
        margin: 0.25,
        filename: `linkwiki-project-update[${data.value && data.value.created_at ? data.value.created_at.split(' ').join('-').replace(',','').toLowerCase() : 'date'}].pdf`,
        html2canvas:  {scale: 2},
        jsPDF: {format: [500,500]},
        pagebreak: {avoid: ['avoid-all', 'css', 'legacy']},
        enableLinks : true
    }
    html2pdf().from(container.value).set(opt).save();
}

async function getLinksLog(id){
    if(!showLogs.value){
         try {
            showLogs.value = true;
            let res = await store.dispatch('Domain/getLinksDataLog', {id: id});
            linkLogs.value = res;
            new Modal(document.getElementById('link-logs')).show();
        } catch (e) {
            if(e.code !== "ERR_NETWORK"){
                toast.error('Something unexpected happened!');
            }
        } finally{
            showLogs.value = false;
        }
    }  
}
async function getLinks(id,load){
    try{
        loading.value = load;
        tableLoading.value = true;
        let res = await store.dispatch('Domain/getAllLinksAction', {
            verificationId: id,
            project_id: computedProjectId.value, 
        });
        if(res.success){
            
            data.value = res.data;

            const hash = window.location.hash;
            if(hash){
                await nextTick();

                window.scrollTo({
                    top: document.getElementById(hash.replace('#', '')).offsetTop,
                    left: 0,
                    behavior: "smooth",
                });
            }
        }
    }catch(e){
        const { response } = e;
        if(e.code !== "ERR_NETWORK"){
            if(!response.data.success){
                apiError.value = `<h4>Invalid Request</h4><p>${response.data.messages[0]}</p>`;
            }else{
                apiError.value = `<h4>Invalid Request</h4><p>Something unexpected happened</p>`
            }
            let errorModal = new Modal(document.getElementById('error-modal'));
            errorModal.show();    
        }
    }finally{
        setTimeout(()=>{
            loading.value = false;
            tableLoading.value = false;
        }, 1000);
    }
}

async function init(id){
    // Clear Search Params
    search.value = '';
    selectedProject.value = '';
    searchProjects.value = [];

    // Default Values
    data.value = null;

    await getLinks(id, true); 

    // Setting Filter Values
    search.value = computedSearchDate.value[computedSearchDate.value.findIndex(date => date.verify_token === computedId.value)];
    await searchProject();
}

// Computed Properties
const computedId = computed(()=>{
    return route.params.id;
});
const computedProjectId = computed(()=>{
    return route.query.pid;
});
const computedProjectName = computed(()=>{
    return data.value ? data.value.domain_link : '---';
});
const computedSummaryBoxes = computed(()=>{
    return [
        {
            value: data.value ? data.value.created_at : 'NA',
            title: 'Date',
            description: `This is the crawl report for the week of ${data.value ? data.value.created_at.split(' ')[0] + ' ' + data.value.created_at.split(' ')[2] : 'NA'}`,
        },
        {
            value: data.value ? data.value.total_links : '0',
            title: 'Total Links Analyzed',
            description: `A total of ${ data.value ? data.value.total_links : '0' } links were analyzed for your Project`,
        },
        {
            value: data.value ? data.value.health_score : '0',
            title: 'Domain Health Score',
            description: 'This score is calculated on our metrics of how the links are performing.',
        },
        {
            value: data.value ? data.value.avg_da : '0',
            title: 'Average Backlink DA',
            description: 'The mean DA from your acquired backlinks to determine overall quality.',
        }
    ]
});
const computedConcernsResolved = computed(()=>{
    return[
        {
            concernType: 'concern_resolved',
            attrType: 'links_found',
            title: 'Destination Links Found',
            description: 'Following destination URLs have been successfully found:',
            links: data.value ? data.value.concern_resolved.links_found : null,
        },
        {
            concernType: 'concern_resolved',
            attrType: 'da_score',
            title: 'DA Increased',
            description: 'Your website\'s Domain Authority (DA) has improved.',
            links: data.value ? data.value.concern_resolved.da_score : null,
        },
        {
            concernType: 'concern_resolved',
            attrType: 'spam_score',
            title: 'Spam Decreased',
            description: 'There\'s a successful spam reduction on your website.',
            links: data.value ? data.value.concern_resolved.spam_score : null,
        },
        {
            concernType: 'concern_resolved',
            attrType: 'broken',
            title: 'Broken Links Fixed',
            description: 'Following URLs have been fixed and are now functioning:',
            links: data.value ? data.value.concern_resolved.broken : null,
        },
        {
            concernType: 'concern_resolved',
            attrType: 'server_down',
            title: 'Server Down Links Recovered',
            description: 'Following URLs have been recovered and are now responsive:',
            links: data.value ? data.value.concern_resolved.server_down : null,
        },
        {
            concernType: 'concern_resolved',
            attrType: 'blocked',
            title: 'Blocked Links Restored',
            description: 'Following URLs have been restored and are now open for access:',
            links: data.value ? data.value.concern_resolved.blocked : null,
        }
    ]
});
const computedTopConcerns = computed(()=>{
    return[
        {
            concernType: 'top_concern',
            attrType: 'links_found',
            title: 'Destination Links Not Found',
            description: 'Following destination URLs cannot be found:',
            links: data.value ? data.value.top_concern.links_found : null,
        },
        {
            concernType: 'top_concern',
            attrType: 'da_score',
            title: 'DA Decreased',
            description: 'The DA of your website has experienced a decrease.',
            links: data.value ? data.value.top_concern.da_score : null,
        },
        {
            concernType: 'top_concern',
            attrType: 'spam_score',
            title: 'Spam Increased',
            description: 'Your website’s spam has increased.',
            links: data.value ? data.value.top_concern.spam_score : null,
        },
        {
            concernType: 'top_concern',
            attrType: 'broken',
            title: 'Broken Links',
            description: 'Following URLs are broken and display an error: ',
            links: data.value ? data.value.top_concern.broken : null,
        },
        {
            concernType: 'top_concern',
            attrType: 'server_down',
            title: 'Server Down Links',
            description: 'Following URLs are unresponsive due to server issues:',
            links: data.value ? data.value.top_concern.server_down : null,
        },
        {
            concernType: 'top_concern',
            attrType: 'blocked',
            title: 'Blocked Links',
            description: 'Following URLs are blocked and cannot be accessed:',
            links: data.value ? data.value.top_concern.blocked : null,
        }
    ]
});
const linkLogsRows = computed(()=>{
    return linkLogs.value.map(row => {
        return {    
            created_at: moment(row.created_at.split(' ')[0]).format('MM-DD-YYYY'),
            placement_url: (row.placement_url) ? `<a href="${row.placement_url}" target="_blank"><span>${row.placement_url}</span> <i style="padding-left: 6px;" class="fas fa-external-link-alt"></i></a>`: `<span>---</span>`,
            live_url_link: (row.live_url_link) ? `<a href="${row.live_url_link}" target="_blank"><span>${row.live_url_link}</span> <i style="padding-left: 6px;" class="fas fa-external-link-alt"></i></a>`: `<span>---</span>`,
            live_url_http_status: `<div class="status-box ${getStatus(row.live_url_http_status)}">
                                        Status
                                        <span>${row.live_url_http_status}</span>
                                    </div>`,
            is_placement_anchor_url_matched: row.is_placement_anchor_url_matched === 0 ? '<div style="color: #ff0e52;font-weight: 700">No</div>' : '<div style="color: #00A66C; font-weight: 700">Yes</div>',
            is_google_index: (row.is_google_index === 0) ? `<div class="google-index"><i class="fab fa-google" style="color: #ff0e52;font-weight: 700"></i></div>` : `<div class="google-index"><i class="fab fa-google" style="color: #00A66C;font-weight: 700"></i></div>`,
            da_score: row.da_score,
            spam_score: row.spam_score < 0 ? '1%' : row.spam_score + '%',
        }
    });
});
const computedSearchDate = computed(()=>{
    return data.value && data.value.all_email.map((date, index)=>{
        return {
            'created_at' : index === 0 ? 'This Week' : (index === 1 ? 'Previous Week' : moment(date.created_at).format('MM-DD-YYYY')),
            'batch_id': date.batch_id,
            'verify_token': date.verify_token,
        }
    });
});
const computedSearchAllProjects = computed(()=>{
    return searchProjects.value ? searchProjects.value : []; 
});

// Life Cycle Methods
onMounted(async () => {
    init(computedId.value);
});

// Watcher
watch(() => [route.params.id, route.query.pid], () => {
    if(route.name === 'updated-links'){
        init(route.params.id);
    }
});

</script>

<template>

    <section class="dashboard-agency-view updated-links-view">
        <LoaderComponent :isLoading="loading" />

        <form class="agency-form-2">
            <div class="row align-items-end">
                <div class="col-lg-8 col-md-8 col-12">
                    <div class="row" v-if="false">
                        <div class="col-lg-4 col-md-6 col-12" v-if="computedSearchDate">
                            <div class="form-group">
                                <label>Filter By Date</label>
                                <div class="d-relative">
                                    <select class="form-control" v-model="search" @change="searchProject">
                                        <option value="" v-if="computedSearchDate.length === 0" selected>Not Available</option>
                                        <option value="" selected disabled v-else>Select Date</option>
                                        <option :value="date" v-for="date in computedSearchDate" :key="date.batch_id">{{ date.created_at }}</option>
                                    </select>
                                    <i class="fas fa-angle-down"></i>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-12" v-if="computedSearchAllProjects.length > 0" >
                            <div class="form-group">
                                <label>Filter By Project</label>
                                <div class="d-relative">
                                    <select class="form-control" v-model="selectedProject" @change="updateSearchFilter">
                                        <option value="" disabled>Select Project</option>
                                        <option :value="project.project_id" v-for="project in computedSearchAllProjects" :key="project.id">{{ project.domain_link }}</option>
                                    </select>
                                    <i class="fas fa-angle-down"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-12">
                    <div class="table-filter">
                        <button class="primary-btn tertiary-transparent-btn export-btn" @click.prevent="downloadPDF">
                            <span>Download PDF <i class="fas fa-file-export"></i></span>
                        </button>
                    </div>
                </div>
            </div>
        </form>

        <div class="container-wrapper" ref="container" style="padding: 2rem;">
            <div class="primary-heading">
                <div class="row align-items-center justify-content-between">
                    <div class="col-lg-12 col-md-12 col-12">
                        <h2 style="font-size: 3rem;font-weight: 400;line-height: 4rem;">New Crawl Updates For <a :href="computedProjectName" target="_blank" style="color: #38cb89;">{{ computedProjectName }} <i class="fas fa-external-link-alt" v-if="computedProjectName !== '---'"></i></a></h2>
                    </div>
                </div>
            </div>

            <div class="dashboard-agency-info" style="margin-top: 4rem;">
                <div class="row">
                    <SummaryBox v-for="(box,index) in computedSummaryBoxes" :key="index" :data="box"/>
                </div>
            </div>

            <div class="dashboard-agency-table domain-link-table">
                
                <div v-if="data && data.hasOwnProperty('top_concern') && Object.keys(data.top_concern).length > 0">
                    <div class="primary-heading">
                        <h3 style="margin-top: 6rem;">Top Concerns</h3>
                    </div>

                    <SummaryDetailBox 
                        v-for="(concern, index) in computedTopConcerns" 
                        :key="index" 
                        :class="index > 0 && 'pdf-pb'"   
                        :data="concern" 
                        :tableLoading="tableLoading" 
                        type="Error"
                        :id="`${concern.concernType}_${concern.attrType}`" 
                        @event="SummaryDetailBoxEvent"
                    />  
                </div>

                <div v-if="data && data.hasOwnProperty('concern_resolved') && Object.keys(data.concern_resolved).length > 0">
                    <div class="primary-heading">
                        <h3 style="margin-top: 4rem;">Concerns Resolved</h3>
                    </div>

                    <SummaryDetailBox 
                        v-for="(concern, index) in computedConcernsResolved" 
                        :key="index" 
                        :class="index > 0 && 'pdf-pb'"
                        :data="concern" 
                        :tableLoading="tableLoading" 
                        type="Update"
                        :id="`${concern.concernType}_${concern.attrType}`"
                        @event="SummaryDetailBoxEvent"
                    />   
                </div> 
            </div>
        </div>

        <OverviewLinksDetailComponent :showOldData="true" :is-show="isLinkDetailShowing" :data="linksDetailData" @close-side-bar="linkDetailClose" />

        <!-- Notification(Validation Error) Modal Start -->
        <NotificationModal modalId="error-modal" type="error" :verticalCentered="true" dataBackDrop="static" :dataKeyboard="true" :showCloseBtn="false">
            <div v-if="apiError" v-html="apiError"></div>
            <div class="modal-btns">
                <router-link class="primary-btn primary-bg" :to="{name:'dashboard'}">Go To Dashboard</router-link>
            </div>
        </NotificationModal>

        <!-- Link Logs Modal -->
        <ModalComponent modalId="link-logs" title="Link Logs" :verticalCentered="true" dataBackDrop="static" :dataKeyboard="true" :showCloseBtn="true">
            <div class="scroller">
                <TableComponent
                    :rows="linkLogsRows"
                    :columns="linkLogsColumns"
                />
                <AlertComponent v-if="!linkLogsRows.length" type="warning" :show-close-btn="false">
                    No Logs Available
                </AlertComponent>
            </div>
        </ModalComponent>

    </section>

</template>
