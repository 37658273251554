import apiInstance from "./apiInstance";

export class PackagesServices{

    static getAllPackagesAPI(){
        return apiInstance.get('/api/packages');
    }
    static getCurrentPackageAPI(){
        return apiInstance.get('/api/package-info');
    }
    static customPackageQuoteAPI(payload){
        return apiInstance.post('/api/package-contact',payload);
    }

}