import { DomainServices } from '@/services/domainServices';
export default {
    namespaced: true,

    actions:{
        async addDomainAction({commit},payload){
            let res = await DomainServices.addDomainAPI({domain:payload.domain});
            let data = res.data;
            if(data.success && data.http_status_code !== 208){
                commit('Packages/updateProjectCountMutation', payload.projectCountLeft, { root: true });
                commit('Auth/updateRecentProjectIDMutation', data.project_id, { root: true })
            }
            return data;
        },
        
        async getAllDomainDataAction(){
            let res = await DomainServices.getAllDomainDataAPI();
            let data = res.data;
            return data;
        },

        async getDomainDataAction(_,payload){
            let res = await DomainServices.getDomainDataAPI(payload);
            let data = res.data;
            return data;
        },

        async getLinksDataAction(_,payload){
            let res = await DomainServices.getDomainLinksDataAPI(payload);
            let data = res.data;
            return data;
        },
        
        async trackLinksAction(_,payload){
            let res = await DomainServices.trackLinksAPI(payload.links);
            let data = res.data;
            return data;
        },

        async exportLinksDataAction(_,payload){
            let res = await DomainServices.exportLinksDataAPI(payload);
            let data = res.data;
            return data;
        },

        async getAllProjectsAction(_,payload){
            let res = await DomainServices.getAllProjectsAPI(payload);
            let data = res.data;
            return data;
        },

        async getAllLinksAction(_,payload){
            let res = await DomainServices.getAllLinksAPI(payload);
            let data = res.data;
            return data;
        },

        async exportUpdatedLinksDataAction(_,payload){
            let res = await DomainServices.exportUpdatedLinksDataAPI(payload);
            let data = res.data;
            return data;
        },

        async getSourcesAction(){
            let res = await DomainServices.getSourcesAPI();
            let data = res.data;
            return data;            
        },

        async addSourceAction(_,payload){
            let res = await DomainServices.addSourceAPI(payload);
            let data = res.data;
            return data;            
        },

        async removeSourceAction(_,payload){
            let res = await DomainServices.removeSourceAPI(payload);
            let data = res.data;
            return data;    
        },

        async updateAnchorTextURLAction(_,payload){
            let res = await DomainServices.updateAnchorTextURLAPI(payload);
            let data = res.data;
            return data;    
        },

        async getLinksDataLog(_,payload){
            let res = await DomainServices.getDomainLinksLog(payload);
            let data = res.data;
            return data;
        },

        async getProjectsByBatchId(_,payload){
            let res = await DomainServices.getProjectsByBatchIdAPI(payload);
            let data = res.data;
            return data;
        }
    }
}