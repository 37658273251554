import apiInstance from "./apiInstance";

export class AuthServices{

    static createSession(){
        return apiInstance.get('/sanctum/csrf-cookie');
    }

    static loginAPI(payload){
        return apiInstance.post('/api/login',payload);
    }

    static signupAPI(payload){
        return apiInstance.post('/api/register',payload);
    }

    static forgetPasswordAPI(payload){
        return apiInstance.post('/api/forget-password',payload);
    }

    static updatePasswordAPI(payload){
        return apiInstance.post('/api/update-password',payload);
    }

    static logoutAPI(){ 
        return apiInstance.post('/api/logout')
    }

    static isEmailVerified(){
        return apiInstance.get('/api/is-email-verified');
    }

    static sendVerficationEmail(payload){
        return apiInstance.post('/api/send-verification-email', payload);
    }

    static updateVerficationEmail(payload){
        return apiInstance.post(`/api/set-email-verification/${payload}`);
    }

    static googleAuthAPI(){
        return apiInstance.get('/api/auth/google');
    }

    static googleAuthCallbackAPI(){
        return apiInstance.get('/api/auth/google/callback');
    }

    static activityLog(payload){
        return apiInstance.post('/api/activity-log', payload);
    }

}