<template>
    <div class="Vue-Toastification__toast-body">
        Your subscription has expired. To keep using Link Wiki without interruption, please pick a package.
        <a href="#" @click.prevent="nextPage">Click Here</a>
    </div>
</template>

<script setup>
import { defineEmits } from 'vue';

// Vue Emits
const emit = defineEmits(['toPackagePage'])

function nextPage(){
    emit('toPackagePage');
}

</script>
