import { Tooltip } from 'bootstrap'

const bsTooltip = (el, binding) => {
  const t = []

  if (binding.modifiers.focus) t.push('focus')
  if (binding.modifiers.hover) t.push('hover')
  if (binding.modifiers.click) t.push('click')
  if (!t.length) t.push('hover')

  if(binding.value){
    return new Tooltip(el, {
      title: binding.value,
      placement: binding.arg || 'top',
      trigger: t.join(' '),
      html: !!binding.modifiers.html,
    });
  }
}

export const tooltip = {
    
  // mounted(el) {
  //   return new Tooltip(el);
  // }
  
  bind(el, binding){
    bsTooltip(el, binding);
  },
  mounted(el, binding){
    bsTooltip(el, binding);
  },
  unbind(el){
    new Tooltip(el).dispose();
  }
}
