<script setup>
import { ref } from "vue";
import { RouterLink } from 'vue-router';
import { useStore } from "vuex";
import { useToast } from "vue-toastification";

// Vuex Store
const store = useStore();

// Toastification 
const toast = useToast();

// Normal Variables
const toastTimeOut = 3000;
const toastConfig = {
    transition: "Vue-Toastification__fade",
    position: "top-center",
    timeout: toastTimeOut,
    closeOnClick: false,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: false,
    showCloseButtonOnHover: false,
    hideProgressBar: false,
    closeButton: "button",
    icon: true,
    rtl: false
}

// Reative Variables
const authData = ref({
    states: {
        email: "",
    },
    errors: {
        email: "",
    }
});


// Methods
function toggleLoadingBtn(btn){
    if(!btn.classList.contains('loading')){
        btn.classList.add('loading');
        btn.disabled = true;
    }else{
        btn.classList.remove('loading');
        btn.disabled = false;
    }
}
function emailValidation(email) {
    return String(email)
        .toLowerCase()
        .match(/^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i);
}
function validateForm(){
    let errCount = 0;
    const states = authData.value.states; 
    const errors = authData.value.errors;

    if(!states.email){
        errors.email = 'Field is required!';
    }else if(!emailValidation(states.email)){
        errors.email = 'Email is invalid!';
    }else{
        errors.email = '';
    }

    Object.keys(errors).forEach(error => {
        if(errors[error]){
            errCount++;
        }
    });

    return errCount;
}
async function submitForm(e){
    const submitBtn = e.target.querySelector('.submit-btn');

    if(validateForm() === 0){
        try{
            toggleLoadingBtn(submitBtn);
            await store.dispatch('Auth/forgetPasswordAction',authData.value.states,{root:true});
            toast.success('Reset URL is sent to your provided email',toastConfig);
        }catch(e){
            e.response.data.data.error.forEach((error) => {
                toast.error(error,toastConfig);
            });
        }finally{
            toggleLoadingBtn(submitBtn);
        }
    }
}

</script>

<template>
    <section class="dashboard-agency-auth dashboard-forget-password">
        <div class="dashboard-agency-auth-form">
            <form @submit.prevent="submitForm" class="agency-form">
               <figure class="mobile-comp"><img src="@/assets/images/logo.png" alt="logo"></figure>
                <h1>Reset <br> Password!</h1>
                <div class="form-group">
                    <label>Email</label>
                    <div class="d-relative">
                        <input
                            v-model.trim="authData.states.email"
                            type="text"
                            class="form-control"
                            placeholder="johndoe@gmail.com"
                        />
                        <i class="fas fa-envelope"></i>
                        <span class="error-message" v-if="authData.errors.email"
                            ><i class="fas fa-exclamation-circle"></i>
                            {{ authData.errors.email }}</span
                        >
                    </div>
                </div>
                <button class="primary-btn primary-bg lg-btn submit-btn" type="submit">
                    <span>Reset Password</span>
                    <i class="fas fa-spinner fa-spin loading"></i>
                </button>
                <p class="mobile-comp">
                    Already have code?
                    <router-link :to="{name: 'updatepassword'}" class="p-anchor">Reset Password</router-link>
                </p>
            </form>
        </div>
        <div
            class="dashboard-agency-auth-content"
            :style="{backgroundImage: 'url(' + require('../../assets/images/auth-bg.jpg') + ')'}"
        >
            <div class="wrapper">
                <h2>
                    Welcome to <br />
                    Link Wiki
                </h2>
                <p>
                    Take your link building to the next level <br />
                    with a tool that does all the work of <br />
                    monitoring your backlinks.
                </p>
                <div class="have-account">
                    <p>Don't have an account? Create account now!</p>
                    <router-link :to="{name: 'signup'}" class="p-anchor">Sign Up</router-link>
                </div>
            </div>
        </div>
    </section>
</template>

<style></style>
