
import store from '@/store';
import Axios from 'axios';
import url from '@/prod.js';
import app from '@/main';
import { useToast } from 'vue-toastification';

const toast = useToast();
const time = 1000;
let isSessionExpired = false;
let isInternetDisconnect = false;
const apiInstance = Axios.create({
    baseURL: url.baseUrl,
});

apiInstance.interceptors.request.use(config => {
    app.config.globalProperties.$Progress.start();
    let token = store.getters['Auth/authToken'];
    if(token){
        config.headers.Authorization = `Bearer ${token}`;
        config.headers['Access-Control-Allow-Origin'] = url.baseUrl;
        config.headers['Access-Control-Allow-Credentials'] = true;
    }
    return config;
});

apiInstance.interceptors.response.use(
    response => {
        if(isInternetDisconnect){
            isInternetDisconnect = false;
            toast.dismiss("internet-toast");
        }
        app.config.globalProperties.$Progress.finish();
        return response;
    },
    error => {
        app.config.globalProperties.$Progress.finish();
        if(error.response.status === 401 && !isSessionExpired){
            isSessionExpired = true;

            toast.error('Session Expired');
            setTimeout(()=>{
                window.localStorage.clear();
                window.location.reload();
            },time)
        }
        // if(error.code === 'ERR_NETWORK' && !isInternetDisconnect){
        //     isInternetDisconnect = true;
        //     toast.error('No Internet Connection',{ 
        //         id: "internet-toast",
        //         timeout: false,
        //         closeOnClick: true,
        //         closeButton: "button",
        //         draggable: true,
        //     });
        // }
        // if(error.code !== 'ERR_NETWORK' && isInternetDisconnect){
        //     isInternetDisconnect = false;
        //     toast.dismiss("internet-toast");
        // }

        return Promise.reject(error);
    }
);

export default apiInstance;