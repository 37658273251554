import apiInstance from "./apiInstance";

export class PaymentServices{

    static getCountriesAPI(){
        return apiInstance.get('/api/countries');
    }
    static getStatesAPI(id){
        return apiInstance.get(`/api/states/${id}`);
    }
    static getOrderSummaryAPI(id){
        return apiInstance.get(`api/package/${id}`);
    }
    static getBillingDetailsAPI(){
        return apiInstance.get('api/user-billing-address');
    }
    static getPaymentCardsAPI(){
        return apiInstance.get('api/user-payment-cards');
    }
    static savePaymentCardsAPI(payload){
        return apiInstance.post('api/save-user-payment-card',payload);
    }
    static setDefaultPaymentCardAPI(payload){
        return apiInstance.post('api/set-default-user-payment-card',payload);
    }
    static removeDefaultPaymentCardAPI(payload){
        return apiInstance.post('api/remove-default-user-payment-card',payload);
    }
    static deletePaymentCardAPI(id){
        return apiInstance.delete(`api/delete-user-payment-card?id=${id}`);
    }
    static paymentAPI(payload){
        return apiInstance.post('api/checkout-order', payload);
    }
    static getAllInvoicesAPI(payload){
        return apiInstance.get('api/user-order-invoices',{ params: payload });
    }
    static getInvoicesDetailAPI(payload){
        return apiInstance.get(`api/user-order-invoice?payment_number=${payload}`);
    }
    static cancelSubscriptionAPI(){
        return apiInstance.post('api/cancel-order');
    }
}

