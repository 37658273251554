<script setup>
import { defineProps, defineEmits, computed, ref, onMounted, onUnmounted } from 'vue';

// Vue Props
const props = defineProps({
    modelValue: Number,
    data: Object
});

// Vue Emits
const emit = defineEmits(['update:modelValue', 'updateRoute']);

// Reactive Variables
const selectBox = ref(null);
const selectDropdown = ref(null)
const selectSearchBox = ref(null);
const selectOptionsBox = ref(null);
const searchVal = ref('');


// Methods
function onSelectChange(id){
    emit('update:modelValue', Number(id)); 
    emit('updateRoute');
}
function showHideOptions(){
    if(!selectBox.value.classList.contains('show')){
        selectBox.value.classList.add('show');
        setTimeout(()=>{
            selectSearchBox.value.querySelector('input').focus();
        }, 500);
    }else{
        selectBox.value.classList.remove('show');
    }
}
function close(e) {
    if (
        selectBox.value &&
        selectBox.value.classList.contains("show") &&
        !selectBox.value.contains(e.target)
    ) {
       selectBox.value.classList.remove('show');
    }
}

// Computed Properties
const computedVal = computed(() => {
    return props.data.filter(project => project.id === props.modelValue)[0];
});
const computedOptions = computed(()=>{
    if(searchVal.value){
        const search = props.data.filter(project => project.domain.includes(searchVal.value));
        return search;
    }

    return props.data;
});

// Life Cycle Function

onMounted(() => {
    window.addEventListener("click", close);
});
onUnmounted(() => {
    window.removeEventListener("click", close);
});
</script>

<template>
    <div class="custom-select-box d-relative" ref="selectBox">
        <select :value="modelValue" style="display: none">
            <option v-for="project in data" :key="project.id" :value="project.id">{{ project.domain }}</option>
        </select>
        
        <div class="custom-select-active" @click.prevent="showHideOptions">
            <span class="custom-select-value">{{ computedVal.domain }}</span>
            <i class="fa fa-angle-down"></i>
        </div>
        <div class="custom-select-dropdown" ref="selectDropdown">
            <div class="custom-select-dropdown--search" ref="selectSearchBox">
                <i class="fas fa-search"></i>
                <input type="text" placeholder="Search" v-model="searchVal" @change="searchOptions">
            </div>
            <ul class="custom-select-dropdown--options" v-if="computedOptions.length" ref="selectOptionsBox">
                <li v-for="project in computedOptions" :key="project.id" :class="{'active' : project.id === modelValue}" @click.prevent="onSelectChange(project.id)">{{ project.domain }}</li>
            </ul>
            <ul class="custom-select-dropdown--options" v-else ref="selectOptionsBox">
                <li>No result found</li>
            </ul>
        </div>
    </div>
</template>

<style>
</style>