<script setup>
    import { computed, defineProps, defineEmits, ref } from 'vue';
    import AlertComponent from "@/components/AlertComponent.vue";
    import TableComponent from "@/components/tableComponents/TableComponent.vue"

    // Vue Props
    const props = defineProps({
        data: Object,
        type: String,
        tableLoading: Boolean,
        id: String
    });

    // Vue Emits
    const emit = defineEmits(['event']);


    // Vue State Variables
    const columns = ref([
        { label: "Destination URL", field: "placement_url", html: true, tooltip: 'The page that the backlink leads to.'},
        { label: "Live URL", field: "live_url_link", html: true, tooltip: 'The URL that contains the Backlink.'},
        { label: "Status", field: "live_url_http_status", html: true, sortable: false , tooltip: 'Indicates the current status of the monitored link. 200 represents an active backlink. '},
        { label: "Anchor Text", field: "placement_anchor_text", html: true, tooltip: 'The clickable text that leads to the destination URL.'},    
        { label: "URL Match", field: "is_placement_anchor_url_matched", html: true, tooltip: 'Check if the destination URL matches the backlink placed on the Live URL.'},
        { label: "Google Index", field: "live_url_ga_index", html: true, tooltip: 'Check if Google has indexed the Live URL page with the Domain.'},
        { label: "Moz DA", field: "live_url_da_score", html: true,class: 'moz-spam-td',style: 'min-width: 100px;', tooltip: 'Domain Authority, a score from 0 to 100 rating the overall presence of the Live URL Website. The stats are taken from Moz.'},
        { label: "Spam Score", field: "live_url_spam_score", html: true,class: 'moz-spam-td',style: 'min-width: 100px;', tooltip: 'The probability of the Live URL being spammy.'},
        { label: "Action", field: "action", html: false, pdf: true },
    ]);


    // Vue Methods
    function tableEvent(data) {
        emit('event', {
            id: data.id,
            type: data.type,
            concernType: data.concernType,
            attrType: data.attrType,
        });
    }
    function getStatus(status){
        if(!status || status >= 400){
            return 'status-box-0';
        }else if(status >= 200 && status < 300){
            return 'status-box-1'
        }else if(status >= 300 && status){
            return 'status-box-2'
        }else{
            return 'status-box-0'
        }
    }

    // Computed Properties
    const getComputedRows = computed(()=>{
        if(props.data.links && props.data.links.length > 0){
            return props.data.links.map(row => {
                return {
                    placement_url: `<a href="${row.placement_url}" target="_blank"
                                    ><span class="link" style="width: auto;white-space: unset;">${row.placement_url ? row.placement_url : '--' }</span>
                                    <i class="fas fa-external-link-alt"></i>
                                </a>`,
                    live_url_link: `<a href="${row.live_url_link}" target="_blank"
                                    ><span class="link" style="width: auto;white-space: unset;">${row.live_url_link ? row.live_url_link : '--' }</span>
                                    <i class="fas fa-external-link-alt"></i>
                                </a>`,              
                    live_url_http_status: `<div class="status-box ${getStatus(row.live_url_http_status)}" data-bs-toggle="tooltip" data-bs-placement="top" title="${row.live_url_http_status_reason}">
                                            Status
                                            <span>${row.live_url_http_status}</span>
                                        </div>`,
                    placement_anchor_text: `<span class="anchor_text" style="width: auto;white-space: unset;">${row.placement_anchor_text ? row.placement_anchor_text : '---'}</span>`,       
                    is_placement_anchor_url_matched: `<span style="font-weight:700;color:${row.is_placement_anchor_url_matched ? '#00cbaf' : '#ff0e52'}">${row.is_placement_anchor_url_matched ? 'Yes' : 'No'}</span>`,             
                    is_google_index: `<i class="fab fa-google" style="color:${row.is_google_index ? '#00A66C' : '#F03800'}"></i>`,
                    live_url_da_score: `${row.live_url_da_score} <span style="color: ${(row.live_url_da_score - row.old_da) < 0 ? '#ff0e52' : '#38cb89'}"><i class="fas ${(row.live_url_da_score - row.old_da) < 0 ? 'fa-caret-down' : 'fa-caret-up'}"></i>  ${Math.abs(row.live_url_da_score - row.old_da)}</span>`,
                    live_url_spam_score: `${row.live_url_spam_score < 0 ? '1%' : row.live_url_spam_score + '%'} <span style="color: ${(row.live_url_spam_score - row.old_spam) < 0 ? '#38cb89' : '#ff0e52'}"><i class="fas ${(row.live_url_spam_score - row.old_spam) < 0 ? 'fa-caret-down' : 'fa-caret-up'}"></i>  ${Math.abs(row.live_url_spam_score - row.old_spam)}%</span>`,
                    action: [
                        {
                            id: row.id,
                            concernType: props.data.concernType,
                            attrType: props.data.attrType,
                            label: "<i class='fas fa-eye'></i>",
                            type: "view",
                            route: "#",
                            target: "",
                            disable: false,
                            tooltip: 'View'
                        },
                        {
                            id: row.id,
                            concernType: props.data.concernType,
                            attrType: props.data.attrType, 
                            label: "<i class='fas fa-history'></i>",
                            type: "logs",
                            route: "#",
                            target: "",
                            disable: false,
                            tooltip: 'Logs'
                        },
                    ]
                }
            })
        } 

        return null;
    });  

</script>

<template>
    <div class="table-wrapper" v-if="getComputedRows && getComputedRows.length > 0" :id="id" style="margin-top: 3rem;border-bottom: 2px solid #edf0f7;border-radius: 0;">
        <div class="table-heading">
            <h4 style="display: flex;align-items: center;justify-content: flex-start;font-size: 2.2rem;font-weight: 600;line-height: 3.2rem;color: #0a0e31;margin-bottom: 1rem;">{{ data.title }}  <span style="font-size: 1.3rem;font-weight: 600;color: #fff;line-height: 1.8rem;padding: 0.4rem 2rem .2rem;border-radius: 0.5rem;margin-left: 2rem;position: relative;top: -2px;" :style="{background: type==='Error' ? '#ff0e52' : '#38cb89'}">{{ type }}</span></h4>
            <p style="font-size: 1.6rem;font-weight: 400;color: #7b7b7b;line-height: 2.6rem;">{{ data.description }}</p>
        </div>

        <TableComponent
            :rows="getComputedRows"
            :columns="columns"
            :tableLoading="tableLoading"
            @tableEvent="tableEvent"
        />
        <AlertComponent v-if="getComputedRows && getComputedRows.length === 0" type="warning" :show-close-btn="false">Links not found.</AlertComponent>
    </div>
</template>
