<script setup>
    import { defineProps, ref } from 'vue';

    // Vue Props
    defineProps({
        show: Boolean,
        message: String,
    });

    // Reactive Variables
    let showAlert = ref(true);

    // Methods
    function hideAlert(){
        showAlert.value = false;
    }

</script>

<template>
    <div class="crawled-progress" v-if="show" :style="{ 'display': showAlert ? 'block' : 'none' }">
        <p v-html="message"></p>
        <div class="progress">
            <div class="progress-bar progress-bar-striped progress-bar-animated bg-success" role="progressbar" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100" style="width: 100%"></div>
        </div>
        <button class="primary-btn primary-bg lg-btn" @click.prevent="hideAlert()">Continue this operation in the background</button>
    </div>
</template>

<style>

</style>