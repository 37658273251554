<script setup>
import { defineProps, computed } from 'vue';
import ProjectThumbnail from "@/components/projectThumbnailComponents/ProjectThumbnail.vue";
import ProjectThumbnailList from "@/components/projectThumbnailComponents/ProjectThumbnailList.vue";
import ProjectThumbnailGraph from "@/components/projectThumbnailComponents/ProjectThumbnailGraph.vue";
// import ProjectThumbnailBoxVal from "@/components/projectThumbnailComponents/ProjectThumbnailBoxVal.vue";

// Vue Props
const props = defineProps({
    id: Number,
    data: Object,
    detailView: Boolean,
    remainingLinks: Number,
});

// Computed Properties
const getProjectName = computed(()=>{
    return props.data.domain_data.domain_link;
});
const getMozObj = computed(()=>{
    return props.data.moz_array;
});
const getComputedHealthData = computed(()=>{
    return {
        'Crawled': {
            val: props.data.health_score.crawled ? props.data.health_score.crawled : 0,
            diff: props.data.health_score.crawled ? (props.data.health_score.crawled - props.data.calc_logs.crawled_links_count) : 0,
            tooltip: 'The total number of links that have been added.',
        },
        'Redirects': {
            val: props.data.health_score.redirects ? props.data.health_score.redirects : 0,
            diff: props.data.health_score.redirects ? (props.data.health_score.redirects - props.data.calc_logs.redirects_links_count) : 0,
            tooltip: 'Live URL Links that are redirecting to another page',
        },
        'Broken': {
            val: props.data.health_score.broken ? props.data.health_score.broken : 0,
            diff: props.data.health_score.broken ? (props.data.health_score.broken - props.data.calc_logs.broken_links_count) : 0,
            tooltip: 'URL Links that are broken',
        },
        'Blocked': {
            val: props.data.health_score.blocked ? props.data.health_score.blocked : 0,
            diff: props.data.health_score.blocked ? (props.data.health_score.blocked - props.data.calc_logs.blocked_links_count) : 0,
            tooltip: 'URL Links that are restricted',
        },
        'Server Down': {
            val: props.data.health_score.server_down ? props.data.health_score.server_down : 0,
            diff: props.data.health_score.server_down ? (props.data.health_score.server_down - props.data.calc_logs.server_down_links_count) : 0,
            tooltip: 'The server of the URL isn\'t working.',
        },
        'Health Score':{
            val: props.data.health_score.health_score ? props.data.health_score.health_score.toFixed(1) : 0,
            tooltip: 'A ratio of the live URLs versus links that are redirecting, broken, blocked or not working because the server is down.',
        },  
    }
});
const getComputedDAData = computed(()=>{
    return {
        'Below DA 30': {
            val: props.data.links_with_da['da_0+'] ? props.data.links_with_da['da_0+'] : 0,
            diff: props.data.links_with_da['da_0+'] ? (props.data.links_with_da['da_0+'] - props.data.calc_logs.below_da_30_links_count) : 0        
        },
        'DA 30 +': {
            val: props.data.links_with_da['da_30+'] ? props.data.links_with_da['da_30+'] : 0,
            diff: props.data.links_with_da['da_30+'] ? (props.data.links_with_da['da_30+'] - props.data.calc_logs.da_30_links_count) : 0    
        },
        'DA 40 +': {
            val: props.data.links_with_da['da_40+'] ? props.data.links_with_da['da_40+'] : 0,
            diff: props.data.links_with_da['da_40+'] ? (props.data.links_with_da['da_40+'] - props.data.calc_logs.da_40_links_count) : 0
        },
        'DA 50 +': {
            val: props.data.links_with_da['da_50+'] ? props.data.links_with_da['da_50+'] : 0,
            diff: props.data.links_with_da['da_50+'] ? (props.data.links_with_da['da_50+'] - props.data.calc_logs.da_50_links_count) : 0
        }, 
        'DA 60 +': {
            val: props.data.links_with_da['da_60+'] ? props.data.links_with_da['da_60+'] : 0,
            diff: props.data.links_with_da['da_60+'] ? (props.data.links_with_da['da_60+'] - props.data.calc_logs.da_60_links_count) : 0
        }, 

    }    
})
const getComputedAnchorData = computed(()=>{
    const obj = {};
    let newAnchorTextArr = [];
    if(props.data.anchor_text_calc.length > 5){
        newAnchorTextArr = props.data.anchor_text_calc.slice(0, 5);
    }else{
        newAnchorTextArr = props.data.anchor_text_calc;
    }
    
    newAnchorTextArr.forEach(anchor => {
        obj[anchor.placement_anchor_text] = {
            val: Number(anchor.counts),
            per: Number(anchor.percentage).toFixed(2),
        }
    });
    return obj
});

</script>

<template>
    <ProjectThumbnail>
        <template #project-thumbnail-top-name>
            <div class="project-thumbnail-select">
                <p>{{ getProjectName ? getProjectName : 'Domain Name' }}</p>
                <!-- <div class="last-crawled-at">Last Crawled: <span>{{ getLastCrawledDate }}</span></div> -->
            </div>
        </template>
        <template #project-thumbnail-top-slot>
            <div class="remaining-links" v-if="remainingLinks">
                <p>Remaining Links: <span>{{ remainingLinks }}</span></p>
            </div>
            <div class="project-thumbnail-top-btns" v-else>
                <router-link class="primary-btn primary-transparent-bg remainingLinks" :to="`/projects/${id}`" v-if="detailView">View Links</router-link>
                <router-link class="primary-btn primary-bg" :to="`/add-links/${id}`">Add Links</router-link>
            </div>
        </template>
        <template #project-thumbnail-bottom-1>
            <ProjectThumbnailList 
                :project-id="id"
                :title="{
                    name: 'Links\' Stats',
                    tooltip: 'A concise summary of how your added links are functioning. '
                }"
                :data="getComputedHealthData" 
            />
        </template>
        <template #project-thumbnail-bottom-2>
            <!-- <ProjectThumbnailBoxVal 
                title="Links' Health"
                :project-id="id"
                :link-count="Number(data.health_score.crawled)"
                tooltip="A ratio of the live URLs versus links that are redirecting, broken, blocked or not working because the server is down."
                :value="data.health_score.health_score"
            /> -->
            <ProjectThumbnailList 
                :title="{
                    name: 'Anchor Spread',
                    tooltip: 'Provides count and percentage of Anchor texts of your backlinks.'
                }"
                :project-id="id"
                :data="getComputedAnchorData"
                :count="data.anchor_text_calc.length"
            />
        </template>
        <template #project-thumbnail-bottom-3>
            <ProjectThumbnailGraph 
                title="Current DA"
                tooltip="The current Domain Authority(DA) or presence of the project. Higher DA indicates successful SEO. "
                graphTitle="Avg DA"
                name="da_score"
                :data="getMozObj"
                chartColor="#FF6824"
            />
        </template>
        <template #project-thumbnail-bottom-4>
            <ProjectThumbnailGraph 
                title="Links Tracked"
                tooltip="The number of links that are being tracked under the project."
                graphTitle="Link Tracked"
                name="crawled_count"
                :data="getMozObj"
                chartColor="#5338FC"
            />
        </template>
        <template #project-thumbnail-bottom-5>
                <ProjectThumbnailGraph 
                title="Spam Score"
                tooltip="The percentage of Links under the project that can be considered spammy."    
                graphTitle="Avg SS"
                name="spam_score"
                :data="getMozObj"
                chartColor="#FF0E52"
            />
        </template>
        <template #project-thumbnail-bottom-6>
            <ProjectThumbnailList 
                :title="{
                    name: 'Links with DA',
                    tooltip: 'A summary of Live URL links and their Domain Authority'
                }"
                :data="getComputedDAData" 
            />
        </template>
    </ProjectThumbnail>
</template>

<style>

</style>