<script setup>
import { computed } from '@vue/runtime-core';
import { RouterLink } from 'vue-router';
import { useStore } from 'vuex';

// Vuex Store
const store = useStore();

// Computed Properties
const isAuthorized = computed(()=>{
   return store.getters['Auth/isAuthenticated']; 
})

</script>

<template>
  <section class="dashboard-agency-404">
    <div class="container">
        <div class="wrapper">
            <figure><img src="../assets/images/404.png" alt="404-img"></figure>
            <h2>Sorry! <span>page not found</span></h2>
            <p>You have been tricked into click on a link that cannot be Found. <br> Please check the url or go to the page given below and see if you can locate what you are looking for.</p>
            <div class="btns">
                <router-link :to="{name:'dashboard'}" class="primary-btn primary-bg" v-if="isAuthorized">Go To Dashboard</router-link>
                <router-link :to="{name:'login'}" class="primary-btn primary-bg" v-if="!isAuthorized">Login Now</router-link>
            </div>
        </div>
    </div>
  </section>
</template>

<style>

</style>