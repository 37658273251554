import { PaymentServices } from '@/services/paymentServices';
export default {
  namespaced: true,
  actions: {
    // Get Order Summary
    async getOrderSummaryAction(_,payload){
        let res = await PaymentServices.getOrderSummaryAPI(payload);
        let data = res.data;
        return data; 
    },
    //  Get Countries Action
    async getCountriesAction(){
        let res = await PaymentServices.getCountriesAPI();
        let data = res.data;
        return data; 
    },

    // Get States Action
    async getStatesAction(_,payload){
        let res = await PaymentServices.getStatesAPI(payload);
        let data = res.data;
        return data; 
    },

    // Get Billing Details
    async getBillingDetailsAction(){
        let res = await PaymentServices.getBillingDetailsAPI();
        let data = res.data;
        return data;
    },

    // Get Payment Cards
    async getPaymentCardsAction(){
        let res = await PaymentServices.getPaymentCardsAPI();
        let data = res.data;
        return data;
    },

    // Save Payment Card
    async savePaymentCardsAction(_,payload){
        let res = await PaymentServices.savePaymentCardsAPI(payload);
        let data = res.data;
        return data; 
    },

    // Set Default Payment Card
    async setDefaultPaymentCardAction(_,payload){
        let res = await PaymentServices.setDefaultPaymentCardAPI(payload);
        let data = res.data;
        return data; 
    },

    // Remove Default Payment Card
    async removeDefaultPaymentCardAction(_,payload){
        let res = await PaymentServices.removeDefaultPaymentCardAPI(payload);
        let data = res.data;
        return data; 
    },

    // Delete Payment Card
    async deletePaymentCardAction(_,payload){
        let res = await PaymentServices.deletePaymentCardAPI(payload);
        let data = res.data;
        return data; 
    },

    // Payment Action
    async paymentAction(_,payload){
        let res = await PaymentServices.paymentAPI(payload);
        let data = res.data;
        return data; 
    },

    // Get All Invoices Action
    async getAllInvoicesAction(_,payload){
        let res = await PaymentServices.getAllInvoicesAPI(payload);
        let data = res.data;
        return data; 
    },

    // Get Invoice Detail Action
    async getInvoiceDetailAction(_,payload){
        let res = await PaymentServices.getInvoicesDetailAPI(payload);
        let data = res.data;
        return data; 
    },

    // Cancel Subscription Action
    async cancelSubscriptionAction(){
        let res = await PaymentServices.cancelSubscriptionAPI();
        let data = res.data;
        return data; 
    },

  },
}