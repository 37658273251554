<script setup>
    import { defineProps } from 'vue';

    // Vue Props
    defineProps({
        data: Object,
    });

    // Vue Methods
    const getPercentageColor = function(val){
        if(val >= 0 && val < 30){
            return '#ff0000';
        }else if(val >= 30 && val < 60){
            return '#FFA500';
        }else if(val >= 60 && val < 80){
            return '#ffc200';
        }else if(val >= 80 && val < 90){
            return '#38cb89';
        }else if(val >= 90 && val <= 100){
            return '#00cbaf';
        }else{
            return '#ff0000';
        }
    }

</script>

<template>
    <div class="col-lg-3 col-md-6 col-12" style="flex: 0 0 auto;width: 25%;">
        <div class="box" style="padding: 3rem 2rem;border: 1px solid #edf0f7;height: 100%;border-radius: 1rem;">
            <h3 style="font-size: 3rem;font-weight: 600;line-height: 4rem;color: #024059;margin-bottom: 0;" :style="{color: data.title === 'Domain Health Score' ? getPercentageColor(data.value) : ''}">{{ data.title === 'Domain Health Score' ? data.value + '%' : data.value }}</h3>
            <h4 style="font-size: 1.8rem;font-weight: 600;line-height: 2.8rem;color: #0a0e31;margin-bottom: 1rem;">{{ data.title }}</h4>
            <p style="font-size: 1.5rem;font-weight: 400;line-height: 2.5rem;color: #7b7b7b;margin: 0;">{{ data.description }}</p>
        </div>
    </div>
</template>
