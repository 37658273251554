<script setup>
import { defineProps, defineEmits, onUpdated } from "vue";
import TableLoadingComponent from '@/components/loader-components/TableLoadingComponent.vue';
import { Tooltip } from 'bootstrap';

// Vue Props
const props = defineProps({
    rows: Array,
    columns: Array,
    tableLoading: Boolean,
    limit: {
        type: Number,
        default: 10,
    },
    sort: String,
    order: String,
});

// Vue Emits
const emits = defineEmits(["tableEvent", "columnSortEvent"]);

// Methods
function tableActionEvent(data) {
    emits("tableEvent", data);
}
function applyColumnSorting(event, name){
    const searchObj = {
        order: '',
        sort: '',
    };

    [...document.querySelectorAll('.sort')].forEach(item => {
        item.querySelector('.fa-caret-up').classList.remove('active');
        item.querySelector('.fa-caret-down').classList.remove('active');
    });

    if(props.sort === name){
        searchObj.order = props.order === 'desc' ? 'asc' : 'desc';
    }else{
        searchObj.order = 'asc';
    }

    searchObj.sort = name;
    emits("columnSortEvent", searchObj);

    const fa1 = event.currentTarget.querySelector('.fa-caret-up');
    const fa2 = event.currentTarget.querySelector('.fa-caret-down');
    if(searchObj.order === 'asc'){
        fa1.classList.add('active');
        fa2.classList.remove('active');
    }else{
        fa1.classList.remove('active');
        fa2.classList.add('active');
    }
}
function getToolTip(){
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('.table-responsive [data-bs-toggle="tooltip"]'))
    tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new Tooltip(tooltipTriggerEl,{
            container: 'body',
            trigger : 'hover'
        })
    });
}

// LifeCycle Methods
onUpdated(()=>{
    getToolTip();
});

</script>

<template>
    <div class="table-responsive">
        <table class="table table-hover">
            <thead>
                <tr>
                    <th v-for="(column, index) in columns" :key="index">
                        <div class="d-flex align-items-center" :data-html2canvas-ignore="column.pdf">
                            <span v-if="column.tooltip" v-tooltip="column.tooltip">{{ column.label }}</span>
                            <span v-else>{{ column.label }}</span>
                           
                            <span v-if="column.sortable" class="sort" @click="(event)=>applyColumnSorting(event,column.field)">
                                <i class="fas fa-caret-up"></i>
                                <i class="fas fa-caret-down"></i>
                            </span>
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody v-if="!tableLoading">
                <tr v-for="(row, index) in rows" :key="index">
                    <td v-for="(column, colKey, id) in row" :key="colKey">

                        <div v-if="colKey !== 'action' && !columns[id].html" :class="columns[id].class" :style="columns[id].style">
                            {{ column }}
                        </div>
                        <div
                            v-else-if="colKey !== 'action' && columns[id].html" :class="columns[id].class" :style="columns[id].style"
                            v-html="column"
                        ></div>

                        <div v-if="colKey === 'action'" :data-html2canvas-ignore="columns[id].pdf">
                            <div class="btns">
                                <a
                                    v-for="(action, actIndex) in column"
                                    :key="actIndex"
                                    :href="action.route"
                                    :target="action.target"
                                    :disable="action.disable"
                                    @click.prevent="tableActionEvent(action)"
                                    v-html="action.label"
                                    v-tooltip="action.tooltip"
                                ></a>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
            <TableLoadingComponent :loading="tableLoading" :rows="limit" :columns="columns.length" />
        </table>
    </div>
</template>

<style></style>
