<script setup>
import { computed } from "@vue/runtime-core";
import { RouterLink } from "vue-router";
import { useStore } from 'vuex';

// Vuex Store
const store = useStore();

// Computed Properties
const packageData = computed(()=>{
    return store.getters['Packages/getPackageData'];
});
const computedExpireDateTimeStr = computed(()=>{
    if(Object.keys(packageData.value).length > 0){
        let dateTime = null;
        if(packageData.value.is_trial){
            dateTime = store.getters['Packages/getPackageData'].trial_end_at.split(' ');
        }else{
            dateTime = store.getters['Packages/getPackageData'].pkg_end_at.split(' ');
        }
        const date = dateTime[0].split('-');
        return {
            year: date[0],
            month: new Date(date[0],date[1]-1,date[2]).toLocaleString('default', { month: 'long' }),
            date: date[2],
        }
    }
    return {}
});

</script>

<template>
    <section class="dashboard-agency-view welcome-view">
        <div class="custom-container">
            <div class="primary-heading">
                <h2>Welcome to Link Wiki</h2>
            </div>
            <div class="row">
                <div class="col-lg-6 col-md-6 col-12">
                    <div class="welcome-box">
                        <div class="welcome-box-top">
                            <h3>Track your Links</h3>
                            <p>Add a project, and start taking <br> benefits from Link Wiki.</p>
                            <p>Start Crawling your domain by <br>entering your Domain URL after<br> clicking the button below.</p>
                        </div>
                        <div class="welcome-box-bottom">
                            <router-link
                                :to="{ name: 'add-project' }"
                                class="primary-btn primary-bg"
                                >Add Project</router-link
                            >
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                    <div class="welcome-box" v-if="Object.keys(computedExpireDateTimeStr).length > 0">
                        <div class="welcome-box-top">
                            <h3 v-if="packageData.pkg_cycle === 'LIFETIME'">Early Bird Package</h3>
                            <h3 v-else>{{ packageData.pkg_n }} Package</h3>
                            <!-- <p v-if="packageData.is_trial && packageData.trial_exp_days > 0">
                                You are currently subscribed to {{ packageData.pkg_n }} package(trial), trial expires on {{ packageData.trial_end_at ? packageData.trial_end_at.split(' ')[0] : '---' }}</p> -->
                            <!-- <p v-else-if="packageData.is_trial && trial_exp_days <= 0">Your trial period is ended, please explore other packages to keep using services.</p> -->
                            <p v-if="packageData.pkg_cycle === 'LIFETIME'">You currently have unlimited access as our Early Bird:</p>
                            <p v-else-if="packageData.pkg_cycle !== 'LIFETIME' && packageData.pkg_exp_days > 0">You are currently subscribed to {{ packageData.pkg_n }} package, expires on {{ packageData.pkg_end_at ? packageData.pkg_end_at : '---' }}.</p>
                            <p v-else-if="packageData.pkg_cycle !== 'LIFETIME' && packageData.pkg_exp_days <= 0">Your current package is expired, please renew your subscription to keep using services.</p>
                            <ul>
                                <li>
                                    <span>Type</span>
                                    {{ packageData.pkg_cycle ? packageData.pkg_cycle === 'LIFETIME' ? 'Free' : packageData.pkg_cycle.toLowerCase() : '--' }}
                                </li>
                                <li v-if="packageData.pkg_cycle !== 'LIFETIME'">
                                    <span>Domains</span>
                                    {{ packageData.pro_lt }}
                                </li>
                                <li v-else>
                                    <span>Domains</span>
                                    Unlimited
                                </li>
                                <li v-if="packageData.pkg_cycle !== 'LIFETIME'">
                                    <span>Links</span>
                                    {{ packageData.li_lt }}
                                </li>
                                <li v-else>
                                    <span>Links</span>
                                    Unlimited
                                </li>
                            </ul>
                        </div>
                        <div class="welcome-box-bottom">
                            <!-- <router-link v-if="packageData.pkg_cycle === 'LIFETIME'"
                                :to="{ name: 'packages' }"
                                class="primary-btn primary-bg"
                                ></router-link
                            > -->
                            <router-link v-if="packageData.pkg_cycle !== 'LIFETIME'"
                                :to="{ name: 'packages' }"
                                class="primary-btn primary-bg"
                                >Explore Other Packages</router-link
                            >
                        </div>
                        <span class="tag">YOUR PACKAGE</span>
                    </div>
                    <div class="welcome-box loading" v-else>
                        <i class="fas fa-spinner fa-spin loading"></i>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<style></style>
