<script setup>
import { onMounted, ref } from "vue";
import { RouterLink, useRouter, useRoute } from 'vue-router';
import { useStore } from "vuex";
import { useToast } from "vue-toastification";

// Vue Router
const router = useRouter();
const route = useRoute();

// Vue Store
const store = useStore();

// Toastification 
const toast = useToast();

// Normal Variables
const toastTimeOut = 3000;
const toastConfig = {
    transition: "Vue-Toastification__fade",
    position: "top-center",
    timeout: toastTimeOut,
    closeOnClick: false,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: false,
    showCloseButtonOnHover: false,
    hideProgressBar: false,
    closeButton: "button",
    icon: true,
    rtl: false
}

// Reative Variables
const authData = ref({
    states: {
        resetCode: "",
        newPassword: "",
        confirmNewPassword: "",
    },
    errors: {
        resetCode: "",
        newPassword: "",
        confirmNewPassword: "",
    }
});

// Methods
function toggleLoadingBtn(btn){
    if(!btn.classList.contains('loading')){
        btn.classList.add('loading');
        btn.disabled = true;
    }else{
        btn.classList.remove('loading');
        btn.disabled = false;
    }
}
function togglePassword(e){
    const el = e.target;
    const  parentEl = el.parentNode;
    const inputEl = parentEl.querySelector('input');

    if(!parentEl.classList.contains('show-password')){
        parentEl.classList.add('show-password');
        el.classList.remove('fa-eye-slash');
        el.classList.add('fa-eye');
        inputEl.setAttribute('type','text');
    }else{
        parentEl.classList.remove('show-password');
        el.classList.remove('fa-eye');
        el.classList.add('fa-eye-slash');
        inputEl.setAttribute('type','password');
    }
}
function validateForm(){
    let errCount = 0;
    const states = authData.value.states; 
    const errors = authData.value.errors;

    errors.resetCode = states.resetCode === '' ? 'Field is required!' : ''; 
    errors.newPassword = states.newPassword === '' ? 'Field is required!' : '';
    if(!states.confirmNewPassword){
        errors.confirmNewPassword = 'Field is required!';
    }else if(states.confirmNewPassword !== states.newPassword){
        errors.confirmNewPassword = 'Password does not match!';
    }else{
        errors.confirmNewPassword = '';
    }

    Object.keys(errors).forEach(error => {
        if(errors[error]){
            errCount++;
        }
    });

    return errCount;
}
async function submitForm(e){
    const submitBtn = e.target.querySelector('.submit-btn');

    if(validateForm() === 0){
        try{
            toggleLoadingBtn(submitBtn);
            await store.dispatch('Auth/updatePasswordAction',{
                reset_password_code: authData.value.states.resetCode,
                new_password: authData.value.states.newPassword,
                new_confirm_password: authData.value.confirmNewPassword,
                token: route.query.token,
            },{root:true});
            toast.success('Password update successfully!',toastConfig);
            
            setTimeout(()=>{
                router.push({name: 'login'});
            }, toastTimeOut);
        }catch(e){
            e.response.data.data.error.forEach((error) => {
                toast.error(error,toastConfig);
            });
        }finally{
            toggleLoadingBtn(submitBtn);
        }
    }
}

// Lifecycle Methods
onMounted(()=>{
    if(route.query.token){
        authData.value.states.resetCode = route.query.token;
    }else{
        router.push({name: 'login'});
    }
});

</script>

<template>
    <section class="dashboard-agency-auth dashboard-update-password">
        <div class="dashboard-agency-auth-form">
            <form @submit.prevent="submitForm" class="agency-form">
                <figure class="mobile-comp"><img src="@/assets/images/logo.png" alt="logo"></figure>
                <h1>Update <br> Password!</h1>
                <div class="form-group">
                    <label>New Password</label>
                    <div class="d-relative">
                        <input
                            v-model="authData.states.newPassword"
                            type="password"
                            class="form-control"
                            placeholder="********"
                        />
                        <i class="fas fa-key"></i>
                        <i
                            class="hide-password fas fa-eye-slash"
                            @click="togglePassword"
                        ></i>
                        <span class="error-message" v-if="authData.errors.newPassword"
                            ><i class="fas fa-exclamation-circle"></i>
                            {{ authData.errors.newPassword }}</span
                        >
                    </div>
                </div>
                <div class="form-group">
                    <label>Confirm New Password</label>
                    <div class="d-relative">
                        <input
                            v-model="authData.states.confirmNewPassword"
                            type="password"
                            class="form-control"
                            placeholder="********"
                        />
                        <i class="fas fa-key"></i>
                        <i
                            class="hide-password fas fa-eye-slash"
                            @click="togglePassword"
                        ></i>
                        <span class="error-message" v-if="authData.errors.confirmNewPassword"
                            ><i class="fas fa-exclamation-circle"></i>
                            {{ authData.errors.confirmNewPassword }}</span
                        >
                    </div>
                </div>
                <button class="primary-btn primary-bg lg-btn submit-btn" type="submit">
                    <span>Update Password</span>
                    <i class="fas fa-spinner fa-spin loading"></i>
                </button>
                <p class="mobile-comp">
                    Remembered your password,
                    <router-link :to="{name: 'login'}" class="p-anchor">Login Now</router-link>
                </p>
            </form>
        </div>
        <div
            class="dashboard-agency-auth-content"
            :style="{backgroundImage: 'url(' + require('../../assets/images/auth-bg.jpg') + ')'}"
        >
            <div class="wrapper">
                <h2>
                    Welcome to <br />
                    Link Wiki
                </h2>
                <p>
                    Take your link building to the next level <br />
                    with a tool that does all the work of <br />
                    monitoring your backlinks.
                </p>

                <div class="have-account">
                    <p>Remembered your password,</p>
                    <router-link :to="{name: 'login'}" class="p-anchor">Login Now</router-link>
                </div>
            </div>
        </div>
    </section>
</template>

<style></style>
