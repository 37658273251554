<script setup>
import {
    ref,
    toRefs,
    defineEmits,
    watchEffect,
    onUnmounted,
    onMounted,
    defineProps,
} from "vue";

// Props
const props = defineProps({
    isShow: Boolean,
    otherClass: String,
});

// Emits
const emit = defineEmits(["closeSideBar"]);
    
// Reactive States
const detailSideBar = ref(null);
const detailSideBarBody = ref(null);
const sidebarTop = ref(null);
const sidebarBottom = ref(null);
let { isShow } = toRefs(props);
let interval = ref(null);

// Methods
function close() {
    emit("closeSideBar");
}
function closeSideBarOnClick(e) {
    if (
        detailSideBar.value &&
        detailSideBar.value.classList.contains("show") &&
        !detailSideBarBody.value.contains(e.target)
    ) {
        close();
    }
}
function sideMenuHeight(){
    const windowHeight = window.innerHeight;
    const sideMenuLogoHeight = sidebarTop.value.offsetHeight;
    let newHeight = windowHeight - sideMenuLogoHeight - 50;
    sidebarBottom.value.style.maxHeight = `${newHeight}px`;
}

// Life Cycle Function

onMounted(() => {
    sideMenuHeight();
    window.addEventListener("click", closeSideBarOnClick);
    window.addEventListener("resize", sideMenuHeight);
});
onUnmounted(() => {
    window.removeEventListener("click", closeSideBarOnClick);
    window.removeEventListener("resize", sideMenuHeight);
});

// Watcher
const watcher = watchEffect(() => {
    if (interval.value) {
        clearInterval(interval.value);
    }

    if (isShow.value && detailSideBar.value) {
        detailSideBar.value.style.display = "block";
        interval.value = setInterval(() => {
            detailSideBar.value.classList.add("show");
        }, 500);
    } else if (!isShow.value && detailSideBar.value) {
        detailSideBar.value.classList.remove("show");
        detailSideBar.value.classList.add("hide");
        interval.value = setInterval(() => {
            if (detailSideBar.value) {
                detailSideBar.value.style.display = "none";
                detailSideBar.value.classList.remove("hide");
            } else {
                watcher();
            }
        }, 1000);
    }
});

</script>

<template>
    <div
        class="base-sidebar"
        :class="otherClass"
        ref="detailSideBar"
        style="display: none"
    >
        <div class="base-sidebar-wrapper" ref="detailSideBarBody">
            <div class="base-sidebar-top" ref="sidebarTop">
                <button
                    class="base-sidebar-close"
                    @click.prevent="close"
                >
                    <i class="fas fa-times"></i>
                </button>
                <h3>Filters</h3>
            </div>
            <div class="base-sidebar-bottom" ref="sidebarBottom">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<style></style>
