<script setup>
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import { RouterView, useRoute, useRouter} from "vue-router";
import { useToast } from 'vue-toastification';
import { useStore } from 'vuex';
import HeaderComponent from "@/components/HeaderComponent.vue";
import SidemenuComponent from "@/components/SidemenuComponent.vue";
import AlertComponent from "@/components/AlertComponent.vue";
import PackageExpiredMessage from "@/components/toastificationComponents/PackageExpiredMessage.vue";
import { AuthServices } from '@/services/authServices';
// import TrialExpiredMessage from "@/components/toastificationComponents/TrialExpiredMessage.vue";

// Toastification 
const toast = useToast();

// Vuex Store
const store = useStore();

// Vue Router
const route = useRoute();
const router = useRouter();

// Non-Reactive Variable
const miliSecondsInDay = 3600000;

// Reactivity Variables
const isEmailVerified = ref(false);
const isExpireInterval = ref(null);
const isSideBarShow = ref(false);

// Methods
function closeSidebar(val){
    isSideBarShow.value = val;
}
function openSidebar(val){
    isSideBarShow.value = val;
}
async function getPackageInfo(){
    try{
        await store.dispatch('Packages/getCurrentPackageAction',null,{root:true});
        // if(packageData.value.is_trial && packageData.value.is_trial_exp){
        //     toast.error(trialExpiredToast);
        // }else if(packageData.value.is_trial && packageData.value.trial_exp_days <= 3){
        //     toast.error(`Your trial will expire in ${packageData.value.trial_exp_days} days.`);
        // }
        if(packageData.value.pkg_cycle !== 'LIFETIME' && packageData.value.is_pkg_exp){
            toast.error(subscriptionExpiredToast);
        }else if(packageData.value.pkg_cycle !== 'LIFETIME' && packageData.value.pkg_exp_days <= 3){
            toast.error(`Your subscription will expire in ${packageData.value.pkg_exp_days} days.`);
        }
    }catch(e){
        console.log(e);
    }
}  
async function init(){
    await getPackageInfo();

    isExpireInterval.value = setInterval(()=>{
        getPackageInfo();
    },miliSecondsInDay);

    if(!userData.value.isEmailVerfied && !route.query.email_verification){
        store.dispatch('Auth/emailNotifiedAction',null,{root:true});
        isEmailVerified.value = true;
    }
}

// Life Cycle Methods
onMounted(()=>{
    init();
});
onUnmounted(()=>{
    if(isExpireInterval.value){
        clearInterval(isExpireInterval.value);
        isExpireInterval.value = null;
    }
});
// Watcher
watch(()=>route.name,()=>{
    if(route.name !== 'login'){
        AuthServices.activityLog({request_type: route.name});
    }   
});

// Computed Properties
const userData = computed(()=>{
    const data = store.getters['Auth/getUser'];
    return {
        name: data.name,
        email: data.email,
        profileImage: data.profileImage,
        isEmailVerfied: data.isEmailVerified,
        isEmailNotified: data.isEmailNotified,
        projectId: data.pro_id,
    }
});
const packageData = computed(()=>{
    return store.getters['Packages/getPackageData'];
});

// JSX
const subscriptionExpiredToast = {
    component: PackageExpiredMessage,
    listeners: {
        toPackagePage: () => {
            router.push('/packages');
        }
    }
}
// const trialExpiredToast = {
//     component: TrialExpiredMessage,
//     listeners: {
//         toPackagePage: () => {
//             router.push('/packages');
//         }
//     }
// }

</script>

<template>
    <HeaderComponent @sidebarEvent="openSidebar" :user-data="userData" :package-data="packageData"/>
    <SidemenuComponent :isSidebarShow="isSideBarShow" @sidebarEvent="closeSidebar" :user-data="userData"/>
    <main class="dashboard-agency-wrapper">
        <AlertComponent v-if="!userData.isEmailVerfied" type="danger" :show-close-btn="true">Please verify your email</AlertComponent>
        <RouterView />
    </main>
    <vue-progress-bar class="vue-progress-bar"></vue-progress-bar>
</template>

<style></style>
