<script setup>
import { defineEmits, defineProps, onMounted, onUnmounted, ref} from 'vue';
import { RouterLink, useRoute } from "vue-router";
import { Tooltip } from 'bootstrap';

const route = useRoute();

// Props
defineProps({
    isSidebarShow: Boolean,
    userData: Object,
});


// Ref Variables
const sideMenuLogo = ref(null);
const sideMenuLinks = ref(null);

// Emits
const emit = defineEmits(['sidebarEvent'])

// Methods
function sidebarClose(){
    emit('sidebarEvent',false);
}
function getToolTip(){
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new Tooltip(tooltipTriggerEl,{
            container: 'body',
            trigger : 'hover'
        })
    });
}
function sideMenuHeight(){
    const windowHeight = window.innerHeight;
    const sideMenuLogoHeight = sideMenuLogo.value.offsetHeight;
    let newHeight = windowHeight - sideMenuLogoHeight;
    sideMenuLinks.value.style.height = `${newHeight}px`;
}

// Life Cycle Fn
onMounted(() => {
    getToolTip();
    sideMenuHeight();
    window.addEventListener('resize',sideMenuHeight);
});
onUnmounted(()=>{
    window.removeEventListener('resize',sideMenuHeight);
});

</script>

<template>
    <aside class="side-menu" :class="{'show': isSidebarShow}">
        <button class="side-close-btn" @click.prevent="sidebarClose"><i class="fas fa-times"></i></button>
        <div class="side-menu-logo" ref="sideMenuLogo">
            <router-link :to="{ name: 'dashboard' }"
                ><img
                    src="../assets/images/logo.svg"
                    alt="sidemenu-logo"
                />
            </router-link>
        </div>
        <div class="side-menu-links" ref="sideMenuLinks">
            <ul>
                <li>
                    <router-link
                        :to="{ name: 'dashboard' }"
                        :class="{
                            active:
                                route.name === 'dashboard' ||
                                route.name === 'welcome',
                        }"
                        data-bs-toggle="tooltip"
                        data-bs-placement="right"
                        title="Dashboard"
                        ><i class="fas fa-th-large"></i>
                        <span>Dashboard</span></router-link
                    >
                </li>
                <li v-if="userData.projectId">
                    <router-link
                        :to="`/projects`"
                        :class="{
                            active:
                                route.name === 'project' ||
                                route.name === 'projects' ||
                                route.name === 'add-links' ||
                                route.name === 'add-project',
                        }"
                        data-bs-toggle="tooltip"
                        data-bs-placement="right"
                        title="Projects"
                        ><i class="fas fa-link"></i>
                        <span>Projects</span></router-link
                    >
                </li>
                <li v-else>
                    <router-link
                        :to="{ name: 'add-project' }"
                        :class="{
                            active:
                                route.name === 'project' ||
                                route.name === 'add-links' ||
                                route.name === 'add-project',
                        }"
                        data-bs-toggle="tooltip"
                        data-bs-placement="right"
                        title="Projects"
                        ><i class="fas fa-link"></i>
                        <span>Links</span></router-link
                    >
                </li>
                <li>
                    <router-link
                        :to="{ name: 'packages' }"
                        :class="{
                            active: 
                                route.name === 'packages' ||
                                route.name === 'payment',
                        }"
                        data-bs-toggle="tooltip"
                        data-bs-placement="right"
                        title="Packages"
                        ><i class="fas fa-shopping-cart"></i>
                        <span>Packages</span></router-link
                    >
                </li>
                <!-- <li>
                    <router-link
                        :to="{ name: 'invoices' }"
                        :class="{
                            active: 
                                route.name === 'invoices' ||
                                route.name === 'invoice-detail'
                        }"
                        data-bs-toggle="tooltip"
                        data-bs-placement="right"
                        title="Invoices"
                        ><i class="fas fa-chart-bar"></i>
                        <span>Invoices</span></router-link
                    >
                </li> -->
                <li>
                    <router-link
                        :to="{ name: 'profile' }"
                        :class="{
                            active: route.name === 'profile',
                        }"
                        data-bs-toggle="tooltip"
                        data-bs-placement="right"
                        title="Profile"
                        ><i class="fas fa-user"></i>
                        <span>Profile</span></router-link
                    >
                </li>
                <li>
                    <router-link
                        :to="{ name: 'http-status-legends' }"
                        :class="{
                            active: route.name === 'http-status-legends',
                        }"
                        data-bs-toggle="tooltip"
                        data-bs-placement="right"
                        title="HTTP Status Legends"
                        ><i class="fas fa-info-circle"></i>
                        <span>HTTP Status Legends</span></router-link
                    >
                </li>
            </ul>
        </div>
    </aside>
</template>

<style></style>
