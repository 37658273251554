<script setup>
import { computed, onMounted, ref, watch, onUnmounted } from "vue";
import { RouterLink, useRoute, useRouter } from "vue-router";
import Multiselect from 'vue-multiselect'
import LoaderComponent from "@/components/LoaderComponent.vue";
import ProgressBarAlert from "@/components/loader-components/ProgressBarAlert.vue";
import AlertComponent from "@/components/AlertComponent.vue";
import ProjectThumbnail from "@/components/projectThumbnailComponents/ProjectThumbnail.vue";
import ProjectThumbnailSelect from "@/components/projectThumbnailComponents/ProjectThumbnailSelect.vue";
import ProjectThumbnailList from "@/components/projectThumbnailComponents/ProjectThumbnailList.vue";
import ProjectThumbnailGraph from "@/components/projectThumbnailComponents/ProjectThumbnailGraph.vue";
import ProjectThumbnailLoader from "@/components/loader-components/ProjectThumbnailLoader.vue";
import OverviewLinksDetailComponent from "@/components/projectDetailComponents/OverviewLinksDetailComponent.vue";
import ProjectFilter from '@/components/projectDetailComponents/ProjectFilters.vue';
import TableLoadingComponent from '@/components/loader-components/TableLoadingComponent.vue';
import PerPageComponent from '@/components/tableComponents/PerPageComponent.vue';
import TableCountTextComponent from '@/components/tableComponents/TableCountTextComponent.vue';
import NotificationModal from "@/components/modalComponents/NotificationModal.vue";
import ModalComponent from "../../components/modalComponents/ModalComponent.vue";
import Pagination from 'laravel-vue-pagination';
import TableComponent from "@/components/tableComponents/TableComponent.vue"
import Popper from "vue3-popper";
import { Modal } from 'bootstrap';
import { useStore } from "vuex";
import { useToast } from "vue-toastification";
import moment from 'moment';

// Tostification
const toast = useToast();

// Vue Routes
const route = useRoute();
const router = useRouter();

// Vue Vuex
const store = useStore();

// Reactive Variables
let loading = ref(true);
let tableLoading = ref(true);
let isShowSidebar = ref(false);
let filterSidebarToggle = ref(false);
let apiError = ref('');
let isLinksExporting = ref(false);
const showLogs = ref(false);
const linkLogs = ref([]);
const selected = ref([]);
const allSelected = ref(false);
const domainData = ref({
    domainName: '',
    mozObj: [],
    domainHealthObj: {},
    linkWithDAObj: {},
    projectId: '',
    projectList: [
        {
            id: 1,
            name: 'Project 1'
        },
        {
            id: 2,
            name: 'Project 2'
        },
        {
            id: 3,
            name: 'Project 3'
        },
        {
            id: 4,
            name: 'Project 4'
        },
    ],
    anchor_text_calc: [],
});
const paginationData = ref(null);
const linksData = ref([]);
const searchObj = ref({
    id: "",
    sort: "",
    order: "desc",
    limit: 10,
    page: 1,
    search_links : "",
    search_text: "",
    search_moz : "",
    search_spam : "",
    search_link_found : "",
    // search_text_found : "",
    search_google_index : "",
    search_http_status: "",
    search_url_client_status: "",
    search_rel: '',
    search_source: '',
});
const linksDetailData = ref({});
let apiInterval = ref(null);
let isCrawling = ref(false);
let source = ref('');
let rmSource = ref('');
let singleSourceId = ref('');
let sourceOptions = ref([]);
let sourceFilterOptions = ref([]);
const addSourceBtn = ref(null);

const anchorSpreadColumns = ref([
    { label: "Anchor Text", field: "placement_anchor_text", html: true, },
    { label: "Count", field: "counts", html: false, },
    { label: "Percentage", field: "percentage", html: false, },
    { label: "Action", field: "action", html: false,},
]);
const linkLogsColumns = ref([
    { label: "Crawl Date", field: "created_at", html: false, },
    { label: "Destination URL", field: "placement_url", html: true, },
    { label: "Live URL", field: "live_url_link", html: true, },
    { label: "HTTP Status", field: "live_url_http_status", html: true, },
    { label: "URL Match", field: "is_placement_anchor_url_matched", html: true, },
    { label: "Google Index", field: "is_google_index", html: true, },
    { label: "Moz DA", field: "da_score", html: true, },
    { label: "Spam Score", field: "spam_score", html: true, },
]);

// Methods
function getStatus(status){
    if(!status || status >= 400){
        return 'status-box-0';
    }else if(status >= 200 && status < 300){
        return 'status-box-1'
    }else if(status >= 300 && status){
        return 'status-box-2'
    }else{
        return 'status-box-0'
    }
}
function toggleLoadingBtn(btn){
    if(!btn.classList.contains('loading')){
        btn.classList.add('loading');
        btn.disabled = true;
    }else{
        btn.classList.remove('loading');
        btn.disabled = false;
    }
}
function replaceDomainName(url){
    if(url === domainData.value.domainName || (url + '/') === domainData.value.domainName || url === (domainData.value.domainName + '/')){
        return url;
    }

    let newUrl = url.replace(domainData.value.domainName, '');
    return newUrl[0] === '/' || newUrl[0] === 'h' ? newUrl : '/' + newUrl;
}
function toggleFilterSidebar() {
    if(getComputedSearchFilters.value || (!isCrawling.value && linksData.value.length > 0)){
        filterSidebarToggle.value = filterSidebarToggle.value ? false : true;   
    }
}
function clearFilters(){
    // Resetiting Filters
    searchObj.value.sort= "";
    searchObj.value.order= "desc";
    searchObj.value.limit= 10;
    searchObj.value.page= 1;
    searchObj.value.search_links = "";
    searchObj.value.search_moz = "";
    searchObj.value.search_spam = "";
    searchObj.value.search_link_found = "";
    // searchObj.value.search_text_found = "";
    searchObj.value.search_google_index = "";
    searchObj.value.search_http_status= "";
    searchObj.value.search_url_client_status= "";
    searchObj.value.search_rel= "";
    searchObj.value.search_source= "";
    searchObj.value.search_text= "";
}
function updateSearchFilter(){
    filterSidebarToggle.value = false;
    if(apiInterval.value){
        clearAPIInterval();
    }
    searchObj.value.page = 1;
    getLinksData(true);   
}
async function clearSearchFilters(){

    // Clear Filters
    clearFilters();

    // Hitting Links API
    await getLinksData(true);
}
function onProjectChange(){
    router.push(`/projects/${domainData.value.projectId}`);   
}
function setAPIInterval(links){
    let linkCount = 0;
    if(links && links.length > 0){
        linkCount = links.filter(link => link.is_queue === 1)?.length;
    }

    if(!linkCount){
        isCrawling.value = false;
    }

    if(linkCount && !apiInterval.value){
        apiInterval.value = setInterval(() => {
            getLinksData(false);
        }, 30000);
    }else if(!linkCount && apiInterval.value){
        if(localStorage.getItem('pro-a-type')){
            localStorage.removeItem('pro-a-type');
        }
        clearAPIInterval();
        linkCount = 0;
        isCrawling.value = false;
    }
}
async function clearAPIInterval(){
    clearInterval(apiInterval.value);
    await getDomainData(searchObj.value.id);
    apiInterval.value = null;
    isCrawling.value = false;
}
async function getDomainData(id){
    let errorModal = new Modal(document.getElementById('project-error-modal'));

    try{
        let res = await store.dispatch('Domain/getDomainDataAction', {id: id,type: ''});
        let data = res.data;
        if(res.success){
            domainData.value.projectId = res.project_id;
            domainData.value.domainName = data.link.domain_data.domain_link;
            domainData.value.lastCrawledAt = data.link.domain_data.last_crawl_date;
            domainData.value.mozObj = data.link.moz_array;
            domainData.value.domainHealthObj = data.link.health_score;
            domainData.value.calcLogs = data.link.calc_logs;
            domainData.value.linkWithDAObj = data.link.links_with_da;
            domainData.value.projectList = res.projects;
            domainData.value.anchor_text_calc = data.link.anchor_text_calc;
        }else if(!res.success){
            apiError.value = `<h4>Project not Found</h4><p>Project does not exist or deleted.</p>`;
            
            setTimeout(()=>{
                errorModal.show();
            }, 1000);
        }
    }catch(e){
        if(e.code !== "ERR_NETWORK"){
            apiError.value = `<h4>Something Unexpected Occured</h4><p>Something unexpected happened, try again later!</p>`;
            setTimeout(()=>{
                errorModal.show();
            }, 1000);
        }
    }
}
async function getLinksData(load){
    try{
        allSelected.value = false;
        selected.value = [];
        tableLoading.value = load;
        let res = await store.dispatch('Domain/getLinksDataAction', {
            type: localStorage.getItem('pro-a-type') ? localStorage.getItem('pro-a-type') : ''
            ,...searchObj.value
        });
        if(res.http_status_code === 200){
            paginationData.value = {
                data: res.data.data,
                links: res.data.links,
                meta: {
                    current_page: res.data.current_page,
                    from: res.data.from,
                    last_page: res.data.last_page,
                    path: res.data.path,
                    per_page: res.data.per_page,
                    to: res.data.to,
                    total: res.data.total,
                }
            }
            linksData.value = res.data.data;
            sourceFilterOptions.value = res.data.filter_source;
            setAPIInterval(linksData.value);
            tableLoading.value = false;
        }     
    }catch(e){
        if(e.code !== "ERR_NETWORK"){
            if(e.response.data.http_status_code === 404){
                apiError.value = `<h4>Links Not Found</h4><p>You haven't added any link to this project. Click below to add links now.</p>`;
                let errorModal = new Modal(document.getElementById('links-error-modal'));
                setTimeout(()=>{
                    errorModal.show();
                }, 1000);
            }else{
                apiError.value = `<h4>Something Unexpected Occured</h4><p>Something unexpected happened, try again later!</p>`;
                let errorModal = new Modal(document.getElementById('project-error-modal'));
                setTimeout(()=>{
                    errorModal.show();
                }, 500);
            }

            tableLoading.value = false;
            isCrawling.value = false;
        }
    }
}
async function exportLinks(){
    if(!isCrawling.value || linksData.value.length > 0){
        try{
            isLinksExporting.value = true;
            let res = await store.dispatch('Domain/exportLinksDataAction', {id: route.params.id, ...searchObj.value})
            downloadFile(res, 'links');
            toast.success('Export CSV successfully.');
        }catch(e){
            apiError.value = `<h4>Something Unexpected Occured</h4><p>Something unexpected happened, try again later!</p>`;
            let errorModal = new Modal(document.getElementById('project-error-modal'));
            setTimeout(()=>{
                errorModal.show();
            }, 500);
        }finally{
            isLinksExporting.value = false;
        }   
    }else{
        toast.error('No links available in this project.');
    }
}
function downloadFile(response, filename) {
    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should
    var newBlob = new Blob([response], { type: 'text/csv' });

    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(newBlob);
    return;
    }

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob);
    var link = document.createElement('a');
    link.href = data;
    link.download = filename + '.csv';
    link.click();
    setTimeout(function() {
    // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
    }, 100);
}

// Link Detail Event
function sideBarClose() {
    isShowSidebar.value = false;
}
function viewDetail(id) {
    let redId = linksData.value.findIndex(link => link.id === id);
    linksDetailData.value = linksData.value[redId];
    isShowSidebar.value = true; 
}
async function OverviewLinksDetailEvent(data){
    if(data.type === 'updatePlacementData'){
        await updateAnchorTextURLData(data);
    }
}
async function updateAnchorTextURLData(obj){
    try{
        let res = await store.dispatch('Domain/updateAnchorTextURLAction', obj, {root:true});
        let data = res.data;
        if(res.success){
            const id = linksData.value.findIndex(link => link.id === data.lpm_id);
            if(obj.column === 'new_placement_anchor_url'){
                linksData.value[id].new_placement_anchor_url = '';
                linksData.value[id].placement_url = data.value;
            }else if(obj.column === 'new_placement_anchor_text'){
                linksData.value[id].new_placement_anchor_text = '';
                linksData.value[id].placement_anchor_text = data.value;
            }
        }
    }catch(e){
        if(e.code !== "ERR_NETWORK"){
            if(!e.response.data.success && e.response.data.http_status_code === 422){
                toast.error(e.response.data.message);
            }else{
                toast.error('Something unexpected happened!');
            }
        }
    }
}
async function getLinksLog(id){
    if(!showLogs.value){
        try {
            showLogs.value = true;

            let res = await store.dispatch('Domain/getLinksDataLog', {id: id});
            linkLogs.value = res;
            new Modal(document.getElementById('link-logs')).show();
        } catch (e) {
            if(e.code !== "ERR_NETWORK"){
                toast.error('Something unexpected happened!');
            }
        }finally{
            showLogs.value = false;
        }  
    } 
}

// Show All Anchor Text
function projectThumbnailListEvent(e){
    if(e.type === 'showAllAnchorText'){
        showAllAnchorText();
    }else if(e.type === 'filterAnchorText'){
        // Clear Filters
        clearFilters();

        // Update Filters
        searchObj.value.search_text = e.anchor;
        getLinksData(true);  
    }
    else if(e.type === 'filter'){
        // Clear Filters
        clearFilters();
        
        // Update Filters
        if(e.data.for === 'Links with DA'){
            searchObj.value.search_moz = e.data.value;
        }else if(e.data.for === "Links' Stats"){
            searchObj.value.search_http_status = e.data.value;
        }
        getLinksData(true);  
    }
}
function showAllAnchorText(){
    const modal = new Modal(document.getElementById('all-anchor-text-modal'));    
    modal.show();
}
async function anchorSpreadTableEvent(data){
    if(data.type === 'search'){
        searchObj.value.search_text = data.anchor_text;

        // Closing Modal
        const modal = Modal.getInstance(document.getElementById('all-anchor-text-modal'));
        modal.hide();        

        // Updating Filters
        await updateSearchFilter();         
    }
}

// Source Method
function openRemoveSourceConfirmModal(id){
    const confirmModal = new Modal(document.getElementById('remove-source-confirm-modal'));
    rmSource.value = id;
    
    confirmModal.show();
}
function openSourceModal(src, id=""){
    let sourceModal = new Modal(document.getElementById('add-tag-modal'));
    source.value = '';
    singleSourceId.value = [];

    if(getComputedSelectedRowsLength.value === 1 || id){
        allSelected.value = false;
        if(id){
            source.value = src;
            singleSourceId.value.push(id);
        }else{
            const index = linksData.value.findIndex(link => selected.value[0] === link.id);
            source.value = linksData.value[index].source;
        }
    }

    sourceModal.show(); 
}
async function addSource(src=""){
    const submitBtn = addSourceBtn.value;
    const sourceModal = Modal.getInstance(document.getElementById('add-tag-modal'));

    if(src){
        source.value = src; 
    }
    sourceOptions.value.push(source.value);
    sourceOptions.value = [...new Set(sourceOptions.value)];

    try{
        toggleLoadingBtn(submitBtn);
        const payload = {
                            source: source.value,
                            lpm_ids: singleSourceId.value.length > 0 ? singleSourceId.value : selected.value,
                        };

        let res = await store.dispatch('Domain/addSourceAction', payload);

        if(res.success){
            // Updating Table Data
            await getLinksData(true);

            // Updating Tag List 
            await getSources();

            // Show Toast
            toast.success('Source successfully added');

            // Closing Modal
            sourceModal.hide();
        }
    }catch(e){
        if(e.code !== "ERR_NETWORK"){
            if(!e.response.data.success){
                toast.error(e.response.data.message);
            }
        }
    }
    finally{
        toggleLoadingBtn(submitBtn);
    }
}
async function removeSource(e){
    const submitBtn = e.target;
    const confirmModal = Modal.getInstance(document.getElementById('remove-source-confirm-modal'));
    try{
        toggleLoadingBtn(submitBtn);
        let res = await store.dispatch('Domain/removeSourceAction', rmSource.value);

        if(res.success){
            // Updating Table Data
            await getLinksData(true);

            // Updating Tag List 
            await getSources();

            // Show Toast
            toast.success(res.message);

            // Closing Modal
            confirmModal.hide();
        }

    }catch(e){
        if(e.code !== "ERR_NETWORK"){
            if(!e.response.data.success){
                toast.error('Something unexpected hanppened');
            }
        }
    }finally{
        toggleLoadingBtn(submitBtn);
    }
}
async function getSources(){
    try{
        let res = await store.dispatch('Domain/getSourcesAction');
        if(res.success){
            sourceOptions.value = res.source;
        }
    }catch(e){
        console.log(e);
    }
}

// Table Methods
function applyColumnSorting(event, name){
    
    [...document.querySelectorAll('.sort')].forEach(item => {
        item.querySelector('.fa-caret-up').classList.remove('active');
        item.querySelector('.fa-caret-down').classList.remove('active');
    });

    if(searchObj.value.sort === name){
        searchObj.value.order = searchObj.value.order === 'desc' ? 'asc' : 'desc';
    }else{
        searchObj.value.order = 'asc';
    }

    const fa1 = event.currentTarget.querySelector('.fa-caret-up');
    const fa2 = event.currentTarget.querySelector('.fa-caret-down');

    if(searchObj.value.order === 'asc'){
        fa1.classList.add('active');
        fa2.classList.remove('active');
    }else{
        fa1.classList.remove('active');
        fa2.classList.add('active');
    }

    searchObj.value.sort = name;
    getLinksData(true); 
}
function changePerPage(){
    searchObj.value.page = 1;
    getLinksData(true);
}
function paginateData(page){
    if(apiInterval.value){
        clearAPIInterval();
    }

    // Updating Search Object
    searchObj.value.page = page;

    // Hitting Links API
    getLinksData(true);
}
function isAllSelected(){
    if(allSelected.value){
        linksData.value.forEach(link => selected.value.push(link.id));
        selected.value = [...new Set(selected.value)];
    }else{
        selected.value = [];
    }
}

// Init Method
async function init(id){
    
    // Default State Variables
    loading.value = true;
    isCrawling.value = false;
    tableLoading.value = true;
    apiError.value = '';
    paginationData.value = null;
    linksData.value = [];
    linksDetailData.value = {};
    isCrawling.value = true;
    domainData.value = {
        domainName: '',
        mozObj: [],
        domainHealthObj: {},
        linkWithDAObj: {},
        projectId: '',
        projectList: [
            {
                id: 1,
                name: 'Project 1'
            },
            {
                id: 2,
                name: 'Project 2'
            },
            {
                id: 3,
                name: 'Project 3'
            },
            {
                id: 4,
                name: 'Project 4'
            },
        ]
    }
    searchObj.value = {
        id: "",
        sort: "",
        order: "desc",
        limit: 10,
        page: 1,
        search_links : "",
        search_moz : "",
        search_spam : "",
        search_link_found : "",
        // search_text_found : "",
        search_google_index : "",
        search_http_status: "",
        search_url_client_status: "",
        search_rel: "",
        search_source: "",
        search_text: "",
    }

    setTimeout(()=>{
        loading.value = false;
    }, 2000);

    // Checking For API Interval    
    if(apiInterval.value){
        clearAPIInterval();
    }

    // Get Id Param From URL
    searchObj.value.id = id;

    // Get Domain Details
    await getDomainData(searchObj.value.id);

    if(!apiError.value){
        // Get Links Details
        isCrawling.value = true;
        await getLinksData(true);

        // Get Tags List
        await getSources();
    }

    [...document.querySelectorAll('.sort')]?.forEach(item => {
        item.querySelector('.fa-caret-up').classList.remove('active');
        item.querySelector('.fa-caret-down').classList.remove('active');
    });
}

// Computed Properties
const getComputedHealthData = computed(()=>{
    return {
        'Crawled': {
            val: domainData.value.domainHealthObj.crawled,
            diff: domainData.value.domainHealthObj.crawled - domainData.value.calcLogs.crawled_links_count,
            tooltip: 'The total number of links that have been added.',
        },
        'Redirects': {
            val: domainData.value.domainHealthObj.redirects,
            diff: domainData.value.domainHealthObj.redirects - domainData.value.calcLogs.redirects_links_count,
            tooltip: 'Live URL Links that are redirecting to another page',
            filter: 'http_300'
        },
        'Broken': {
            val: domainData.value.domainHealthObj.broken,
            diff: domainData.value.domainHealthObj.broken - domainData.value.calcLogs.broken_links_count,
            tooltip: 'URL Links that are broken',
            filter: 'http_400'
        },
        'Blocked': {
            val: domainData.value.domainHealthObj.blocked,
            diff: domainData.value.domainHealthObj.blocked - domainData.value.calcLogs.blocked_links_count,
            tooltip: 'URL Links that are restricted',
            filter: 'http_600'
        },
        'Server Down': {
            val: domainData.value.domainHealthObj.server_down,
            diff: domainData.value.domainHealthObj.server_down - domainData.value.calcLogs.server_down_links_count,
            tooltip: 'The server of the URL isn\'t working.',
            filter: 'http_500'
        },
        'Health Score':{
            val: domainData.value.domainHealthObj.health_score ? domainData.value.domainHealthObj.health_score.toFixed(1) : 0,
            tooltip: 'A ratio of the live URLs versus links that are redirecting, broken, blocked or not working because the server is down.',
        },  
    }
});
const getComputedDAData = computed(()=>{
    return {
        'Below DA 30': {
            val: domainData.value.linkWithDAObj['da_0+'],
            diff: domainData.value.linkWithDAObj['da_0+'] - domainData.value.calcLogs.below_da_30_links_count,
            filter: 'da_0'
        },
        'DA 30 +': {
            val: domainData.value.linkWithDAObj['da_30+'],
            diff: domainData.value.linkWithDAObj['da_30+'] - domainData.value.calcLogs.da_30_links_count,
            filter: 'da_30'
        },
        'DA 40 +': {
            val: domainData.value.linkWithDAObj['da_40+'],
            diff: domainData.value.linkWithDAObj['da_40+'] - domainData.value.calcLogs.da_40_links_count,
            filter: 'da_40'
        },
        'DA 50 +': {
            val: domainData.value.linkWithDAObj['da_50+'],
            diff: domainData.value.linkWithDAObj['da_50+'] - domainData.value.calcLogs.da_50_links_count,
            filter: 'da_50'
        }, 
        'DA 60 +': {
            val: domainData.value.linkWithDAObj['da_60+'],
            diff: domainData.value.linkWithDAObj['da_60+'] - domainData.value.calcLogs.da_60_links_count,
            filter: 'da_60'
        }, 
    }    
})
const getComputedAnchorData = computed(()=>{
    const obj = {};
    let newAnchorTextArr = [];
    if(domainData.value.anchor_text_calc.length > 5){
        newAnchorTextArr = domainData.value.anchor_text_calc.slice(0, 5);
    }else{
        newAnchorTextArr = domainData.value.anchor_text_calc;
    }
    
    newAnchorTextArr.forEach(anchor => {
        obj[anchor.placement_anchor_text] = {
            val: Number(anchor.counts),
            per: Number(anchor.percentage).toFixed(2),
        }
    });
    return obj;
});
const getComputedSearchFilters = computed(()=>{
    let filterCount = 0;
    const filters = {
        search_links : searchObj.value.search_links,
        search_moz : searchObj.value.search_moz,
        search_spam : searchObj.value.search_spam,
        search_link_found : searchObj.value.search_link_found,
        // search_text_found : searchObj.value.search_text_found,
        search_google_index : searchObj.value.search_google_index,
        search_http_status: searchObj.value.search_http_status,
        search_url_client_status: searchObj.value.search_url_client_status,
        search_rel: searchObj.value.search_rel,
        search_source: searchObj.value.search_source,
        search_text: searchObj.value.search_text,
    }
    for(let val of Object.values(filters)){
        if(val !== ""){
            filterCount++;
        }
    }

    return filterCount;
});
const getComputedSelectedRowsLength = computed(()=>{
    return selected.value.length;
})
const getComputedAnchorSpreadRows = computed(()=>{
    if(domainData.value.anchor_text_calc && domainData.value.anchor_text_calc.length > 0){
        return domainData.value.anchor_text_calc.map(row => {
            return {
                placement_anchor_text: `<span class="anchor_text">${row.placement_anchor_text}</span>`,
                counts: Number(row.counts),
                percentage: `${Number(row.percentage).toFixed(2)}%`,
                action: [
                    {
                        anchor_text: row.placement_anchor_text,
                        label: "<i class='fas fa-search'></i>",
                        type: "search",
                        route: "#",
                        target: "",
                        disable: false,
                    },
                ]
            }
        })
    } 

    return null;
});  
const linkLogsRows = computed(()=>{
    return linkLogs.value.map(row => {
        return {    
            created_at: moment(row.created_at.split(' ')[0]).format('MM-DD-YYYY'),
            placement_url: (row.placement_url) ? `<a href="${row.placement_url}" target="_blank"><span>${row.placement_url}</span> <i style="padding-left: 6px;" class="fas fa-external-link-alt"></i></a>`: `<span>---</span>`,
            live_url_link: (row.live_url_link) ? `<a href="${row.live_url_link}" target="_blank"><span>${row.live_url_link}</span> <i style="padding-left: 6px;" class="fas fa-external-link-alt"></i></a>`: `<span>---</span>`,
            live_url_http_status: `<div class="status-box ${getStatus(row.live_url_http_status)}">
                                        Status
                                        <span>${row.live_url_http_status}</span>
                                    </div>`,
            is_placement_anchor_url_matched: row.is_placement_anchor_url_matched === 0 ? '<div style="color: #ff0e52;font-weight: 700">No</div>' : '<div style="color: #00A66C; font-weight: 700">Yes</div>',
            is_google_index: (row.is_google_index === 0) ? `<div class="google-index"><i class="fab fa-google" style="color: #ff0e52;font-weight: 700"></i></div>` : `<div class="google-index"><i class="fab fa-google" style="color: #00A66C;font-weight: 700"></i></div>`,
            da_score: row.da_score,
            spam_score: row.spam_score < 0 ? '1%' : row.spam_score + '%',
        }
    });
})

// Life Cycle Methods
onMounted(()=>{
    init(route.params.id);
});
onUnmounted(()=>{
    if(apiInterval.value){
        clearAPIInterval();
    }
});

// Watcher
watch(()=>route.params.id,(newId)=>{
    if(route.name === 'project'){
        init(newId);
    }
});

</script>

<template>
    <section class="dashboard-agency-view overiew-links-view">
        <LoaderComponent :isLoading="loading" />
        <ProgressBarAlert :show="isCrawling" message="Please standby as we gather your data. It might take a few, <br> so sit back and sip that latte, tea, espresso, boba tea, matcha… "/>    

        <div class="primary-heading">
            <div class="row align-items-center justify-content-between">
                <div class="col-lg-6 col-md-8 col-8">
                    <h2>Your Projects</h2>
                </div>
                <div class="col-lg-6 col-md-4 col-4">
                    <div class="dashboard-agency-heading-btn text-md-end">
                        <router-link
                            :to="{ name: 'add-project' }"
                            class="primary-btn primary-bg"
                            >Add Project</router-link
                        >
                    </div>
                </div>
            </div>
            <!-- <p v-if="isCrawling" class="crawled-message"><i class="fas fa-spinner fa-spin loading"></i> Please wait while we are fetching some useful information for you.</p> -->
        </div>

        <div class="domain-link-info">
            <ProjectThumbnail v-if="domainData.mozObj.length > 0">
                <template #project-thumbnail-top-name>
                    <div class="project-thumbnail-select">
                        <ProjectThumbnailSelect v-if="domainData.projectId" v-model="domainData.projectId" :data="domainData.projectList" @updateRoute="onProjectChange"/>
                        <p v-else>Domain Name</p>
                    </div>
                </template>
                <template #project-thumbnail-top-slot>
                    <div class="project-thumbnail-top-btns">
                        <router-link class="primary-btn primary-bg" :to="`/add-links/${domainData.projectId}`">Add Links</router-link>
                    </div>
                </template>
                <template #project-thumbnail-bottom-1>
                    <ProjectThumbnailList 
                        :title="{
                            name: 'Links\' Stats',
                            tooltip: 'A concise summary of how your added links are functioning. '
                        }"
                        :data="getComputedHealthData" 
                        :list-filter="true"
                        @event="projectThumbnailListEvent"
                    />
                </template>
                <template #project-thumbnail-bottom-2>
                    <!-- <ProjectThumbnailBoxVal 
                        title="Links' Health"
                        :project-id="domainData.projectId"
                        :link-count="Number(domainData.domainHealthObj.crawled)"
                        tooltip="A ratio of the live URLs versus links that are redirecting, broken, blocked or not working because the server is down."
                        :value="domainData.domainHealthObj.health_score"
                    /> -->
                    <ProjectThumbnailList 
                        :title="{
                            name: 'Anchor Spread',
                            tooltip: 'Provides count and percentage of Anchor texts of your backlinks.'
                        }"
                        :project-id="domainData.projectId"
                        :data="getComputedAnchorData" 
                        :count="domainData.anchor_text_calc.length"
                        :list-filter="true"
                        @event="projectThumbnailListEvent"
                    />
                </template>
                <template #project-thumbnail-bottom-3>
                    <ProjectThumbnailGraph 
                        title="Current DA"
                        tooltip="The current Domain Authority(DA) or presence of the project. Higher DA indicates successful SEO. "
                        graphTitle="Avg DA"
                        name="da_score"
                        :data="domainData.mozObj"
                        chartColor="#FF6824"
                    />
                </template>
                <template #project-thumbnail-bottom-4>
                    <ProjectThumbnailGraph 
                        title="Links Tracked"
                        tooltip="The number of links that are being tracked under the project."
                        graphTitle="Link Tracked"
                        name="crawled_count"
                        :data="domainData.mozObj"
                        chartColor="#5338FC"
                    />
                </template>
                <template #project-thumbnail-bottom-5>
                     <ProjectThumbnailGraph 
                        title="Spam Score"
                        tooltip="The percentage of Links under the project that can be considered spammy." 
                        graphTitle="Avg SS"
                        name="spam_score"
                        :data="domainData.mozObj"
                        chartColor="#FF0E52"
                    />
                </template>
                <template #project-thumbnail-bottom-6>
                    <ProjectThumbnailList 
                        :title="{
                            name: 'Links with DA',
                            tooltip: 'A summary of Live URL links and their Domain Authority'
                        }"
                        :data="getComputedDAData" 
                        :list-filter="true"
                        @event="projectThumbnailListEvent"
                    />
                </template>
            </ProjectThumbnail>
            <ProjectThumbnailLoader v-else />
        </div>

        <div class="dashboard-agency-table domain-link-table">
            <div class="row align-items-center">
                <div class="col-lg-4 col-md-4 col-12">
                    <div class="primary-heading">
                        <h3>Links Overview</h3>
                    </div>
                </div>
                <div class="col-lg-8 col-md-8 col-12">
                    <div class="table-filter">
                        <button class="filter-btn tag-btn" @click="openSourceModal" v-if="selected.length">
                            Add Source <i class="fas fa-tags"></i>
                        </button>
                        <button class="filter-btn" @click.prevent="toggleFilterSidebar">
                            Filter <i class="fas fa-sort-amount-down"></i>
                        </button>
                        <button class="filter-btn" @click.prevent="clearSearchFilters" v-if="getComputedSearchFilters">
                            Clear({{ getComputedSearchFilters}}) <i class="fas fa-times"></i>
                        </button>
                        <button class="primary-btn tertiary-transparent-btn export-btn" @click.prevent="exportLinks" :disabled="isCrawling || linksData.length <= 0">
                            <span v-if="!isLinksExporting">Export <i class="fas fa-file-export"></i></span>
                            <i v-if="isLinksExporting" class="fas fa-spinner fa-spin loading"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="table-wrapper">
                <PerPageComponent 
                    v-model="searchObj.limit" 
                    :options="[10,25,50,100]"
                    @changePerPage="changePerPage"
                />
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr :class="{'selected': allSelected || selected.length}">
                                <th class="table-check-input">
                                    <div>
                                        <input type="checkbox" id="table-check-all" class="d-none" @change="isAllSelected" v-model="allSelected">
                                        <label for="table-check-all"></label>
                                    </div>
                                </th>
                                <th><span v-tooltip="'The page that the backlink leads to.'">Destination URL</span></th>
                                <th><span v-tooltip="'The URL that contains the Backlink.'">Live URL</span></th>
                                <th><div class="d-flex align-items-center"><span v-tooltip="'Indicates the current status of the monitored link. 200 represents an active backlink. '">Status</span>  <span class="sort" @click="(event)=>applyColumnSorting(event,'live_url_http_status')"><i class="fas fa-caret-up"></i><i class="fas fa-caret-down"></i></span></div></th>
                                <th><span v-tooltip="'The clickable text that leads to the destination URL.'">Anchor Text</span></th>
                                <th><div class="d-flex align-items-center"><span v-tooltip="'Check if the destination URL matches the backlink placed on the Live URL.'">URL Match</span> <span class="sort" @click="(event)=>applyColumnSorting(event,'is_placement_anchor_url_matched')"><i class="fas fa-caret-up"></i><i class="fas fa-caret-down"></i></span></div></th>
                                <!-- <th><div class="d-flex align-items-center"><span v-tooltip="'Check if the provided anchor text matches the present one on the Live URL.'">Text Match</span> <span class="sort" @click="(event)=>applyColumnSorting(event,'is_placement_anchor_text_matched')"><i class="fas fa-caret-up"></i><i class="fas fa-caret-down"></i></span></div></th> -->
                                <th><div class="d-flex align-items-center"><span v-tooltip="'Check if Google has indexed the Live URL page with the Domain.'">Google Index</span> <span class="sort" @click="(event)=>applyColumnSorting(event,'is_google_index')"><i class="fas fa-caret-up"></i><i class="fas fa-caret-down"></i></span></div></th>
                                <th><div class="d-flex align-items-center"><span v-tooltip="'Domain Authority, a score from 0 to 100 rating the overall presence of the Live URL Website. The stats are taken from Moz.'">Moz DA</span> <span class="sort" @click="(event)=>applyColumnSorting(event,'live_url_da_score')"><i class="fas fa-caret-up"></i><i class="fas fa-caret-down"></i></span></div></th>
                                <th><div class="d-flex align-items-center"><span v-tooltip="'The probability of the Live URL being spammy.'">Spam Score</span> <span class="sort" @click="(event)=>applyColumnSorting(event,'live_url_spam_score')"><i class="fas fa-caret-up"></i><i class="fas fa-caret-down"></i></span></div></th>
                                <th><div class="d-flex align-items-center"><span v-tooltip="'Give this link a source to classify its acquisition.'">Source</span> <span class="sort" @click="(event)=>applyColumnSorting(event,'source')"><i class="fas fa-caret-up"></i><i class="fas fa-caret-down"></i></span></div></th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody v-if="linksData.length > 0 && !tableLoading">
                            <tr v-for="link in linksData" :key="link.id" :class="{'selected':selected.includes(link.id)}">
                                <td class="table-check-input">
                                    <input type="checkbox" :id="'table-check-'+link.id" class="d-none" v-model="selected" :value="link.id">
                                    <label :for="'table-check-'+link.id"></label>
                                </td>
                                <td>
                                    <div class="d-flex-between">
                                        <a :href="link.placement_url" target="_blank"
                                            ><span>{{ link.placement_url ? replaceDomainName(link.placement_url) : '--' }}</span>
                                            <i class="fas fa-external-link-alt"></i
                                        ></a>
                                    </div>    
                                </td>

                                <td>
                                    <div class="d-flex-between">
                                        <a :href="link.live_url_link" target="_blank"
                                            ><span>{{ link.live_url_link ? link.live_url_link : '--' }}</span>
                                            <i class="fas fa-external-link-alt"></i
                                        ></a>
                                    </div>
                                </td>

                                <td>
                                    <Popper
                                        class="error"
                                        :content="link.live_url_http_status === 800 ? 'Crawl Error' : link.live_url_http_status_reason"
                                        :arrow="true"
                                        :hover="true"
                                        placement="top"
                                        v-if="link.live_url_http_status_reason"
                                    >
                                        <div class="status-box" 
                                            :class="{'status-box-0':!link.live_url_http_status || link.live_url_http_status >= 400,
                                                    'status-box-1': link.live_url_http_status >= 200 && link.live_url_http_status < 300,
                                                    'status-box-2': link.live_url_http_status >= 300 && link.live_url_http_status < 400,       
                                            }">
                                            Status
                                            <span v-if="!link.live_url_http_status"><i class="fas fa-spinner fa-spin loading"></i></span>
                                            <span v-else>{{ link.live_url_http_status }}</span>
                                        </div>
                                    </Popper>
                                </td>

                                <td><span>{{ link.placement_anchor_text ? link.placement_anchor_text : '---' }}</span></td>

                                <td v-if="!link.is_queue" :class="{'yes': link.is_placement_anchor_url_matched, 'no': !link.is_placement_anchor_url_matched}">{{ link.is_placement_anchor_url_matched ? 'Yes' : 'No' }}</td>
                                <td v-else class="td-loading"><div></div></td>

                                <!-- <td v-if="!link.is_queue" :class="{'yes': link.is_placement_anchor_text_matched, 'no': !link.is_placement_anchor_text_matched}">{{ link.is_placement_anchor_text_matched ? 'Yes' : 'No' }}</td>
                                <td v-else class="td-loading"><div></div></td> -->

                                <td v-if="!link.is_queue">
                                    <Popper
                                        class="error"
                                        :content="link.is_google_index ? 'Yes' : 'No'"
                                        :arrow="true"
                                        :hover="true"
                                        placement="top"
                                    >
                                        <div class="google-index"><i class="fab fa-google" :class="{'yes':link.is_google_index, 'no': !link.is_google_index}"></i></div>
                                    </Popper>
                                </td>
                                <td v-else class="td-loading"><div></div></td>

                                <td v-if="!link.is_queue">{{ link.live_url_da_score }}<span class="diff-val" :style="{'color': (link.live_url_da_score - link.last_da) < 0 ? '#ff0e52' : '#38cb89'}"><i class="fas" :class="{'fa-caret-down':(link.live_url_da_score - link.last_da) < 0, 'fa-caret-up':(link.live_url_da_score - link.last_da) >= 0 }"></i>{{ Math.abs(link.live_url_da_score - link.last_da) }}</span></td>
                                <td v-else class="td-loading"><div></div></td>

                                <td v-if="!link.is_queue">{{ link.live_url_spam_score <= 0 ? '1%' : link.live_url_spam_score + '%' }} <span class="diff-val" :style="{'color': (link.live_url_spam_score - link.last_spam) <= 0 ? '#38cb89' : '#ff0e52'}"><i class="fas" :class="{'fa-caret-up':(link.live_url_spam_score - link.last_spam) > 0, 'fa-caret-down':(link.live_url_spam_score - link.last_spam) <= 0 }"></i>{{ (link.live_url_spam_score - link.last_spam) === 0 ? 0: Math.abs(link.live_url_spam_score - link.last_spam) + '%' }}</span></td>
                                <td v-else class="td-loading"><div></div></td>

                                <td v-if="!link.is_queue">
                                    <ul class="tags" v-if="link.source">
                                        <li><span>{{ link.source }}</span> <button @click.prevent="openRemoveSourceConfirmModal(link.id)"><i class="fas fa-times-circle"></i></button></li>
                                        <li @click.prevent="openSourceModal(link.source,link.id)"><i class="fas fa-tags"></i></li>
                                    </ul>
                                    <ul class="tags" v-else>
                                        <li @click.prevent="openSourceModal(link.source,link.id)"><i class="fas fa-tags"></i></li>
                                    </ul>
                                </td>
                                <td v-else class="td-loading"><div></div></td>

                                <td class="btns">
                                    <button @click.prevent="viewDetail(link.id)" v-tooltip="'View'">
                                        <i class="fas fa-eye"></i>
                                    </button>
                                    <button @click.prevent="getLinksLog(link.id)" v-tooltip="'Logs'">
                                        <i class="fas fa-history"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                        <TableLoadingComponent :loading="tableLoading" :rows="searchObj.limit" :columns="11" />
                    </table>
                </div>
                <TableCountTextComponent v-if="paginationData && linksData.length > 0" 
                    :from="paginationData.meta.from"
                    :to="paginationData.meta.to" 
                    :total="paginationData.meta.total" 
                />
            </div>
            <AlertComponent v-if="linksData.length === 0 && !tableLoading" type="warning" :show-close-btn="false">No records found.</AlertComponent>
            <div class="table-pagination" v-if="paginationData && paginationData.meta.last_page > 0">
                <Pagination :data="paginationData" :limit="1" @pagination-change-page="paginateData">
                    <template #prev-nav>
                        <span>&lt; Previous</span>
                    </template>
                    <template #next-nav>
                        <span>Next &gt;</span>
                    </template>
                </Pagination>
            </div>
        </div>

        <OverviewLinksDetailComponent :showOldData="false" :is-show="isShowSidebar" :data="linksDetailData" @custom-event="OverviewLinksDetailEvent" @close-side-bar="sideBarClose" />
        
        <ProjectFilter :is-show="filterSidebarToggle" @close-side-bar="toggleFilterSidebar" other-class="project-filter-sidemenu">
            <div class="agency-form-2">
                <div class="row">
                    <div class="col-lg-5 col-md-5 col-12">
                        <div class="form-group">
                            <label>Search by Live URL</label>
                            <input type="text" class="form-control" v-model="searchObj.search_links" placeholder="eg. https://liveurl.com">
                        </div>
                        <div class="form-group">
                            <label>Search by Anchor Text</label>
                            <input type="text" class="form-control" v-model="searchObj.search_text" placeholder="eg. anchor text">
                        </div>
                        <div class="form-group">
                            <label>HTTP Status Code</label>
                            <div class="d-relative">
                                <select class="form-control" v-model="searchObj.search_http_status">
                                    <option value="">Select Status Code</option>
                                    <option value="http_200">200+</option>
                                    <option value="http_300">300+</option>
                                    <option value="http_400">400+</option>
                                    <option value="http_500">500+</option>
                                    <option value="http_600">600+</option>
                                    <option value="http_800">800+</option>
                                </select>
                                <i class="fas fa-angle-down"></i>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Spam Score</label>
                            <div class="d-relative">
                                <select class="form-control" v-model="searchObj.search_spam">
                                    <option value="">Select any option</option>
                                    <option value="0">0% +</option>
                                    <option value="10">10% +</option>
                                    <option value="20">20% +</option>
                                    <option value="30">30% +</option>
                                    <option value="40">40% +</option>
                                    <option value="50">50% +</option>
                                    <option value="60">60% +</option>
                                    <option value="70">70% +</option>
                                    <option value="80">80% +</option>
                                    <option value="90">90% +</option>
                                </select>
                                <i class="fas fa-angle-down"></i>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Rel</label>
                            <div class="d-relative">
                                <select class="form-control" v-model="searchObj.search_rel">
                                    <option value="">Select any option</option>
                                    <option value="author">author</option>
                                    <option value="bookmark">bookmark</option>
                                    <option value="help">help</option>
                                    <option value="license">license</option>
                                    <option value="next">next</option>
                                    <option value="prev">prev</option>
                                    <option value="search">search</option>
                                    <option value="tag">tag</option>
                                    <option value="ugc">ugc</option>
                                    <option value="noopener">noopener</option>
                                    <option value="noreferrer">noreferrer</option>
                                    <option value="nofollow">nofollow</option>
                                    <option value="external">external</option>
                                    <option value="dofollow">dofollow</option>
                                    <option value="follow">follow</option>
                                    <option value="sponsored">sponsored</option>
                                    <option value="alternate">alternate</option>
                                    <option value="norferrer">norferrer</option>
                                    <option value="no referrer">no referrer</option>
                                    <option value="false">false</option>
                                    <option value="do">do</option>
                                </select>
                                <i class="fas fa-angle-down"></i>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-7 col-md-7 col-12">
                        <div class="form-group check">
                            <label>URL Status</label>
                            <ul>
                                <li>
                                    <input type="radio" id="url-status-all" name="url-status-radio" v-model="searchObj.search_url_client_status" value="">
                                    <label for="url-status-all">All URLs</label>
                                </li>
                                <li>
                                    <input type="radio" id="url-status-active" name="url-status-radio" v-model="searchObj.search_url_client_status" value="1">
                                    <label for="url-status-active">Active URLs</label>
                                </li>
                                <li>
                                    <input type="radio" id="url-status-inactive" name="url-status-radio" v-model="searchObj.search_url_client_status" value="0">
                                    <label for="url-status-inactive">Inactive URLs</label>
                                </li>
                            </ul>
                        </div>
                        <div class="form-group check">
                            <label>Moz DA</label>
                            <ul>
                                <li>
                                    <input type="radio" id="da-below-30" name="moz-da-radio" v-model="searchObj.search_moz" value="da_0">
                                    <label for="da-below-30">Below 30</label>
                                </li>
                                <li>
                                    <input type="radio" id="da-30" name="moz-da-radio" v-model="searchObj.search_moz" value="da_30">
                                    <label for="da-30">30+</label>
                                </li>
                                <li>
                                    <input type="radio" id="da-40" name="moz-da-radio" v-model="searchObj.search_moz" value="da_40">
                                    <label for="da-40">40+</label>
                                </li>
                                <li>
                                    <input type="radio" id="da-50" name="moz-da-radio" v-model="searchObj.search_moz" value="da_50">
                                    <label for="da-50">50+</label>
                                </li>
                                <li>
                                    <input type="radio" id="da-60" name="moz-da-radio" v-model="searchObj.search_moz" value="da_60">
                                    <label for="da-60">60+</label>
                                </li>
                            </ul>
                        </div>
                        <div class="form-group check">
                            <label>URL Match</label>
                            <ul>
                                <li>
                                    <input type="radio" id="url-match-1" name="url-match-radio" v-model="searchObj.search_link_found" value="1">
                                    <label for="url-match-1">Yes</label>
                                </li>
                                <li>
                                    <input type="radio" id="url-match-0" name="url-match-radio" v-model="searchObj.search_link_found" value="0">
                                    <label for="url-match-0">No</label>
                                </li>
                            </ul>
                        </div>
                        <!-- <div class="form-group check">
                            <label>Text Match</label>
                            <ul>
                                <li>
                                    <input type="radio" id="text-match-1" name="text-match-radio" v-model="searchObj.search_text_found" value="1">
                                    <label for="text-match-1">Yes</label>
                                </li>
                                <li>
                                    <input type="radio" id="text-match-0" name="text-match-radio" v-model="searchObj.search_text_found" value="0">
                                    <label for="text-match-0">No</label>
                                </li>
                            </ul>
                        </div> -->
                        <div class="form-group check">
                            <label>Google Index</label>
                            <ul>
                                <li>
                                    <input type="radio" id="google-index-1" name="google-index-radio" v-model="searchObj.search_google_index" value="1">
                                    <label for="google-index-1">Yes</label>
                                </li>
                                <li>
                                    <input type="radio" id="google-index-0" name="google-index-radio" v-model="searchObj.search_google_index" value="0">
                                    <label for="google-index-0">No</label>
                                </li>
                            </ul>
                        </div>
                        <div class="form-group check" v-if="sourceFilterOptions.length">
                            <label>Sources</label>
                            <ul>
                                <li :value="tag" v-for="(tag, index) in sourceFilterOptions" :key="index">
                                    <input type="radio" :id="'tag' + index" name="tags" v-model="searchObj.search_source" :value="tag">
                                    <label :for="'tag' + index">{{ tag }}</label>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="btns">
                    <button class="primary-btn primary-bg" @click.prevent="updateSearchFilter">Filter</button>
                    <button class="primary-btn primary-transparent-bg" v-if="getComputedSearchFilters" @click.prevent="clearSearchFilters">Clear</button>
                </div>
            </div>
        </ProjectFilter>

        <!-- Notification(Project Errors) Modal Start -->
        <NotificationModal modalId="project-error-modal" type="error" :verticalCentered="true" dataBackDrop="static" :dataKeyboard="true" :showCloseBtn="false">
            <div v-html="apiError"></div>
            <div class="modal-btns">
                <router-link class="primary-btn primary-bg" :to="{name:'dashboard'}">Go To Dashboard</router-link>
            </div>
        </NotificationModal>

        <!-- Notification(Links Errors) Modal Start -->
        <NotificationModal modalId="links-error-modal" type="error" :verticalCentered="true" dataBackDrop="static" :dataKeyboard="true" :showCloseBtn="false">
            <div v-html="apiError"></div>
            <div class="modal-btns">
                <router-link class="primary-btn primary-bg" :to="`/add-links/${domainData.projectId}`">Add Links</router-link>
            </div>
        </NotificationModal>

        <!-- Add Tags Modal Start -->
        <ModalComponent modalId="add-tag-modal" modalClass="form-modal" title="Add New Source" :verticalCentered="true" dataBackDrop="static" :dataKeyboard="true" :showCloseBtn="true">
            <p>Fill out the below field, you can add/edit source.</p>
            <div class="row">
                <div class="col-lg-12 col-12">
                    <div class="form-group">
                        <label>Add/Edit Source</label>
                        <div class="d-relative">
                            <multiselect 
                                v-model="source" 
                                :options="sourceOptions" 
                                :taggable="true" 
                                @input="event => source = event.target.value"
                                @tag="addSource"
                                placeholder="Search or add new source"
                            >
                            </multiselect>
                        </div>
                    </div>  
                </div>
            </div>
            <div class="modal-btns">
                <button class="primary-btn primary-bg submit-btn" type="submit" @click.prevent="addSource()" ref="addSourceBtn">
                    <span><i class="fas fa-save"></i> Submit</span>
                    <i class="fas fa-spinner fa-spin loading"></i>
                </button>
            </div>
        </ModalComponent>

        <!-- All Anchor Text Modal Start -->
        <ModalComponent modalId="all-anchor-text-modal" title="Anchor Spread" :verticalCentered="true" dataBackDrop="static" :dataKeyboard="true" :showCloseBtn="true">
            <div class="scroller">
                <TableComponent
                    :rows="getComputedAnchorSpreadRows"
                    :columns="anchorSpreadColumns"
                    @tableEvent="anchorSpreadTableEvent"
                />
            </div>
        </ModalComponent>

        <!-- Link Logs Modal -->
        <ModalComponent modalId="link-logs" title="Link Logs" :verticalCentered="true" dataBackDrop="static" :dataKeyboard="true" :showCloseBtn="true">
            <div class="scroller">
                <TableComponent
                    :rows="linkLogsRows"
                    :columns="linkLogsColumns"
                />
                <AlertComponent v-if="!linkLogsRows.length" type="warning" :show-close-btn="false">
                    No Logs Available
                </AlertComponent>
            </div>
        </ModalComponent>

         <!-- Cancel Subscription Confirm Modal Start -->
        <NotificationModal modalId="remove-source-confirm-modal" type="question" :verticalCentered="true" dataBackDrop="static" :dataKeyboard="true" :showCloseBtn="false">
            <div>
                <h4>Are You Sure?</h4>
                <p>This source will be permanently deleted.</p>
            </div>
            <div class="modal-btns">
                <button class="primary-btn primary-bg submit-btn" @click.prevent="removeSource">
                    <span>Remove</span>
                    <i class="fas fa-spinner fa-spin loading"></i>
                </button>
                <a href="#" class="primary-btn primary-transparent-bg" data-bs-dismiss="modal" aria-label="Close">Cancel</a>
            </div>
        </NotificationModal>

    </section>
</template>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>