<script setup>
import { computed, onMounted, ref } from "vue";
import { RouterLink } from "vue-router";
import LoaderComponent from "@/components/LoaderComponent.vue";
import DashboardBoxComponent from "@/components/DashboardBoxComponent.vue";
import ProjectThumbnailWrapper from "@/components/projectThumbnailComponents/ProjectThumbnailWrapper.vue";
import ProjectThumbnailLoader from "@/components/loader-components/ProjectThumbnailLoader.vue";
import AlertComponent from "@/components/AlertComponent.vue";
import { useStore } from "vuex";
// import { useToast } from 'vue-toastification';

// Toastification 
// const toast = useToast();

// Vuex Store
const store = useStore();

// Reactive Variables
let loading = ref(true);
const data = ref({
    anchor_text_count: '',
    back_links_count: '',
    domain_count: '',
    p_name: '',
    projects: [],
});

// Methods
function numberFormatter(num){
    const formatter = new Intl.NumberFormat("en-GB", {
        style: "currency",
        currency: "USD", 
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
    });
    return formatter.format(num).replace('US$','');
}
async function getAllDomains(){
    try{
        loading.value = true;
        let res = await store.dispatch('Domain/getAllDomainDataAction');
        if(res.http_status_code === 200){
            if(res.data.links){
                data.value.projects = res.data.links;
            }
            data.value.anchor_text_count = res.data.anchor_text_count;
            data.value.back_links_count = res.data.back_links_count;
            data.value.domain_count = res.data.domain_count;
            data.value.p_name = res.data.p_name;
        }
    }catch(e){
        // if(e.code !== "ERR_NETWORK"){
        //     toast.error('Something unexpected happened');
        // }
    }finally{
        setTimeout(()=>{
            loading.value = false;
        },1000)
    }
}

// Computed Properties
const packageData = computed(()=>{
    return store.getters['Packages/getPackageData'];
});
const getDashboardBoxData = computed(()=>{
    let pkgName = '';
    if(data.value.p_name === 'Free'){
        pkgName = data.value.p_name;
    }else if(packageData.value.is_trial && packageData.value.is_trial_exp){
        pkgName = 'NA';
    }else if(!packageData.value.is_trial && packageData.value.is_pkg_exp){
        pkgName = 'NA';
    }else if(data.value.p_name === ''){
        pkgName = 'NA';
    }

    return{
        domains: data.value.domain_count ? numberFormatter(data.value.domain_count) : '0',
        backLinks: data.value.back_links_count ? numberFormatter(data.value.back_links_count) : '0',
        anchorText: data.value.anchor_text_count ? numberFormatter(data.value.anchor_text_count) : '0',
        packageName: pkgName,
    }
});

// Life Cycle Methods
onMounted(()=>{
    getAllDomains();
});

</script>

<template>
    <section class="dashboard-agency-view dashboard-view">
        <LoaderComponent :isLoading="loading" />

        <div class="dashboard-agency-heading">
            <div class="row align-items-center justify-content-between">
                <div class="col-lg-6 col-md-8 col-6">
                    <h2>Dashboard</h2>
                </div>
                <div class="col-lg-6 col-md-4 col-6">
                    <div class="dashboard-agency-heading-btn text-end">
                        <router-link
                            :to="{ name: 'add-project' }"
                            class="primary-btn primary-bg"
                            >Add Project</router-link
                        >
                    </div>
                </div>
            </div>
        </div>

        <div class="dashboard-boxes">
            <div class="row gx-5">
                <div class="col-lg-3 col-md-3 col-6">
                    <DashboardBoxComponent
                        dashboardIcon="dashboard-icon-1.svg"
                        :dashboardCount="getDashboardBoxData.domains"
                        dashboardText="Projects"
                    ></DashboardBoxComponent>
                </div>
                <div class="col-lg-3 col-md-3 col-6">
                    <DashboardBoxComponent
                        dashboardIcon="dashboard-icon-2.svg"
                        :dashboardCount="getDashboardBoxData.backLinks"
                        dashboardText="Backlinks"
                    ></DashboardBoxComponent>
                </div>
                <div class="col-lg-3 col-md-3 col-6">
                    <DashboardBoxComponent
                        dashboardIcon="dashboard-icon-3.svg"
                        :dashboardCount="getDashboardBoxData.anchorText"
                        dashboardText="Anchor Text"
                    ></DashboardBoxComponent>
                </div>
                <div class="col-lg-3 col-md-3 col-6">
                    <DashboardBoxComponent
                        dashboardIcon="dashboard-icon-4.svg"
                        :dashboardCount="getDashboardBoxData.packageName === 'Free' ? 'EARLY BIRD' : getDashboardBoxData.packageName"
                        dashboardText="Package"
                    >
                    </DashboardBoxComponent>
                </div>
            </div>
        </div>

        <div class="dashboard-project-list">
            <div class="primary-heading">
                <h3>Recent Projects</h3>
            </div>
            
            <div class="listing-wrapper" v-if="loading">
                <ProjectThumbnailLoader />
                <ProjectThumbnailLoader />
                <ProjectThumbnailLoader />
            </div>
            <div class="listing-wrapper" v-if="data.projects && data.projects.length > 0 && !loading">
                <ProjectThumbnailWrapper v-for="project in data.projects" :key="project.id" :id="project.project_id" :data="project" :detailView="true"/>    
            </div>
            <AlertComponent v-if="data.projects.length === 0 && !loading" type="warning" :show-close-btn="false">
                You haven't added any projects yet. <router-link to="/add-project">Click Here</router-link> to add a project and start Link Tracking.
            </AlertComponent>
        </div>
    </section>

</template>

<style></style>
