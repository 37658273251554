<script setup>
import {
    ref,
    toRefs,
    defineEmits,
    watchEffect,
    onUnmounted,
    onMounted,
    defineProps,
} from "vue";
import { useRoute } from "vue-router";
import moment from 'moment';

// Vue Routes
const route = useRoute();

// Props
const props = defineProps({
    isShow: Boolean,
    showOldData: Boolean,
    data: Object,
});

// Emits
const emit = defineEmits(["closeSideBar", 'customEvent']);

// Reactive States
const detailSideBar = ref(null);
const detailSideBarBody = ref(null);
const sidebarTop = ref(null);
const sidebarBottom = ref(null);
let { isShow } = toRefs(props);
let interval = ref(null);

// Methods
function close() {
    emit("closeSideBar");
}
function closeSideBarOnClick(e) {
    if (
        detailSideBar.value &&
        detailSideBar.value.classList.contains("show") &&
        !detailSideBarBody.value.contains(e.target)
    ) {
        close();
    }
}
function sideMenuHeight(){
    const windowHeight = window.innerHeight;
    const sideMenuLogoHeight = sidebarTop.value.offsetHeight;
    let newHeight = windowHeight - sideMenuLogoHeight - 50;

    sidebarBottom.value.style.height = `${newHeight}px`;
}
function updatePlacementData(data){
    emit('customEvent', data);
}

// Life Cycle Function
onMounted(() => {
    sideMenuHeight();
    window.addEventListener("resize", sideMenuHeight);
    window.addEventListener("click", closeSideBarOnClick);
});
onUnmounted(() => {
    window.removeEventListener("click", closeSideBarOnClick);
    window.removeEventListener("resize", sideMenuHeight);
});

// Watcher
const watcher = watchEffect(() => {
    if (interval.value) {
        clearInterval(interval.value);
    }

    if (isShow.value && detailSideBar.value) {
        detailSideBar.value.style.display = "block";
        interval.value = setInterval(() => {
            detailSideBar.value.classList.add("show");
        }, 500);
    } else if (!isShow.value && detailSideBar.value) {
        detailSideBar.value.classList.remove("show");
        detailSideBar.value.classList.add("hide");
        interval.value = setInterval(() => {
            if (detailSideBar.value) {
                detailSideBar.value.style.display = "none";
                detailSideBar.value.classList.remove("hide");
            } else {
                watcher();
            }
        }, 1000);
    }
});
</script>

<template>
    <div
        class="overview-link-sidebar"
        ref="detailSideBar"
        style="display: none"
    >
        <div class="overview-link-sidebar-wrapper" ref="detailSideBarBody">
            <div class="overview-link-sidebar-top" ref="sidebarTop">
                <button
                    class="overview-link-sidebar-close"
                    @click.prevent="close"
                >
                    <i class="fas fa-times"></i>
                </button>
                <a :href="data.placement_url" target="_blank"><h3>{{ data.placement_url }} <i class="fas fa-external-link-alt"></i></h3></a>
            </div>
            <div class="overview-link-sidebar-bottom" ref="sidebarBottom">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-12">
                        <div class="link-box link-box--liveurl">
                            <label>Live URL</label>
                            <div class="link-box-info">
                                <a :href="data.live_url_link" target="_blank"
                                    >{{ data.live_url_link }}
                                    <i class="fas fa-external-link-alt"></i
                                ></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-12">
                        <div class="link-box link-box--liveurl">
                            <label>Anchor Text</label>
                            <div class="link-box-info">
                                <p>{{ data.placement_anchor_text ? data.placement_anchor_text : '---' }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-12" v-if="data.new_placement_anchor_url">
                        <div class="link-box link-box--liveurl">
                            <label>Current Destination URL</label>
                            <div class="link-box-info">
                                <p>{{ data.new_placement_anchor_url }}</p>
                                <button v-if="route.name === 'project'" @click.prevent="(event) => updatePlacementData({type: 'updatePlacementData', column: 'new_placement_anchor_url', value: data.new_placement_anchor_url, lpm_id: data.id}, event)" class="primary-btn primary-bg"><span>Update</span><i class="fas fa-spinner fa-spin"></i></button>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-12" v-if="data.new_placement_anchor_text">
                        <div class="link-box link-box--liveurl">
                            <label>Current Anchor Text</label>
                            <div class="link-box-info">
                                <p>{{ data.new_placement_anchor_text }}</p>
                                <button v-if="route.name === 'project'" @click.prevent="(event) => updatePlacementData({type: 'updatePlacementData', column: 'new_placement_anchor_text', value: data.new_placement_anchor_text, lpm_id: data.id}, event)" class="primary-btn primary-bg"><span>Update</span><i class="fas fa-spinner fa-spin"></i></button>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-12" v-if="data.placement_rel">
                        <div class="link-box link-box--liveurl">
                            <label>Rel</label>
                            <div class="link-box-info">
                                <p>{{ data.placement_rel }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-12">
                        <div class="link-box link-box--status link-box--liveurl">
                            <label>HTTP Status</label>
                            <div class="link-box-info">
                                <div class="status-box" 
                                    :class="{'status-box-0':!data.live_url_http_status || data.live_url_http_status >= 400,
                                            'status-box-1': data.live_url_http_status >= 200 && data.live_url_http_status < 300,
                                            'status-box-2': data.live_url_http_status >= 300 && data.live_url_http_status < 400,       
                                    }">
                                    Status
                                    <span v-if="!data.live_url_http_status"><i class="fas fa-spinner fa-spin loading"></i></span>
                                    <span v-else>{{ data.live_url_http_status }}</span>
                                </div>
                                <p v-if="data.live_url_http_status_reason"><span>Reason:</span> {{ data.live_url_http_status_reason }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-12" v-if="showOldData && data.last_http_status && data.last_http_status !== data.live_url_http_status">
                        <div class="link-box link-box--status link-box--liveurl">
                            <label>Old HTTP Status</label>
                            <div class="link-box-info">
                                <div class="status-box" 
                                    :class="{'status-box-0':!data.last_http_status || data.last_http_status >= 400,
                                            'status-box-1': data.last_http_status >= 200 && data.last_http_status < 300,
                                            'status-box-2': data.last_http_status >= 300 && data.last_http_status < 400,       
                                    }">
                                    Status
                                    <span v-if="!data.last_http_status"><i class="fas fa-spinner fa-spin loading"></i></span>
                                    <span v-else>{{ data.last_http_status }}</span>
                                </div>
                                <p v-if="data.last_live_url_http_status_reason"><span>Reason:</span> {{ data.last_live_url_http_status_reason }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-12">
                        <div class="link-box link-box--tags">
                            <label>Source <i class="fas fa-tags"></i></label>
                            <ul class="tags" v-if="data.source">
                                <li>{{ data.source }}</li>
                            </ul>
                            <ul class="tags" v-else>
                                <li>No source available</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-4 col-md-4 col-6">
                        <div class="link-box-sm">
                            <p>URL Match</p>
                            <span v-if="!data.is_queue" :class="{'yes': data.is_placement_anchor_url_matched, 'no': !data.is_placement_anchor_url_matched}">{{ data.is_placement_anchor_url_matched ? 'Yes' : 'No' }} <small v-if="showOldData && data.hasOwnProperty('last_url_match') && data.last_url_match !== null && data.last_url_match !== data.is_placement_anchor_url_matched">{{ data.last_url_match ? 'Yes' : 'No' }}</small></span>
                            <span v-else><i class="fas fa-spinner fa-spin loading"></i></span>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-6">
                        <div class="link-box-sm link-box-sm--googleindex">
                            <p>Google Index</p>
                            <span class="google-index" v-if="!data.is_queue" :class="{'yes':data.is_google_index, 'no': !data.is_google_index}">{{ data.is_google_index ? 'Yes' : 'No' }} <small v-if="showOldData && data.hasOwnProperty('last_gi') && data.last_gi !== null && data.last_gi !== data.is_google_index">{{ data.last_gi ? 'Yes' : 'No' }}</small></span>
                            <span v-else><i class="fas fa-spinner fa-spin loading"></i></span>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-6">
                        <div class="link-box-sm">
                            <p>MOZ DA</p>
                            <span v-if="!data.is_queue">{{ data.live_url_da_score ? data.live_url_da_score : 0 }} <small v-if="showOldData && data.hasOwnProperty('last_da') && data.last_da !== null && data.last_da !== data.live_url_da_score">{{ data.last_da }}</small></span>
                            <span v-else><i class="fas fa-spinner fa-spin loading"></i></span>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-6">
                        <div class="link-box-sm link-box-sm--spamscore">
                            <p>Spam Score</p>
                            <span v-if="!data.is_queue">{{ data.live_url_spam_score <= 0 ? '1%' : data.live_url_spam_score + '%' }} <small v-if="showOldData && data.hasOwnProperty('last_spam') && data.last_spam !== null && data.last_spam !== data.live_url_spam_score">{{ data.last_spam }}%</small></span> 
                            <span v-else><i class="fas fa-spinner fa-spin loading"></i></span>
                        </div>
                    </div>
                </div>
                <div class="last-crawled-date">
                    <p>Last Crawled:</p>
                    <span>{{ data.last_crawl_date ? moment(data.last_crawl_date).format('MM-DD-YYYY') : 'N/A' }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<style></style>
