<template>
  <div class="project-thumbnail-loader animate-empty">
        <div class="project-thumbnail-top">
            <div class="project-thumbnail-select"></div>
            <div class="project-thumbnail-top-btn"></div>  
        </div>
        <div class="project-thumbnail-bottom">
            <div class="row gx-5">
                <div class="col-xl-2 col-lg-4 col-md-4 col-12">
                    <div class="column column-list">
                        <div class="heading"></div>
                        <ul>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                        </ul>
                    </div>
                </div>
                <div class="col-xl-2 col-lg-4 col-md-4 col-12">
                    <div class="column column-list">
                        <div class="heading"></div>
                        <ul>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                        </ul>
                    </div>
                </div>
                <div class="col-xl-2 col-lg-4 col-md-4 col-12">
                    <div class="column column-graph">
                        <div class="heading"></div>
                        <div class="content"></div>
                        <div class="graph"></div>
                    </div>
                </div>
                <div class="col-xl-2 col-lg-4 col-md-4 col-12">
                    <div class="column column-graph">
                        <div class="heading"></div>
                        <div class="content"></div>
                        <div class="graph"></div>
                    </div>
                </div>
                <div class="col-xl-2 col-lg-4 col-md-4 col-12">
                    <div class="column column-graph">
                        <div class="heading"></div>
                        <div class="content"></div>
                        <div class="graph"></div>
                    </div>
                </div>
                <div class="col-xl-2 col-lg-4 col-md-4 col-12">
                    <div class="column column-list">
                        <div class="heading"></div>
                        <ul>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
    .fade-enter-active{
        transition: opacity 1000ms ease-in-out;
    }
    .fade-enter{
        opacity: 0;
    }
    .fade-leave-active{
        display: none;
    }
</style>