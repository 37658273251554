import { AuthServices } from '@/services/authServices';
import url from '@/prod.js';
export default {
    namespaced: true,

    state: {
        user:{
          data: {
          },
          token: null,
        }
    },

    getters: {
        getUser: state => state.user.data,
        isVerficationEmailSent: state => state.user.data.isEmailSent,
        isEmailVerifiedGetter: state => state.user.data.isEmailVerified,
        authToken: state => state.user.token,
        isAuthenticated: state => state.user.token !== null,
    },

    mutations: {

        // Set User State & Token
        setUser(state, payload){
            state.user.token = payload.token;
            state.user.data['name'] = payload.name;
            state.user.data['email'] = payload.email;
            state.user.data['profileImage'] =  payload.user_profile_img ? `${url.baseUrl}${payload.user_profile_img}` : require('@/assets/images/user-profile-img.png');
            state.user.data['isEmailVerified'] = payload.is_email_verified;
            state.user.data['isEmailSent'] = payload.is_email_sent;
            state.user.data['isEmailNotified'] = payload.is_email_verified;
            state.user.data['pro_id'] = payload.project_id;
        },
        
        // Update User Profile Image 
        userProfileImageUpdateMutation(state, payload){
            state.user.data.profileImage = payload;
        },

        // Update User Profile Data
        updateUserDataMutation(state,payload){
            state.user.data.name = payload.name;
            state.user.data.email = payload.email;
        },

        // Logout User
        logoutUser(state){
            state.user.data = {};
            state.user.token = null;
            setTimeout(()=>{
                window.localStorage.clear();
            }, 500);
        },
    
        // Is Email Verified Mutation
        isEmailVerifiedMutation(state, payload){
            state.user.data.isEmailVerified = payload;
        },

        // Email Verfication Mail Mutation
        emailVerficationMailMutation(state){
            state.user.data.isEmailSent = true;
        },
    
        // Update Email Verificatin Mail Mutation
        updateEmailVerificationMutation(state){
            state.user.data.isEmailVerified = true;
        },

        // Email Notified Mutation
        emailNotifiedMutation(state,payload){
            state.user.data.isEmailNotified = payload;
        },

        // Update Recent Project ID
        updateRecentProjectIDMutation(state,payload){
            state.user.data.pro_id = payload;
        }
    },

    actions: {

        // Login Action
        async loginAction({ commit }, payload){
            let res = await AuthServices.loginAPI(payload);
            let data = res.data.data;
            commit('setUser', data);
            return data; 
        },
    
        // SignUp Action
        async signUpAction({ commit }, payload){
            let res = await AuthServices.signupAPI(payload);
            let data = res.data.data;      
            commit('setUser', data);
            return data;
        },
    
        // Forget Password Action
        async forgetPasswordAction(_, payload){
            let res = await AuthServices.forgetPasswordAPI(payload);
            let data = res.data.data;  
            return data;
        },
    
        // Update Password Action
        async updatePasswordAction(_, payload){
            let res = await AuthServices.updatePasswordAPI(payload);
            let data = res.data.data;  
            return data;
        },
    
        // Logout Action
        async logoutAction({commit}){
            let res = await AuthServices.logoutAPI();
            let data = res.data;
            commit('logoutUser');
            return data;
        },
    
        // Send Email Verification Mail Action
        async emailVerficationMailAction({commit}, payload){
            let res = await AuthServices.sendVerficationEmail(payload);
            let data = res.data;
            commit('emailVerficationMailMutation')
            return data;
        },
    
        // Update Email Verificatin Mail Action
        async updateEmailVerificationAction({commit},payload){
            let res = await AuthServices.updateVerficationEmail(payload);
            let data = res.data;
            if(data.success){
                commit('updateEmailVerificationMutation');
            }
            return data;
        },
    
        // Auth Google Action
        async googleAuthAction(){
            let res = await AuthServices.googleAuthAPI();
            let data = res.data;
            return data;
        },

        // Is Email Verified Action
        async isEmailVerifiedAction({commit}){
            let res = await AuthServices.isEmailVerified();
            let data = res.data;
            if(data.success){
                commit('isEmailVerifiedMutation', data.is_email_verified);
            }
            return data;
        },

        // Email Notified Action
        emailNotifiedAction({commit}){
            commit('emailNotifiedMutation',true);
        },

        // Activity Log
        async activityLogAction(_, payload){
            await AuthServices.activityLog(payload);
        }

    }
}