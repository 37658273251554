<script setup>

import { ref, onMounted, computed} from 'vue';
import LoaderComponent from "@/components/LoaderComponent.vue";
import AlertComponent from "@/components/AlertComponent.vue";
import TableComponent from "@/components/tableComponents/TableComponent.vue"
import PerPageComponent from "@/components/tableComponents/PerPageComponent.vue"
import TableCountTextComponent from '@/components/tableComponents/TableCountTextComponent.vue';
import ProjectFilter from '@/components/projectDetailComponents/ProjectFilters.vue';
import Pagination from 'laravel-vue-pagination';

import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

// Vue Router
const router = useRouter();

// Vuex Store
const store = useStore();

// Reactive Variables
let loading = ref(false);
let tableLoading = ref(false);
let filterSidebarToggle = ref(false);
const rows = ref([]);
const columns = ref([
    { label: "Projects", field: "domain_link", html: true },
    { label: "HTTP Status", field: "domain_http_status", html: true, sortable: true, tooltip: 'Indicates the current status of the monitored link. 200 represents an active backlink.',},
    { label: "Current DA", field: "da_score", html: true, sortable: true, tooltip: 'Domain Authority, a score from 0 to 100 rating the overall presence of the Live URL Website. The stats are taken from Moz.', },
    { label: "Spam Score", field: "spam_score", html: true, sortable: true, tooltip: 'The probability of the Live URL being spammy.'},
    { label: "Google Index", field: "is_google_index", html: true, sortable: true, tooltip: 'Check if Google has indexed the Live URL page with the Domain.' },
    { label: "Total Links", field: "total_links", html: false, sortable: true,},
    { label: "Action", field: "action", html: false },
]);
const paginationData = ref(null);
const search = ref({
    page: 1,
    limit: 10,
    order:'desc',
    sort: '',
    search_links: '',
    search_moz: '',
    search_spam: '',
    search_google_index: '',
    search_http_status: '',
});

// Methods
function getStatus(status){
    if(!status || status >= 400){
        return 'status-box-0';
    }else if(status >= 200 && status < 300){
        return 'status-box-1'
    }else if(status >= 300 && status){
        return 'status-box-2'
    }else{
        return 'status-box-0'
    }
}
function tableEvent(data) {
    if(data.type === 'view'){
        router.push(`/projects/${data.id}`);
    }
}
function tableColumnSort(data){
    search.value.order = data.order;
    search.value.sort = data.sort;

    getProjects(false);    
}
function changePerPage(){
    getProjects(false);
}
function paginateData(page){
    search.value.page = page
    getProjects(false);
}

function toggleFilterSidebar() {
    if(rows.value.length > 0 || getComputedSearchFilters.value){
        filterSidebarToggle.value = filterSidebarToggle.value ? false : true;   
    }
}
function updateSearchFilter(){
    filterSidebarToggle.value = false;
    search.value.page = 1;
    getProjects(false);   
}
function clearSearchFilters(){
    search.value.page = 1;
    search.value.order = 'desc';
    search.value.sort = '';
    search.value.search_links = '';
    search.value.search_moz = '';
    search.value.search_spam = '';
    search.value.search_google_index = '';
    search.value.search_http_status = '';

    getProjects(false);
}

async function getProjects(load){
    try{
        loading.value = load;
        tableLoading.value = true;
        let res = await store.dispatch('Domain/getAllProjectsAction', search.value);
        if(res.success){
            paginationData.value = {
                data: res.data.data,
                links: res.data.links,
                meta: {
                    current_page: res.data.current_page,
                    from: res.data.from,
                    last_page: res.data.last_page,
                    path: res.data.path,
                    per_page: res.data.per_page,
                    to: res.data.to,
                    total: res.data.total,
                }
            }
            rows.value = res.data.data;
        }
    }catch(e){
        if(e.code !== "ERR_NETWORK"){
            console.log(e);
        }
    }finally{
        setTimeout(()=>{
            loading.value = false;
            tableLoading.value = false;
        }, 1000);
    }
}

async function init(){
    await getProjects(true);
}


// Computed Properties
const getComputedRows = computed(()=>{
    if(rows.value && rows.value.length > 0){
        return rows.value.map(row => {
            return {
                domain_link: `<a href="${row.domain_link}" target="_blank"
                                >${row.domain_link ? row.domain_link : '--' }
                                <i class="fas fa-external-link-alt"></i>
                              </a>`,
                domain_http_status: `<div class="status-box ${getStatus(row.domain_http_status)}" data-bs-toggle="tooltip" data-bs-placement="top" title="${row.domain_http_status_reason}">
                                        Status
                                        <span>${row.domain_http_status}</span>
                                    </div>`,
                da_score: `${row.da_score}<span style="color: ${(row.da_score - row.domain_first_da_score) < 0 ? '#ff0e52' : '#38cb89'}"><i class="fas ${(row.da_score - row.domain_first_da_score) < 0 ? 'fa-caret-down' : 'fa-caret-up'}"></i>  ${Math.abs(row.da_score - row.domain_first_da_score)}</span>`,
                spam_score: `${row.spam_score <= 0 ? '1%' : row.spam_score + '%'} <span style="color: ${(row.spam_score - row.domain_first_spam_score) < 0 ? '#38cb89' : '#ff0e52'}"><i class="fas ${(row.spam_score - row.domain_first_spam_score) < 0 ? 'fa-caret-down' : 'fa-caret-up'}"></i>  ${Math.abs(row.spam_score - row.domain_first_spam_score)}</span>`,
                is_google_index:    `<i class="fab fa-google" style="color:${row.is_google_index ? '#00A66C' : '#F03800'}"></i>`,
                total_links: row.total_links,
                action: [
                    {
                        id: row.project_id,
                        label: "<i class='fas fa-eye'></i>",
                        type: "view",
                        route: "#",
                        target: "",
                        disable: false,
                        tooltip: 'View'
                    },
                ]
            }
        })
    } 

    return null;
});  
const getComputedSearchFilters = computed(()=>{
    let filterCount = 0;
    const filters = {
        search_links: search.value.search_links,
        search_moz: search.value.search_moz,
        search_spam: search.value.search_spam,
        search_google_index: search.value.search_google_index,
        search_http_status: search.value.search_http_status,
    }
    for(let val of Object.values(filters)){
        if(val !== ""){
            filterCount++;
        }
    }

    return filterCount;
});

// Life Cycle Methods
onMounted(() => {
    init();
});

</script>

<template>

    <section class="dashboard-agency-view projects-view">
        <LoaderComponent :isLoading="loading" />

        <div class="primary-heading">
            <div class="row align-items-center justify-content-between">
                <div class="col-lg-6 col-md-8 col-8">
                    <h2>Your Projects</h2>
                </div>
                <div class="col-lg-6 col-md-4 col-4">
                    <div class="dashboard-agency-heading-btn text-md-end">
                        <router-link
                            :to="{ name: 'add-project' }"
                            class="primary-btn primary-bg"
                            >Add Project</router-link
                        >
                    </div>
                </div>
            </div>
        </div>

        <div class="dashboard-agency-table domain-link-table">
            <div class="row align-items-center">
                <div class="col-lg-12 col-md-12 col-12">
                    <div class="table-filter">
                        <button class="filter-btn" @click.prevent="toggleFilterSidebar" >
                            Filter <i class="fas fa-sort-amount-down"></i>
                        </button>
                        <button class="filter-btn" @click.prevent="clearSearchFilters" v-if="getComputedSearchFilters">
                            Clear({{ getComputedSearchFilters}}) <i class="fas fa-times"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="table-wrapper">
                <PerPageComponent 
                    v-model="search.limit" 
                    :options="[10,25,50,100]"
                    @changePerPage="changePerPage"
                />
                <TableComponent
                    :rows="getComputedRows"
                    :columns="columns"
                    :tableLoading="tableLoading"
                    :limit="search.limit"
                    :sort="search.sort"
                    :order="search.order"
                    @tableEvent="tableEvent"
                    @columnSortEvent="tableColumnSort"
                />
                <TableCountTextComponent v-if="paginationData && rows.length > 0" :from="paginationData.meta.from" :to="paginationData.meta.to" :total="paginationData.meta.total" />
                <AlertComponent v-if="rows.length === 0 && !loading" type="warning" :show-close-btn="false">Projects not found.</AlertComponent>
            </div>
            <div class="table-pagination" v-if="paginationData && paginationData.meta.last_page > 0">
                <Pagination :data="paginationData" :limit="5" @pagination-change-page="paginateData"></Pagination>
            </div>
        </div>

        <ProjectFilter :is-show="filterSidebarToggle" @close-side-bar="toggleFilterSidebar" other-class="project-filter-sidemenu">
            <div class="agency-form-2">
                <div class="row">

                    <div class="col-lg-5 col-md-5 col-12">
                        <div class="form-group">
                            <label>Project Name</label>
                            <input type="text" class="form-control" v-model="search.search_links" placeholder="eg. https://projectname.com">
                        </div>
                        <div class="form-group">
                            <label>HTTP Status Code</label>
                            <div class="d-relative">
                                <select class="form-control" v-model="search.search_http_status">
                                    <option value="">Select Status Code</option>
                                    <option value="http_200">200+</option>
                                    <option value="http_300">300+</option>
                                    <option value="http_400">400+</option>
                                    <option value="http_500">500+</option>
                                    <option value="http_600">600+</option>
                                    <option value="http_800">800+</option>
                                </select>
                                <i class="fas fa-angle-down"></i>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Spam Score</label>
                            <div class="d-relative">
                                <select class="form-control" v-model="search.search_spam">
                                    <option value="">Select any option</option>
                                    <option value="0">0% +</option>
                                    <option value="10">10% +</option>
                                    <option value="20">20% +</option>
                                    <option value="30">30% +</option>
                                    <option value="40">40% +</option>
                                    <option value="50">50% +</option>
                                    <option value="60">60% +</option>
                                    <option value="70">70% +</option>
                                    <option value="80">80% +</option>
                                    <option value="90">90% +</option>
                                </select>
                                <i class="fas fa-angle-down"></i>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-7 col-md-7 col-12">
                        <div class="form-group check">
                            <label>Moz DA</label>
                            <ul>
                                <li>
                                    <input type="radio" id="da-below-30" name="moz-da-radio" v-model="search.search_moz" value="da_0">
                                    <label for="da-below-30">Below 30</label>
                                </li>
                                <li>
                                    <input type="radio" id="da-30" name="moz-da-radio" v-model="search.search_moz" value="da_30">
                                    <label for="da-30">30+</label>
                                </li>
                                <li>
                                    <input type="radio" id="da-40" name="moz-da-radio" v-model="search.search_moz" value="da_40">
                                    <label for="da-40">40+</label>
                                </li>
                                <li>
                                    <input type="radio" id="da-50" name="moz-da-radio" v-model="search.search_moz" value="da_50">
                                    <label for="da-50">50+</label>
                                </li>
                                <li>
                                    <input type="radio" id="da-60" name="moz-da-radio" v-model="search.search_moz" value="da_60">
                                    <label for="da-60">60+</label>
                                </li>
                            </ul>
                        </div>
                         <div class="form-group check">
                            <label>Google Index</label>
                            <ul>
                                <li>
                                    <input type="radio" id="google-index-1" name="google-index-radio" v-model="search.search_google_index" value="1">
                                    <label for="google-index-1">Yes</label>
                                </li>
                                <li>
                                    <input type="radio" id="google-index-0" name="google-index-radio" v-model="search.search_google_index" value="0">
                                    <label for="google-index-0">No</label>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="btns">
                    <button class="primary-btn primary-bg" @click.prevent="updateSearchFilter">Filter</button>
                    <button class="primary-btn primary-transparent-bg" v-if="getComputedSearchFilters" @click.prevent="clearSearchFilters">Clear</button>
                </div>
            </div>
        </ProjectFilter>

    </section>

</template>
