<script setup>
import { computed, onMounted, ref, watch, onUnmounted } from "vue";
import { RouterLink, useRoute, useRouter } from "vue-router";
import Popper from "vue3-popper";
import LoaderComponent from "@/components/LoaderComponent.vue";
import ProgressBarAlert from "@/components/loader-components/ProgressBarAlert.vue";
import ProjectThumbnailLoader from "@/components/loader-components/ProjectThumbnailLoader.vue";
import ProjectThumbnailWrapper from "@/components/projectThumbnailComponents/ProjectThumbnailWrapper.vue";
import ModalComponent from "../../components/modalComponents/ModalComponent.vue";
import NotificationModal from "@/components/modalComponents/NotificationModal.vue";
import { Modal } from 'bootstrap';
import Papa from 'papaparse';
import { useStore } from "vuex";
import { useToast } from "vue-toastification";

// Tostification
const toast = useToast();

// Vue Routes
const route = useRoute();
const router = useRouter();

// Vue Vuex
const store = useStore();

// Reactive Variables
let isLinksTracking = ref(false);
let interval = ref(null);
const loading = ref(false);
let apiError = ref('');
let packageError = ref('');
let isQueue = ref(1);
const domainData = ref({});
const links = ref([
    {
        id: 0,
        destinationUrl: "",
        liveUrl: "",
        // anchorText: "",
    },
]);
const errors = ref([
    {
        id: 0,
        destinationUrl: "",
        liveUrl: "",
        // anchorText: "",
    },
]);
const CSVFile = ref({
    file: false,
    fileName: '',
    data: [],
    error: ''
});
const bulkorderInput = ref(null);

// Methods
function downloadFile(filePath) {
  var link = document.createElement('a');
  link.href = filePath;
  link.download = filePath.substr(filePath.lastIndexOf('/') + 1);
  link.click();
}
function toggleLoadingBtn(btn){
    if(!btn.classList.contains('loading')){
        btn.classList.add('loading');
        btn.disabled = true;
    }else{
        btn.classList.remove('loading');
        btn.disabled = false;
    }
}
function urlProtocolValidation(url){
    return String(url)
        .toLowerCase().match(/^(http|https)/);
}
function destinationUrlValidation(url){
    // Checking Destination URL must includes Domain URL
        let domainUrl = '';

        // Checking For WWW
        const isWWWW = domainData.value.domain_data.domain_link.indexOf('://www.');
        const isHttp = domainData.value.domain_data.domain_link.indexOf('://');
        if(isWWWW >= 0){
            domainUrl = domainData.value.domain_data.domain_link.replace(domainData.value.domain_data.domain_link.slice(0, isWWWW + 6 + 1),'');
        }else if(isHttp >= 0){
            domainUrl = domainData.value.domain_data.domain_link.replace(domainData.value.domain_data.domain_link.substring(0, isHttp + 2 + 1),'');
        }

        if(!url.includes(domainUrl)){
            return 'Destination URL must include domain(project) name.';
        }else{
            return '';
        }
}
function urlValidation(url, isDestinationUrl = false) {
    const isEmailValid = String(url).toLowerCase().match(/^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i);
    if(!urlProtocolValidation(url)){
        return 'Please add HTTP / HTTPS with your URL.'
    }else if (!isEmailValid) {
        return "URL is not valid!";
    }else if (url.length > 200) {
        return "The URL length must be less than 200 characters.";
    }else if(isDestinationUrl){
       return destinationUrlValidation(url);
    }

    return "";
}
function getDomainName(url){
    let newUrl = (new URL(url));
    let domain = (newUrl.hostname).replace(/(https?:\/\/)?(www.)?/i, '');
    return domain;
}
function validateLinks(){
    links.value.forEach((link, index) => {
        errors.value[index].destinationUrl = '';
        errors.value[index].liveUrl = '';
        // errors.value[index].anchorText = '';
    });

    links.value.forEach((link,index) => {
        if(!link.destinationUrl){
            errors.value[index].destinationUrl = 'The field must be filled.';
        }else{
            errors.value[index].destinationUrl =  urlValidation(link.destinationUrl, true);
        }

        if(!link.liveUrl){
            errors.value[index].liveUrl = 'The field must be filled.';
        }else if(getDomainName(link.liveUrl) === getDomainName(link.destinationUrl)){
            errors.value[index].liveUrl = 'Destination URL and Live URL can not be from the same domain.';
        }else{
            errors.value[index].liveUrl =  urlValidation(link.liveUrl);
        }

        // if(!link.anchorText){
        //     errors.value[index].anchorText = 'The field must be filled.';
        // }else if(link.anchorText.length > 150){
        //     errors.value[index].anchorText = 'The anchor text length must be less than 150 characters.';
        // }
    });

    //return errors.value.filter(error => error.destinationUrl !== '' || error.liveUrl !== '' || error.anchorText !== '').length;
    return errors.value.filter(error => error.destinationUrl !== '' || error.liveUrl !== '').length;
}
// function validateAnchorText(text){
//     if(!text){
//         return "";
//     }else if(text.includes('â€™')){
//         return text.replace('â€™','\'');
//     }else if(text.includes('ï¿½')){
//         return text.replace('ï¿½','\'');
//     }else{
//         return text;
//     }
// }
function addLink(obj = '') {
    let id = links.value.length === 0 ? 0 : links.value[links.value.length - 1].id + 1;
    if(obj && links.value.length === 1 && !links.value[0].liveUrl /*&& !links.value[0].anchorText*/ ){
        id = links.value[0].id;
        links.value[0].id = id;
        links.value[0].destinationUrl = obj.destinationUrl;
        links.value[0].liveUrl = obj.liveUrl;
        // links.value[0].anchorText = validateAnchorText(obj.anchorText);
    }
    else{
        links.value.push({
            id: id,
            destinationUrl: obj === '' ? '' : obj.destinationUrl,
            liveUrl: obj === '' ? '' : obj.liveUrl,
            // anchorText: obj === '' ? '' : validateAnchorText(obj.anchorText),
        });
        errors.value.push({
            id: id,
            destinationUrl: "",
            liveUrl: "",
            // anchorText: "",
        });
    }
}
function removeLink(id) {
    const reqLinkId = links.value.findIndex((link) => link.id === id);
    const reqErrorId = errors.value.findIndex((link) => link.id === id);
    if (reqLinkId && reqErrorId) {
        links.value.splice(reqLinkId, 1);
        errors.value.splice(reqErrorId, 1);
    }
}
function copyLink(id) {
    const reqLink = links.value.filter((link) => link.id === id);
    const reqError = errors.value.filter((link) => link.id === id);

    if (reqLink.length > 0 && reqError.length > 0) {
        links.value.push({
            id: links.value[links.value.length - 1].id + 1,
            destinationUrl: reqLink[0].destinationUrl,
            liveUrl: reqLink[0].liveUrl,
            // anchorText: reqLink[0].anchorText,
        });
        errors.value.push({
            id: errors.value[errors.value.length - 1].id + 1,
            destinationUrl: "",
            liveUrl: "",
            // anchorText: "",
        });
    }
}
function openBlukOrderModal(){
    let modal = new Modal(document.getElementById('blukorder-modal'));
    modal.show();
}
function uploadCSV(){
    let file = bulkorderInput.value.files[0];
    if(file && (file.type !== 'text/csv' && file.type !== 'application/vnd.ms-excel')){
        CSVFile.value.error = 'Unsupported File Format';
        CSVFile.value.file = false;
        CSVFile.value.fileName = '';
        bulkorderInput.value.value = '';
        return;
    }else if(file && (file.type === 'text/csv' || file.type === 'application/vnd.ms-excel')){
        Papa.parse(file,{
            header:true,
            dynamicTyping:true,
            skipEmptyLines:true,
            keepEmptyRows:false,
            encoding: "ISO-8859-1",

            complete(results) {
                // let headers = results.meta['fields'].filter(header => header !== 'destinationUrl' && header !== 'liveUrl' && header !== 'anchorText');
                let headers = results.meta['fields'].filter(header => header !== 'destinationUrl' && header !== 'liveUrl');
                if(headers.length > 0 || results.meta['fields'].length > 2){
                    CSVFile.value.error = 'Unsupported File Structure';
                    CSVFile.value.file = false;
                    CSVFile.value.fileName = '';
                    bulkorderInput.value.value = '';
                    return;
                }else if(!results.data.length){
                    CSVFile.value.error = 'File cannot be empty.';
                    CSVFile.value.file = false;
                    CSVFile.value.fileName = '';
                    bulkorderInput.value.value = '';
                    return;
                }else if(results.data.length > 500){
                    CSVFile.value.error = 'Maximum Limit exceeded, please upload less than 500 links at a time.';
                    CSVFile.value.file = false;
                    CSVFile.value.fileName = '';
                    bulkorderInput.value.value = '';
                    return;
                }

                CSVFile.value.error = '';
                CSVFile.value.file = true;
                CSVFile.value.fileName = file.name;
                CSVFile.value.data = results.data;
                bulkorderInput.value.value = '';
            }
        });
    }
}
function continueCSV(){
    if(CSVFile.value.data.length > 0){
        CSVFile.value.data.forEach(item => {
            addLink(item);
        });

        let blukModal = document.getElementById('blukorder-modal');
        Modal.getInstance(blukModal).hide();

        setTimeout(()=>{
            bulkorderInput.value.value = '';
            CSVFile.value.file = false;
            CSVFile.value.data = [];
            CSVFile.value.error = '';
            CSVFile.value.fileName = '';
        }, 500);
    }
}
async function getDomainData(id){
    let errorModal = new Modal(document.getElementById('error-modal'));
    try{
        let res = await store.dispatch('Domain/getDomainDataAction', {
            id: id,
            type: localStorage.getItem('pro-a-type') ? localStorage.getItem('pro-a-type') : '', 
        });
        let data = res.data;

        if(res.success){
            domainData.value.project_id = res.project_id;
            domainData.value.domain_name = data.link.domain_data.domain_link;
            domainData.value.domain_data = data.link.domain_data;
            domainData.value.moz_array = data.link.moz_array;
            domainData.value.health_score = data.link.health_score;
            domainData.value.links_with_da = data.link.links_with_da;
            domainData.value.calc_logs = data.link.calc_logs;
            domainData.value.anchor_text_calc = data.link.anchor_text_calc;

            isQueue.value = data.link.domain_data.is_queue;
            if(isQueue.value === 0){
                if(localStorage.getItem('pro-a-type')){
                    localStorage.removeItem('pro-a-type');
                }
                clearInterval(interval.value);
            }
        }else if(!res.success){
            setTimeout(()=>{
                clearInterval(interval.value);
                apiError.value = `<h4>Project not Found<h4><p>Project does not exist or deleted.</p>`;
                errorModal.show();
            }, 1000);
        }
    }catch(e){
        setTimeout(()=>{
            clearInterval(interval.value);
            apiError.value = `<h4>Something Unexpected Occured</h4><p>Something unexpected happened, try again later!</p>`;
            errorModal.show();
        }, 1000);
    }
}
async function trackLinks(e){
    const submitBtn = e.target.querySelector('.submit-btn');
    const validationErrorModal = new Modal(document.getElementById('validation-error-modal'));

    if(packageError.value){
        toast.error(packageError.value);
    }
    // else if(postComputedLinks.value.links.length > packageData.value.li_lt_left){
    //     apiError.value = `<h4>Package Limit Exceeded</h4><p>Remaining links in your current package: <b>${packageData.value.li_lt_left}</b><br> Please Upgrade your package to add more links.</p>`;
    //     linkLimitErrorModal2.show();
    // }
    else if(postComputedLinks.value.links.length > 500){
        apiError.value = `<h4>Links Limit Exceeded</h4><p>Maximum limit exceeded, please track less than 500 links at a time.</p>`;
        validationErrorModal.show();
    }
    else if(!validateLinks()){
        try{
            toggleLoadingBtn(submitBtn);
            isLinksTracking.value = true;
            let res = await store.dispatch('Domain/trackLinksAction', postComputedLinks.value);
            if(res.success && res.http_status_code === 201){

                await updatePackageInfo();

                toast.success(res.message);
                setTimeout(()=>{
                    localStorage.setItem('pro-a-type', 'add');
                    router.push(`/projects/${domainData.value.project_id}`);
                },1000); 
            }
        }catch(e){
            if(e.response.status === 400){
                validationErrorModal.show();

                for(let [key,val] of Object.entries(e.response.data.data)){
                    let id = errors.value.findIndex(error => error.id === (Number(key) - 1));
                    if(val.placement_url){
                        errors.value[id].destinationUrl = val.placement_url.message;
                    }else{
                        errors.value[id].destinationUrl  = "";
                    }

                    if(val.live_url){
                        errors.value[id].liveUrl = val.live_url.message;
                    }else{
                        errors.value[id].liveUrl = "";
                    }

                    if(val.anchor_text){
                        errors.value[id].anchorText = val.anchor_text.message;
                    }else{
                        errors.value[id].anchorText = "";
                    }

                    if(val.duplicate_entry){
                        errors.value[id].destinationUrl = val.duplicate_entry.message;
                        errors.value[id].liveUrl = val.duplicate_entry.message;
                        errors.value[id].anchorText = val.duplicate_entry.message;
                    }
                }

            }else {
                toast.error(e.response.data.messages[0]);
            }
        }finally{
            toggleLoadingBtn(submitBtn);
            isLinksTracking.value = false;
        }
    }
    else{
        apiError.value = `<h4>Validation Errors</h4><p>Please fill all the required highlighted fields!</p>`
        validationErrorModal.show();
    }
}
async function updatePackageInfo(){
    try{
        await store.dispatch('Packages/getCurrentPackageAction',null,{root:true});
    }catch(e){
        console.log(e);
    }
}
// Computed Properties
const packageData = computed(()=>{
    return store.getters['Packages/getPackageData'];
});
const postComputedLinks = computed(()=>{
    const allLinks = links.value.map(link => {
        return {
            project_id: domainData.value.project_id,
            id: link.id+1,
            placement_url: link.destinationUrl,
            live_url: link.liveUrl,
            // anchor_text: link.anchorText,
        }
    });
    return{
        links: allLinks,
        remainingLinks: packageData.value.li_lt_left - allLinks.length,
    }
});

// Life Cycle Methods
onMounted(()=>{
    loading.value = true;
    packageError.value = '';
    apiError.value = '';

    setTimeout(()=>{
        loading.value = false;
    }, 2000);

    let errorModal = new Modal(document.getElementById('error-modal'));
    let linkLimitErrorModal = new Modal(document.getElementById('link-limit-modal'));

    // if(props.isPackageTrial && props.isTrialExpired){
    //     errorModal.show();
    //     packageError.value = `<h4>Trial Period Ended</h4><p>Your trial period has ended. Please upgrade or renew your package to keep tracking.</p>`;
    // }
    if(packageData.value.pkg_cycle !== 'LIFETIME' && packageData.value.is_pkg_exp){
        errorModal.show();
        packageError.value = `<h4>Package Expired</h4><p>Your subscription period has ended. Please upgrade or renew your package to keep tracking.</p>`;
    }else if(packageData.value.li_lt_left <= 0){
        linkLimitErrorModal.show();
        apiError.value = `<h4>Package Limit Exceeded</h4><p>You have 0 remaining Links to add, please update your subscription package to add more Links.</p>`;
    }else{
        packageError.value = '';
        apiError.value = '';
    }

    if(!apiError.value){
        getDomainData(route.params.id);
    
        interval.value = setInterval(()=>{
            getDomainData(route.params.id)
        }, 30000);
    }
});
onUnmounted(()=>{
    if(interval.value){
        clearInterval(interval.value);
    }
});

// Watcher
watch(()=>route.params.id,(newId)=>{
    if(route.name === 'add-links'){
        getDomainData(newId);

        interval.value = setInterval(()=>{
            getDomainData(newId)
        }, 30000);
    }
});

</script>

<template>
    <section class="dashboard-agency-view add-links-view">
        <LoaderComponent :isLoading="loading" />
        <ProgressBarAlert :show="isLinksTracking" message="Please standby as we gather your data. It might take a few, <br> so sit back and sip that latte, tea, espresso, boba tea, matcha… "/>
        <ProgressBarAlert :show="isQueue === 1" message="Please standby as we gather your data. It might take a few, <br> so sit back and sip that latte, tea, espresso, boba tea, matcha… "/>

        <div class="primary-heading">
            <div class="row align-items-center justify-content-between">
                <div class="col-lg-6 col-md-8 col-6">
                    <h2>Your Projects</h2>
                </div>
                <div class="col-lg-6 col-md-4 col-6">
                    <div class="dashboard-agency-heading-btn text-end">
                       <router-link
                            :to="{ name: 'add-project' }"
                            class="primary-btn primary-bg"
                            >Add Project</router-link
                        >
                    </div>
                </div>
            </div>
        </div>

        <div class="domain-link-info">
            <!-- <ProjectThumbnailWrapper v-if="isQueue === 0 && Object.keys(domainData).length > 0" :id="domainData.project_id" :data="domainData" :remainingLinks="packageData.li_lt_left"/> -->
            <ProjectThumbnailWrapper v-if="isQueue === 0 && Object.keys(domainData).length > 0" :id="domainData.project_id" :data="domainData"/>
            <ProjectThumbnailLoader  v-if="isQueue === 1" />
        </div>

        <div class="domain-add-links">
            <div class="primary-heading">
                <h3>Add links <span>(You can add up to 500 in one attempt)</span></h3>
            </div>
            <div class="domain-links-table">
                <form @submit.prevent="trackLinks">
                    <div class="table-responsive">
                        <table class="table table-1 table-bordered">
                            <thead>
                                <tr>
                                    <th scope="col">
                                        Enter Destination URL
                                        <i class="fas fa-exclamation-circle" v-tooltip="'The Page your Backlink leads to upon clicking.'"></i>
                                    </th>
                                    <th scope="col">
                                        Enter Live URL
                                        <i class="fas fa-exclamation-circle" v-tooltip="'The Page that is currently Live and accessible for users.'"></i>
                                    </th>
                                    <!-- <th scope="col">
                                        Anchor Text
                                        <i class="fas fa-exclamation-circle" v-tooltip="'The text that serves as the viewable and readable part of the backlink.'"></i>
                                    </th> -->
                                    <th scope="col" class="text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(link, index) in links" :key="link.id">
                                    <td :class="{'error': errors[index].destinationUrl}">
                                        <div class="form-group mb-0">
                                            <div class="d-relative">
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    placeholder="https://yourdestinationurl.com/..."
                                                    v-model.trim="link.destinationUrl"
                                                    @blur="
                                                        errors[
                                                            index
                                                        ].destinationUrl =
                                                            link.destinationUrl ===
                                                            ''
                                                                ? 'Field must be filled!'
                                                                : urlValidation(
                                                                    link.destinationUrl,true
                                                                )
                                                    "
                                                />

                                                <Popper
                                                    class="error"
                                                    :content="
                                                        errors[index].destinationUrl
                                                    "
                                                    :arrow="true"
                                                    :hover="true"
                                                    placement="left"
                                                    v-if="
                                                        errors[index].destinationUrl
                                                    "
                                                    ><i
                                                        class="fas fa-exclamation-circle"
                                                    ></i
                                                ></Popper>
                                            </div>
                                        </div>
                                    </td>
                                    <td :class="{'error': errors[index].liveUrl}">
                                        <div class="form-group mb-0">
                                            <div class="d-relative">
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    placeholder="https://yourliveurl.com/..."
                                                    v-model.trim="link.liveUrl"
                                                    @blur="
                                                        errors[index].liveUrl =
                                                            link.liveUrl === ''
                                                                ? 'Field must be filled!'
                                                                : urlValidation(
                                                                    link.liveUrl
                                                                )
                                                    "
                                                />

                                                <Popper
                                                    class="error"
                                                    :content="errors[index].liveUrl"
                                                    :arrow="true"
                                                    :hover="true"
                                                    placement="left"
                                                    v-if="errors[index].liveUrl"
                                                    ><i
                                                        class="fas fa-exclamation-circle"
                                                    ></i
                                                ></Popper>
                                            </div>
                                        </div>
                                    </td>
                                    <!-- <td :class="{'error': errors[index].anchorText}">
                                        <div class="form-group mb-0">
                                            <div class="d-relative">
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    placeholder="Enter anchor text"
                                                    v-model="link.anchorText"
                                                    @blur="
                                                        errors[index].anchorText =
                                                            link.anchorText === ''
                                                                ? 'Field must be filled!'
                                                                : ''
                                                    "
                                                />

                                                <Popper
                                                    class="error"
                                                    :content="errors[index].anchorText"
                                                    :arrow="true"
                                                    :hover="true"
                                                    placement="left"
                                                    v-if="errors[index].anchorText"
                                                    ><i
                                                        class="fas fa-exclamation-circle"
                                                    ></i
                                                ></Popper>
                                            </div>
                                        </div>
                                    </td> -->
                                    <td>
                                        <div class="btns">
                                            <button
                                                @click.prevent="copyLink(link.id)"
                                            >
                                                <i class="fas fa-copy"></i>
                                            </button>
                                            <button
                                                @click.prevent="removeLink(link.id)"
                                                v-if="index > 0"
                                            >
                                                <i class="fas fa-trash-alt"></i>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="row justify-content-between align-items-center">
                        <div class="col-lg-9 col-12">
                            <div class="placeorder-table-options">
                                <button
                                    class="primary-btn primary-transparent-bg"
                                    @click.prevent="addLink()"
                                    :disabled="isLinksTracking"
                                >
                                    <i class="fas fa-plus"></i> Add another Link
                                </button>
                                <button  
                                    class="primary-btn secondary-transparent-bg"
                                    @click.prevent="openBlukOrderModal"
                                    :disabled="isLinksTracking"
                                >
                                    <i class="fas fa-exclamation-circle"></i> Upload CSV for Bulk Order
                                </button>
                                <p v-if="postComputedLinks.remainingLinks >= 500">Link Added: <b>( {{ links.length }} / 500 )</b></p>
                                <p v-else>Link Added: {{ links.length }}</p>
                            </div>
                        </div>
                        <div class="col-lg-3 col-12">
                            <div class="placeorder-table-options">
                                 <button class="primary-btn primary-bg lg-btn submit-btn" type="submit">
                                    <span>Track Now</span>
                                    <i class="fas fa-spinner fa-spin loading"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <!-- Bluk Order Modal Start -->
        <ModalComponent modalId="blukorder-modal" modalClass="blukorder-modal" title="Track Muliple Links" :verticalCentered="true" :showCloseBtn="true">
            <p>Need to track multiple links? Follow the steps below:</p>
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-4 col-12" v-if="!CSVFile.file && !CSVFile.error">
                    <div class="step-box step-box--1">
                        <i class="fas fa-download"></i>
                        <h3>Step 1</h3>
                        <p>Download the template CSV file here.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-12" v-if="!CSVFile.file && !CSVFile.error">
                    <div class="step-box step-box--2">
                        <i class="fas fa-edit"></i>
                        <h3>Step 2</h3>
                        <p>Add your links (up to 500) to the template or create another CSV in the same format.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-12" v-if="!CSVFile.file && !CSVFile.error">
                    <div class="step-box step-box--3">
                        <i class="fas fa-upload"></i>
                        <h3>Step 3</h3>
                        <p>Upload the file and start tracking.</p>
                    </div>
                </div>
                <div class="col-lg-8 col-md-8 col-12" v-if="CSVFile.file">
                        <div class="step-box step-box--4">
                        <i class="fas fa-plus-circle"></i>
                        <h3>Step 4</h3>
                        <p>Start tracking your Links from uploaded CSV.</p>
                        <h4>{{ CSVFile.fileName.length > 10 ? CSVFile.fileName.slice(0,10) + '...' : CSVFile.fileName }}</h4>
                    </div>
                </div>
                <div class="col-lg-8 col-md-8 col-12" v-if="CSVFile.error">
                        <div class="step-box step-box--5">
                        <i class="fas fa-times-circle"></i>
                        <h3>{{ CSVFile.error }}</h3>
                        <p>Please upload correct file to continue.</p>
                    </div>
                </div>
            </div>
            <div class="modal-btns">
                <button class="primary-btn primary-transparent-bg lg-btn" @click="downloadFile('https://www.linkwiki.com/wp-content/themes/astra/assets/files/newcsv.csv')" v-if="!CSVFile.file">Download Sample CSV File</button>
                <label for="bulkorderinput" class="primary-btn primary-bg lg-btn">Upload CSV File 
                    <input class="d-none" type="file" id="bulkorderinput" @input="uploadCSV" ref="bulkorderInput">
                </label>
                <button class="primary-btn primary-bg lg-btn" @click="continueCSV" v-if="CSVFile.file">Continue</button>
            </div>
        </ModalComponent>

        <!-- Notification(Error) Package Error Modal Start -->
        <NotificationModal modalId="error-modal" type="error" :verticalCentered="true" dataBackDrop="static" :dataKeyboard="true" :showCloseBtn="false">
            <div v-if="packageError" v-html="packageError"></div>
            <div v-if="apiError" v-html="apiError"></div>
            <div class="modal-btns">
                <!-- <router-link class="primary-btn primary-bg" :to="{name:'packages'}">Explore Packages</router-link> -->
                <router-link class="primary-btn primary-transparent-bg" :to="{name:'dashboard'}">Go To Dashboard</router-link>
            </div>
        </NotificationModal>

        <!-- Notification(Validation Error) Modal Start -->
        <NotificationModal modalId="validation-error-modal" type="error" :verticalCentered="true" dataBackDrop="static" :dataKeyboard="true" :showCloseBtn="false">
            <div v-html="apiError"></div>
            <div class="modal-btns">
                <a href="#" class="primary-btn primary-bg" data-bs-dismiss="modal" aria-label="Close">Ok</a>
            </div>
        </NotificationModal>

        <!-- Notification(Error) Links Limit Error Modal -->
        <NotificationModal modalId="link-limit-modal" type="error" :verticalCentered="true" dataBackDrop="static" :dataKeyboard="true" :showCloseBtn="true">
            <div v-html="apiError"></div>
            <div class="modal-btns">
                <!-- <router-link class="primary-btn primary-bg" :to="{name:'packages'}">Explore Packages</router-link> -->
                <router-link class="primary-btn primary-transparent-bg" :to="{name:'dashboard'}">Go To Dashboard</router-link>
            </div>
        </NotificationModal>
    </section>
</template>
