<script setup>
import { computed, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { useToast } from 'vue-toastification';
import LoaderComponent from "@/components/LoaderComponent.vue";

// Vue Router
const route = useRoute();
const router = useRouter();

// Vuex Store
const store = useStore();

// Toastification 
const toast = useToast();

// Reactive Variables
let loading = ref(false);

// Methods

// UTM Fn
function utm(){
    const allAnchors = [...document.querySelectorAll('a')];

    const Utm = function(s,m,c,t){
        this.s = s;
        this.m = m;
        this.c = c;
        this.t = t;
    }

    Utm.prototype.generateUrl = function(){
        return `utm_source=${this.s}&utm_medium=${this.m}&utm_campaign=${this.c}&utm_term=${this.t}`;
    }

    const storeToSession = function(url){
        sessionStorage.setItem('utmLink',url);
    }

    const getFromSession = function(){
        return sessionStorage.getItem('utmLink');
    }

    const checkUtmSession = function(){
        const url = getFromSession();
        if(url){
            return url;
        }else{
            return false;
        }
    }

    const updateUrl = function(currentUrl,urlParams,hosts){
        const url = checkUtmSession();
        let urlParamsStr = '';

        if(Object.keys(Object.fromEntries(urlParams.entries())).length){
            for(let [key, value] of urlParams.entries()){
                if(key !== 'utm_source' && key !== 'utm_medium' && key !== 'utm_campaign' && key !== 'utm_term'){
                    urlParamsStr += `&${key}=${value}`;
                }
            }
        }

        if(url.length > 0){
            history.replaceState(history.state,null,`?${url}${urlParamsStr}`);
        }else{
            const utmParams = {
                utmSource: urlParams.get('utm_source') ? urlParams.get('utm_source') : 'direct',
                utmMedium: urlParams.get('utm_medium') ? urlParams.get('utm_medium') : 'direct',
                utmCampaign: urlParams.get('utm_campaign') ? urlParams.get('utm_campaign') : 'direct',
                utmTerm: urlParams.get('utm_term') ? urlParams.get('utm_term') : 'direct'
            }
            const newUrl = new Utm(utmParams.utmSource,utmParams.utmMedium,utmParams.utmCampaign,utmParams.utmTerm).generateUrl();

            history.replaceState(history.state,null,`?${newUrl}${urlParamsStr}`);
            storeToSession(newUrl);
        }
        hosts.forEach(function(host){
            allAnchors.filter((anchor)=>anchor.getAttribute('href').includes(host)).map((anchor) => {
                const href = anchor.getAttribute('href');
                anchor.setAttribute('href',`${href}?${getFromSession() ? getFromSession() : ''}`);
            });
        });
    }

    function initUtm(){
        const hosts = [window.location.origin,'https://app.linkwiki.com/','https://calendly.com/'];
        const currentLink =  window.location.search;
        const urlParam = new URLSearchParams(currentLink);
        updateUrl(currentLink, urlParam, hosts);
    }
    initUtm();
}

// Fn for sending verification email
async function sendEmailVerficationMail(isTrue = false){
    try{
        let res = await store.dispatch('Auth/emailVerficationMailAction', computedEmailVerficationParams.value);
        if(res.success && isTrue){
            toast.success('Email has been sent to your email.');
        }
    }catch(e){
        console.log(e);
    }
}
// Fn for verifying email 
async function updateEmailVerification(code){
    try{
        let res = await store.dispatch('Auth/updateEmailVerificationAction',code,{root:true});
        res.messages.forEach((message)=>{
            toast.success(message);
        });

        if(route.query.id && route.query.cycle && route.query.email_verification){
            router.push(`/payment/${route.query.id}?cycle=${route.query.cycle}`);
        }else if(route.query.email_verification){
            router.push({name: 'welcome'});
        }

    }catch(e){
        e.response.data.messages.forEach((message)=>{
            toast.error(message);
        });
    }
}
// Fn for checking is email verified
async function isEmailVerify(){
    try{
        await store.dispatch('Auth/isEmailVerifiedAction');
    }catch(e){
        console.log(e);
    }
} 
//Fn For send verfication email when click on BTN
async function sendEmail(){
    await sendEmailVerficationMail(true);
}

// Initiating Fn
async function init(){
    // Utm Fn
    utm();

    loading.value = true;
    if(!isVerficationEmailSent.value){
        await sendEmailVerficationMail();
    }

    if(route.query.email_verification){
        await updateEmailVerification(route.query.email_verification);
    }else{
        await isEmailVerify();
    }

    loading.value = false;
}

// Life Cycle Methods
onMounted(()=>{
    init();
});

// Computed Properties
const isVerficationEmailSent = computed(()=>{
    return store.getters['Auth/isVerficationEmailSent'];
});
const computedEmailVerficationParams = computed(()=>{
    return {
        id:route.query.id ? route.query.id : '',
        cycle:route.query.cycle ? route.query.cycle : ''
    }
});


</script>

<template>
    <section class="dashboard-email-verification-sec">

        <LoaderComponent :isLoading="loading" />

        <div class="container">
            <div class="wrapper">
                <figure><img src="@/assets/images/logo.png" alt="logo"></figure>
                <h2>Please verify your email</h2>
                <p>You are almost there! We have sent a verification link to your email address.</p>
                <p>Just click on the link in the email to complete your signup. You may need to check spam folder if you do not see it in your inbox.</p>
                <p><b>Still can not find the email? Please click below to resend!</b></p>
                <button class="primary-btn primary-bg" @click.prevent="sendEmail()">Resend Email</button>
            </div>
        </div>
    </section>
</template>
