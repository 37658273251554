import apiInstance from "./apiInstance";

export class UserProfileServices{

    static userProfileImageUpdateAPI(payload,config){
        return apiInstance.post('/api/save-user-img',payload,config);
    }

    static getUserProfileDataAPI(){
        return apiInstance.get('/api/user-profile');
    }

    static updateUserProfileDataAPI(payload){
        return apiInstance.post('/api/update-user',payload)
    }

    static updateUserProfilePasswordAPI(payload){
        return apiInstance.post('/api/change-password',payload)
    }
    
}