<script setup>
    import { defineProps, defineEmits } from 'vue';

    // Vue Props
    defineProps({
        modelValue: Number,
        options: Object,
    });

    // Vue Emits
    const emit = defineEmits(['update:modelValue', 'changePerPage']);

    // Methods
    function updatePerPage(e){
        emit('update:modelValue', Number(e.target.value));
        emit('changePerPage');
    }

</script>

<template>
    <div class="table-per-page">
        <label>Per Page:</label>
        <div class="d-relative">
            <select :value="modelValue" @change="updatePerPage">
                <option v-for="option in options" :key="option" :value="option">{{ option }}</option>
            </select>
            <i class="fas fa-angle-down"></i>
        </div>
    </div>
</template>

<style>

</style>