import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Toast from "vue-toastification";
import VueProgressBar from "@aacassandra/vue3-progressbar";
import VueApexCharts from "vue3-apexcharts";

// Directives
import { tooltip } from '@/directives/tooltip';

// Toastification CSS
import "vue-toastification/dist/index.css";

// All CSS & JS Files
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import './assets/css/all.css';
import './assets/css/style.css'; 

// Options
const options = {
    color: "#00cbaf",
    failedColor: "#874b4b",
    height: '5px',
};
const toastOption = {
    transition: "Vue-Toastification__fade",
    position: "top-right",
    timeout: 8000,
    closeOnClick: true,
    pauseOnFocusLoss: false,
    pauseOnHover: false,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: false,
    closeButton: "button",
    icon: true,
    rtl: false
}

// Vue Use
const app = createApp(App);
app.use(store)
app.use(router)
app.use(VueApexCharts);
app.use(Toast,toastOption)
app.use(VueProgressBar, options)
app.mount('#app')

app.directive('tooltip', tooltip)

// Vue App Export
export default app;