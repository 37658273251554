<script setup>

import { computed, defineProps } from "vue";
import ChartComponent from '@/components/projectThumbnailComponents/ChartComponent.vue';

// Vue Props
const props = defineProps({
    title: String,
    tooltip: String,
    graphTitle: String,
    name: String,
    data: Object,
    chartColor: String,
});

// Methods
function numberFormatter(num){
    const formatter = new Intl.NumberFormat("en-GB", {
        style: "currency",
        currency: "USD", 
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
    });
    return formatter.format(num).replace('US$','');
}
function getGraphValues(reqVal){
    const obj1 = [];
    const obj2 = [];
    props.data.map((val)=>{
        obj1.push(`${val.month_name}(${val.years})`);
        if(props.title === 'Spam Score'){
            obj2.push(val[reqVal] <= 0 ? 0 : val[reqVal]);
        }else{
            obj2.push(val[reqVal]);
        }
    });
    return {
        x: obj1,
        y: obj2
    };
}
function getValues(obj){
    if(obj.length > 0){
        let lastItem = Number(obj[obj.length - 1]);
        let secondLastItem = Number(obj[obj.length - 2]);
        
        lastItem = lastItem === 0 ? secondLastItem : lastItem; 

        let diff = lastItem - secondLastItem;

        return {
            value: lastItem ? numberFormatter(lastItem) : 0,
            diff: diff ? diff.toFixed(2) : 0,
        }
    }

    return {
        value: 0,
        diff: 0,
    }
}

// Computed Properties
const getComputedGraphValues = computed(()=>{
    const obj = getGraphValues(props.name);
    return {
        ...obj,
        ...getValues(obj.y),
    };
});

</script>

<template>
    <div class="project-thumbnail-box">
        <p>{{ title }} <i class="fas fa-exclamation-circle" v-if="tooltip" v-tooltip="tooltip"></i></p>
        <h3 v-if="title === 'Spam Score'">
            {{ getComputedGraphValues.value <= 0 ? '0' : getComputedGraphValues.value + '%' }}
            <span :class="{'down': getComputedGraphValues.diff >= 0, 'up': getComputedGraphValues.diff < 0}">
                <small v-if="getComputedGraphValues.diff > 0">+</small>
                <small v-else-if="getComputedGraphValues.diff < 0">-</small>
                {{ String(getComputedGraphValues.diff + '%').replace('-','') }} <i class="fas" :class="{'fa-caret-up': getComputedGraphValues.diff >= 0, 'fa-caret-down': getComputedGraphValues.diff < 0}"></i>
            </span>
        </h3>
        <h3 v-else>
            {{ getComputedGraphValues.value < 0 ? 'N/A' : getComputedGraphValues.value }}
            <span :class="{'up': getComputedGraphValues.diff >= 0, 'down': getComputedGraphValues.diff < 0}">
                <small v-if="getComputedGraphValues.diff > 0">+</small>
                <small v-else-if="getComputedGraphValues.diff < 0">-</small>
                {{ String(getComputedGraphValues.diff).replace('-','') }} <i class="fas" :class="{'fa-caret-up': getComputedGraphValues.diff >= 0, 'fa-caret-down': getComputedGraphValues.diff < 0}"></i>
            </span>
        </h3>
        <div class="chart">
            <ChartComponent
                :chartData="getComputedGraphValues"
                :chartColor="chartColor"
                :title="graphTitle"
            />
        </div>
    </div>
</template>

<style>

</style>